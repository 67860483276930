import onMasterDataEnter from '@/modules/master-data/routes/guards';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import Permissions from '@/services/permissions/Permissions';
import { ModuleRouteRecordRaw } from '@/router/routes';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import SiteModule from '@/domain/enums/SiteModule';

const masterDataRoutes: ModuleRouteRecordRaw[] = [
    {
        path: '/master-data',
        name: MasterDataRouteTypes.BASE,
        component: () => import('../views/MasterData.vue'),
        beforeEnter: onMasterDataEnter,
        meta: {
            module: 'master-data',
        },
        children: [
            {
                path: '',
                name: MasterDataRouteTypes.DASHBOARD,
                component: () => import('@/views/home/DashboardScreen.vue'),
                props: () => ({
                    title: getTitleCaseTranslation('core.nav.adminAndPlanning'),
                    siteModule: SiteModule.Admin,
                }),
            },
            {
                path: 'item',
                name: MasterDataRouteTypes.ITEM.BASE,
                component: () => import('../views/items/ItemScreen.vue'),
                children: [
                    {
                        name: MasterDataRouteTypes.ITEM.LIST,
                        path: 'list',
                        meta: { parent: 'items' },
                        component: () => import('../views/items/screens/ItemList.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.ITEM.EDIT,
                        path: 'edit/:itemId',
                        props: true,
                        beforeEnter: Permissions.ADMINISTRATION.canEditItems,
                        meta: { parent: 'items' },
                        component: () => import('../views/items/screens/ItemEdit.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.ITEM.ADD,
                        path: 'add',
                        beforeEnter: Permissions.ADMINISTRATION.canEditItems,
                        meta: { parent: 'items' },
                        component: () => import('../views/items/screens/ItemEdit.vue'),
                    },
                ],
            },
            {
                name: MasterDataRouteTypes.CARRIER.LIST,
                path: 'carrier-list',
                component: () => import('../views/carriers/CarrierList.vue'),
                beforeEnter: Permissions.ADMINISTRATION.canEditCarriers,
            },
            {
                path: 'location',
                name: MasterDataRouteTypes.LOCATION.BASE,
                component: () => import('../views/locations/LocationScreen.vue'),
                children: [
                    {
                        name: MasterDataRouteTypes.LOCATION.LIST,
                        path: 'list',
                        meta: { parent: 'locations' },
                        component: () => import('../views/locations/screens/LocationList.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.LOCATION.EDIT,
                        path: 'edit/:locationId',
                        props: true,
                        beforeEnter: () => Permissions.ADMINISTRATION.canEditLocations(),
                        meta: { parent: 'locations' },
                        component: () => import('../views/locations/screens/EditLocation.vue'),
                    },
                ],
            },
            {
                path: 'user-administration',
                name: MasterDataRouteTypes.USER.LIST,
                component: () => import('../views/config/UserAdminScreen.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canEditUsers(),
            },
            {
                path: 'roles',
                name: MasterDataRouteTypes.ROLES.BASE,
                component: () => import('../views/roles/RoleScreen.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canEditRoles(),
                children: [
                    {
                        name: MasterDataRouteTypes.ROLES.LIST,
                        path: 'list',
                        beforeEnter: () => Permissions.ADMINISTRATION.canEditRoles(),
                        meta: { parent: 'roles' },
                        component: () => import('../views/roles/screens/RoleList.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.ROLES.EDIT,
                        path: 'edit/:roleId',
                        props: true,
                        beforeEnter: () => Permissions.ADMINISTRATION.canEditRoles(),
                        meta: { parent: 'roles' },
                        component: () => import('../views/roles/screens/EditRole.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.ROLES.ADD,
                        path: 'add',
                        beforeEnter: () => Permissions.ADMINISTRATION.canEditRoles(),
                        meta: { parent: 'roles' },
                        component: () => import('../views/roles/screens/EditRole.vue'),
                    },
                ],
            },
            {
                path: 'config',
                name: MasterDataRouteTypes.CONFIG.BASE,
                component: () => import('../views/config/ConfigScreen.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canEditConfig(),
                children: [
                    {
                        name: MasterDataRouteTypes.CONFIG.ITEM_TYPE,
                        path: 'item-types',
                        meta: { parent: 'config' },
                        component: () => import('../views/config/screens/ItemTypeConfig.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.CONFIG.LOCATION_TYPE,
                        path: 'location-types',
                        meta: { parent: 'config' },
                        component: () => import('../views/config/screens/LocationTypeConfig.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.CONFIG.TRAILER_TYPE,
                        path: 'trailer-types',
                        meta: { parent: 'config' },
                        component: () => import('../views/config/screens/TrailerTypeConfig.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.CONFIG.FREIGHT_CODE,
                        path: 'freight-codes',
                        meta: { parent: 'config' },
                        component: () => import('../views/config/screens/FreightCodeConfig.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.CONFIG.EQUIPMENT_TYPE,
                        path: 'equipment-types',
                        meta: { parent: 'config' },
                        component: () => import('../views/config/screens/EquipmentTypeConfig.vue'),
                    },
                ],
            },
            {
                path: 'customer-settings',
                name: MasterDataRouteTypes.CUSTOMER_SETTINGS.LIST,
                component: () => import('../views/settings/CustomerSettings.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canEditCustomerSettings(),
            },
            {
                path: 'cycle-count-manager',
                name: MasterDataRouteTypes.CYCLE_COUNT_MANAGER.LIST,
                component: () => import('../views/cycle-count-manager/CycleCountManager.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canUseCycleCountManager(),
            },
            {
                path: 'transaction',
                name: MasterDataRouteTypes.TRANSACTION.BASE,
                component: () => import('../views/transactions/TransactionScreen.vue'),
                beforeEnter: (from, to, next) => {
                    if (Permissions.ADMINISTRATION.canEditTransactions()) {
                        next();
                    } else {
                        next({ name: MasterDataRouteTypes.DASHBOARD });
                    }
                },
                children: [
                    {
                        name: MasterDataRouteTypes.TRANSACTION.LIST,
                        path: 'list',
                        props: true,
                        meta: { parent: 'transactions' },
                        component: () => import('../views/transactions/screens/TransactionList.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.TRANSACTION.EDIT,
                        path: 'edit/:transactionId',
                        props: true,
                        meta: { parent: 'transactions' },
                        component: () => import('@/modules/master-data/views/transactions/TransactionEditScreenDispatcher.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.TRANSACTION.ADD_NEW_TRANSACTION,
                        path: 'add-new-transaction',
                        meta: { parent: 'transactions' },
                        component: () => import('@/modules/master-data/views/transactions/supplier-shipping/screens/SupplierShippingTransactionScreen.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.TRANSACTION.PLANNING,
                        path: 'planning',
                        meta: { parent: 'transactions' },
                        component: () => import('../views/transactions/screens/PlannedTransactionList.vue'),
                    },
                ],
            },
            {
                path: 'inventory',
                name: MasterDataRouteTypes.INVENTORY.BASE,
                component: () => import('../views/inventory/InventoryScreen.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canViewInventory(),
                children: [
                    {
                        name: MasterDataRouteTypes.INVENTORY.LIST,
                        path: 'list',
                        props: true,
                        meta: { parent: 'inventory' },
                        component: () => import('../views/inventory/screens/InventoryList.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.INVENTORY.ADJUSTMENT_LIST,
                        path: 'list',
                        props: true,
                        meta: { parent: 'inventory' },
                        component: () => import('../views/inventory/screens/InventoryCategoryAdjustments.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.INVENTORY.ITEM_TRANSFORMATION,
                        path: 'list',
                        props: true,
                        meta: { parent: 'inventory' },
                        component: () => import('../views/inventory/screens/ItemTransformation.vue'),
                    },
                ],
            },
            {
                path: 'cycle-count-report',
                name: MasterDataRouteTypes.CYCLE_COUNT_REPORT.BASE,
                component: () => import('../../../views/cyclecount/CycleCountReportScreen.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canEditCycleCounts(),
                children: [
                    {
                        name: MasterDataRouteTypes.CYCLE_COUNT_REPORT.LIST,
                        path: 'list',
                        meta: { parent: 'cycle-count-report' },
                        component: () => import('../../../views/cyclecount/CycleCountReportList.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.CYCLE_COUNT_REPORT.REPORT,
                        path: 'report/:reportId',
                        meta: { parent: 'cycle-count-report' },
                        component: () => import('../../../views/cyclecount/CycleCountReport.vue'),
                        props: (route) => ({
                            reportId: route.params.reportId,
                            canEditCount: true,
                            canApproveCount: true,
                            dashboard: MasterDataRouteTypes.CYCLE_COUNT_REPORT.LIST,
                        }),
                    },
                ],
            },
            {
                name: MasterDataRouteTypes.SHIPPING_PLANNER,
                path: 'shipping-planner',
                component: () => import('../views/shipping-planner/ShippingPlannerScreen.vue'),
                beforeEnter: Permissions.ADMINISTRATION.canViewShippingPlanner,
            },
            {
                name: MasterDataRouteTypes.HISTORY.BASE,
                path: 'history',
                component: () => import('@/modules/master-data/views/history/MasterDataHistory.vue'),
                children: [
                    {
                        name: MasterDataRouteTypes.HISTORY.LIST,
                        path: 'entityType/:entityType/entityId/:entityId',
                        props: true,
                        meta: { parent: 'history' },
                        component: () => import('@/modules/master-data/views/history/screens/HistoryList.vue'),
                    },
                ],
            },
            {
                name: MasterDataRouteTypes.PRODUCTION_PART.LIST,
                path: 'production-part-list',
                component: () => import('../views/production-part/ProductionPartList.vue'),
                beforeEnter: Permissions.ADMINISTRATION.canEditProductionPartData,
            },
            {
                name: MasterDataRouteTypes.EQUIPMENT.LIST,
                path: 'equipment-list',
                component: () => import('../views/equipment/EquipmentList.vue'),
                beforeEnter: Permissions.ADMINISTRATION.canEditEquipment,
            },
            {
                path: 'import-errors',
                name: MasterDataRouteTypes.IMPORT_ERRORS.BASE,
                component: () => import('../views/import-errors/ImportErrorsScreen.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canResolveImportErrors(),
                children: [
                    {
                        name: MasterDataRouteTypes.IMPORT_ERRORS.ASN,
                        path: 'advanced-shipping-notice',
                        meta: { parent: 'import-errors' },
                        component: () => import('../views/import-errors/screens/AdvancedShippingNotice.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.IMPORT_ERRORS.UPDATE_ASN,
                        path: 'advanced-shipping-notice/:id',
                        meta: { parent: 'import-errors' },
                        component: () => import('../views/import-errors/screens/UpdateAdvancedShippingNotice.vue'),
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: MasterDataRouteTypes.IMPORT_ERRORS.IDF,
                        path: 'demand-forecast',
                        meta: { parent: 'import-errors' },
                        component: () => import('../views/import-errors/screens/IncomingDemandForecast.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.IMPORT_ERRORS.UPDATE_IDF,
                        path: 'demand-forecast/:id',
                        meta: { parent: 'import-errors' },
                        component: () => import('../views/import-errors/screens/UpdateIncomingDemandForecast.vue'),
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: MasterDataRouteTypes.IMPORT_ERRORS.PFEP,
                        path: 'production-part-notice',
                        meta: { parent: 'import-errors' },
                        component: () => import('../views/import-errors/screens/ProductionPartNotice.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.IMPORT_ERRORS.UPDATE_PFEP,
                        path: 'production-part-notice/:id',
                        meta: { parent: 'import-errors' },
                        component: () => import('../views/import-errors/screens/UpdateProductionPartNotice.vue'),
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: MasterDataRouteTypes.IMPORT_ERRORS.PO,
                        path: 'partner-order',
                        meta: { parent: 'import-errors' },
                        component: () => import('../views/import-errors/screens/PartnerOrder.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.IMPORT_ERRORS.UPDATE_PO,
                        path: 'partner-order/:id',
                        meta: { parent: 'import-errors' },
                        component: () => import('../views/import-errors/screens/UpdatePartnerOrder.vue'),
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                ],
            },
            {
                path: 'repair-parts',
                name: MasterDataRouteTypes.REPAIR_PART.BASE,
                component: () => import('../views/repair/RepairPartScreen.vue'),
                beforeEnter: () => Permissions.ADMINISTRATION.canRepair(),
                children: [
                    {
                        name: MasterDataRouteTypes.REPAIR_PART.LIST,
                        path: 'list',
                        beforeEnter: () => Permissions.ADMINISTRATION.canRepair(),
                        meta: { parent: 'repair-parts' },
                        component: () => import('../views/repair/screens/RepairPartList.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.REPAIR_PART.EDIT,
                        path: 'edit/:repairPartId',
                        props: true,
                        beforeEnter: () => Permissions.ADMINISTRATION.canRepair(),
                        meta: { parent: 'repair-parts' },
                        component: () => import('../views/repair/screens/EditRepairPart.vue'),
                    },
                    {
                        name: MasterDataRouteTypes.REPAIR_PART.ADD,
                        path: 'add',
                        beforeEnter: () => Permissions.ADMINISTRATION.canRepair(),
                        meta: { parent: 'repair-parts' },
                        component: () => import('../views/repair/screens/EditRepairPart.vue'),
                    },
                ],
            },
        ],
    },
];

export default masterDataRoutes;
