import ConfigStore from '@/modules/master-data/store/modules/ConfigStore';
import LocationStore from '@/modules/master-data/store/modules/LocationStore';

export class MasterDataStore {
    configStore = new ConfigStore();
    locationStore = new LocationStore();

    private static instance: MasterDataStore;

    public static getInstance(): MasterDataStore {
        if (!MasterDataStore.instance) {
            MasterDataStore.instance = new MasterDataStore();
        }

        return MasterDataStore.instance;
    }

    public static isInitialized() {
        return Boolean(MasterDataStore.instance);
    }
}

const masterDataStore = MasterDataStore;
export default masterDataStore;
