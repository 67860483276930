
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'orbis-card',
    props: {
        title: String,
        showBack: Boolean,
    },
    emits: ['goBack'],
    setup(props, context) {
        function goBack() {
            context.emit('goBack');
        }

        return {
            goBack,
        };
    },
});
