const PartnerEngagementRouteTypes = {
    BASE: 'partner-engagement',
    HOME: 'partner-engagement-home',
    DASHBOARD: 'partner-engagement-dashboard',
    TRANSACTION: {
        BASE: 'partner-engagement-transaction-base',
        LIST: 'partner-engagement-transaction-list-cur-location',
        LISTALL: 'partner-engagement-transaction-list-all',
        ADD_SUPPLIER_ORDER: 'partner-engagement-transaction-supplier-order',
        ADD_SUPPLIER_SHIPPING: 'partner-engagement-transaction-supplier-shipping',
        EDIT: 'transaction-edit',
        SUPPLIER_INBOUND: 'partner-engagement-transaction-supplier-inbound',
    },
    INVENTORY: {
        BASE: 'inventory-base',
        LIST: 'inventory-list',
    },
    CYCLE_COUNT_REPORT: {
        REPORT: 'partner-engagement-cycle-count-report-report',
    },
    PRODUCTION_PART: {
        LIST: 'partner-engagement-production-part-list',
    },
};

export default PartnerEngagementRouteTypes;
