import axios, { AxiosResponse } from 'axios';
import InventoryCategory from '@/domain/InventoryCategory';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';

export default class InventoryCategoryApiService {
    public async getCategoryDetails(): Promise<DataAccessResponse<Array<InventoryCategory>>> {
        const response: AxiosResponse<DataAccessResponse<Array<InventoryCategory>>> = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/inventory/category-details`,
        });

        return new DataListAccessResponseClass<InventoryCategory>(response, InventoryCategory).response;
    }
}
