
import { defineComponent } from 'vue';
import Navigation from '@/components/Navigation.vue';
import Loading from '@/components/Loading.vue';
import NotificationCenter from '@/components/NotificationCenter.vue';
import { useLoading } from '@/composable/useLoading';
import ConfirmDialogModal from '@/components/bootstrap-library/modal/ConfirmDialogModal.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import coreStore from '@/store/CoreStore';

export default defineComponent({
    name: 'app',
    components: {
        ConfirmDialogModal,
        Navigation,
        Loading,
        NotificationCenter,
    },
    setup() {
        const { loading } = useLoading();
        const { confirmModal } = useDialogBox();
        const { languageStore } = coreStore.getInstance();

        return {
            loading,
            confirmModal,
            languageStore,
        };
    },
});
