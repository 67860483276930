export const STORE_KEY = Symbol();

export type Index<T extends new (...args: any[]) => any> = T & {
    [STORE_KEY]: T extends new (...args: any[]) => infer I ? I : never
};

export const store = <T extends new (...args: any[]) => any>(type: T) => new Proxy(type, {
    // this will hijack the constructor
    construct(target: Index<T>, argsList, newTarget) {
        // we should skip the proxy for children of our target class
        if (target.prototype !== newTarget.prototype) {
            return Reflect.construct(target, argsList, newTarget);
        }
        // if our target class does not have an instance, create it
        if (!target[STORE_KEY]) {
            target[STORE_KEY] = Reflect.construct(target, argsList, newTarget);
        }
        // return the instance we created!
        return target[STORE_KEY];
    },
});

export class CreateStore<T> {
    private static instance: any;

    constructor(value: T) {
        CreateStore.instance = value;
    }

    public getInstance(): T {
        if (!CreateStore.instance) {
            CreateStore.instance = new CreateStore.instance();
        }

        return CreateStore.instance;
    }
}
