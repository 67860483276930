import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import Permissions from '@/services/permissions/Permissions';
import { ModuleRouteRecordRaw } from '@/router/routes';

const floorTrakRoutes: ModuleRouteRecordRaw[] = [
    {
        path: '/floortrak',
        name: FloorTrakRouteTypes.BASE,
        component: () => import('@/modules/floortrak/view/Floortrak.vue'),
        beforeEnter: Permissions.FLOORTRAK.canAccessModule,
        meta: {
            module: 'floortrak',
        },
        children: [
            {
                path: '',
                name: FloorTrakRouteTypes.HOME,
                component: () => import('@/views/home/HomeScreen.vue'),
                props: {
                    title: 'RPM',
                },
            },
            {
                path: 'receiving',
                name: FloorTrakRouteTypes.RECEIVING.BASE,
                component: () => import(/* webpackChunkName: "receiving" */ '../view/receiving/ReceivingScreen.vue'),
                children: [
                    {
                        name: FloorTrakRouteTypes.RECEIVING.NEW,
                        path: 'details/new',
                        meta: { parent: 'receiving' },
                        beforeEnter: Permissions.FLOORTRAK.canDockReceive,
                        component: () => import('../view/receiving/components/ReceivingDetails.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.RECEIVING.EXISTING,
                        path: 'existing/:transactionNumber?',
                        meta: { parent: 'receiving' },
                        props: true,
                        beforeEnter: Permissions.FLOORTRAK.canCloseOutReceipt,
                        component: () => import('../view/receiving/components/Existing.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.RECEIVING.ESTIMATE,
                        path: 'estimate/:transactionNumber?',
                        meta: { parent: 'receiving' },
                        props: true,
                        beforeEnter: Permissions.FLOORTRAK.canCloseOutReceipt,
                        component: () => import('../view/receiving/components/Estimate.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.RECEIVING.SORT,
                        path: 'sort/:transactionNumber?',
                        meta: { parent: 'receiving' },
                        props: true,
                        beforeEnter: Permissions.FLOORTRAK.canCloseOutReceipt,
                        component: () => import('../view/receiving/components/Sort.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.RECEIVING.PUT_AWAY,
                        path: 'put-away/:transactionNumber?',
                        meta: { parent: 'receiving' },
                        props: true,
                        beforeEnter: Permissions.FLOORTRAK.canCloseOutReceipt,
                        component: () => import('../view/receiving/components/PutAway.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.RECEIVING.WAREHOUSE_PUT_AWAY,
                        path: 'warehouse-put-away/:transactionNumber?',
                        meta: { parent: 'receiving' },
                        props: true,
                        beforeEnter: Permissions.FLOORTRAK.canWarehousePutAway,
                        component: () => import('../view/receiving/components/WarehousePutAway.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.RECEIVING.CLOSEOUT,
                        path: 'closeout/:transactionNumber?',
                        meta: { parent: 'receiving' },
                        props: true,
                        beforeEnter: Permissions.FLOORTRAK.canCloseOutReceipt,
                        component: () => import('../view/receiving/components/Closeout.vue'),
                    },
                ],
            },
            { path: 'receiving/details/', redirect: 'receiving/details/new' },
            {
                path: 'shipping',
                name: FloorTrakRouteTypes.SHIPPING.BASE,
                component: () => import(/* webpackChunkName: "shipping" */ '../view/shipping/ShippingScreen.vue'),
                children: [
                    {
                        name: FloorTrakRouteTypes.SHIPPING.NEW,
                        path: 'details/new',
                        meta: { parent: 'shipping' },
                        beforeEnter: Permissions.FLOORTRAK.canShipNew,
                        component: () => import('../view/shipping/components/ShippingDetails.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.SHIPPING.SEARCH,
                        path: 'search',
                        meta: { parent: 'shipping' },
                        beforeEnter: Permissions.FLOORTRAK.canPickAndShipExisting,
                        component: () => import('../view/shipping/components/ShippingSearchModal.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.SHIPPING.EXISTING,
                        path: 'details/:transactionNumber',
                        meta: { parent: 'shipping' },
                        beforeEnter: Permissions.FLOORTRAK.canPickAndShipExisting,
                        component: () => import('../view/shipping/components/ShippingDetails.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.SHIPPING.WAREHOUSE_SEARCH,
                        path: 'warehouse-search',
                        meta: { parent: 'shipping' },
                        beforeEnter: Permissions.FLOORTRAK.canWarehouseShip,
                        component: () => import('../view/shipping/components/WarehouseShippingSearchModal.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.SHIPPING.WAREHOUSE,
                        path: 'warehouse-shipping/:transactionNumber',
                        meta: { parent: 'shipping' },
                        beforeEnter: Permissions.FLOORTRAK.canWarehouseShip,
                        component: () => import('../view/shipping/components/WarehouseShipping.vue'),
                    },
                ],
            },
            {
                path: 'cycle-count-report/report',
                name: FloorTrakRouteTypes.CYCLE_COUNT_REPORT.REPORT,
                component: () => import('../../../views/cyclecount/CycleCountReport.vue'),
                beforeEnter: Permissions.FLOORTRAK.canRecordCount,
                props: () => ({
                    canApproveCount: Permissions.FLOORTRAK.canAdjustCount(),
                }),
            },
            { path: 'shipping/details/', redirect: 'shipping/details/new' },
            {
                path: 'inventory',
                name: FloorTrakRouteTypes.INVENTORY.BASE,
                component: () => import(/* webpackChunkName: "shipping" */ '../view/inventory/InventoryScreen.vue'),
                children: [
                    {
                        name: FloorTrakRouteTypes.INVENTORY.ADD_TAG,
                        path: 'add-tag',
                        meta: { parent: 'inventory' },
                        component: () => import('../view/inventory/screens/InventoryAddTag.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.INVENTORY.REPLACE_TAG,
                        path: 'update-tag/:tag?',
                        props: true,
                        meta: { parent: 'inventory' },
                        component: () => import('../view/inventory/screens/InventoryReplaceTag.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.INVENTORY.TRANSFER,
                        path: 'transfer',
                        meta: { parent: 'inventory' },
                        beforeEnter: Permissions.FLOORTRAK.canMoveInventory,
                        component: () => import('../view/inventory/screens/WarehouseMovement.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.INVENTORY.COMBINED_ITEMS,
                        path: 'assemble-unit-loads',
                        props: { deconstruct: false },
                        meta: { parent: 'inventory' },
                        beforeEnter: Permissions.FLOORTRAK.canManageUnitLoads,
                        component: () => import('../view/inventory/screens/CombinedItems.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.INVENTORY.DECONSTRUCT_ITEMS,
                        path: 'disassemble-unit-loads',
                        props: { deconstruct: true },
                        meta: { parent: 'inventory' },
                        beforeEnter: Permissions.FLOORTRAK.canManageUnitLoads,
                        component: () => import('../view/inventory/screens/CombinedItems.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.INVENTORY.IN_HOUSE_REPAIRS,
                        path: 'in-house-repairs',
                        meta: { parent: 'inventory' },
                        beforeEnter: Permissions.FLOORTRAK.canRepairItems,
                        component: () => import('../view/inventory/screens/InHouseRepairs.vue'),
                    },
                    {
                        name: FloorTrakRouteTypes.INVENTORY.PUT_AWAY,
                        path: 'put-away',
                        meta: { parent: 'inventory' },
                        beforeEnter: Permissions.FLOORTRAK.canProcessPutAway,
                        component: () => import('../view/inventory/screens/ProcessedPutAway.vue'),
                    },
                ],
            },
        ],
    },
];

export default floorTrakRoutes;
