export type CommonTranslation = {
    all: string;
    selectCurrentLocation: string;
    selectLocation: string;
    loading: string;
    changeLocation: string;
    changeLanguage: string;
    about: string;
    logOff: string;
    selected: string;
    typeToSearch: string;
    emptyTable: string;
    trailerSpaceUtilized: string;
    trailerWeightUtilized: string;
    areYouSure: string;
    planning: string;
    quantityCannotBeLess: string;
    clearLocationWarning: string;
    alreadyAdded: string;
    noItemsFound: string;
    requestedQuantityRoundedUp: string;
    unableToFindCycleCount: string;
    contactInformationNotFound: string;
    closed: string;
    awaitingApproval: string;
    cycleCountDashboard: string;
    dashboard: string;
    complete: string;
    allCycleCountsComplete: string;
    itemHasAlreadyBeenCounted: string;
    mustEnterCount: string;
    transactionsInDeliveryConfirmedStatus: string;
    waitForPreviousCountToFinishSaving: string;
    cycleCountReview: string;
    flag: string;
    from: string;
    fromLoc: string;
    to: string;
    toLoc: string;
    search: string;
    yes: string;
    no: string;
    planForEveryPartTitle: string;
    missingProductionPartAddPermission: string;
    missingProductionPartEditPermission: string;
    image: string;
    showDisabled: string;
    general: string;
    home: string;
    receiving: string;
    new: string;
    estimate: string;
    sort: string;
    putAway: string;
    closeout: string;
    shipping: string;
    existing: string;
    assembleUnitLoads: string;
    disassembleUnitLoads: string;
    newUser: string;
    safetyChecklist: string;
    safteyChecklistTrailerLocked: string;
    safetyChecklistWheelsChocked: string;
    safetyChecklistInspectedForDamage: string;
    welcomeTo: string;
    homeToGetStarted: string;
    or: string;
    createReceivingTransaction: string;
    confirmSave: string;
    downloading: string;
    receivingActions: string;
    saveShipmentSuccess: string;
    scanOrAddToStart: string;
    scanOrLookupTransactionNumber: string;
    numberOfLabelsToPrint: string;
    print: string;
    totalEstimatedContainers: string;
    printLabels: string;
    action: string;
    glassFoundInLoad: string;
    receiptAlreadySorted: string;
    connectedUsers: string;
    connectedUser: string;
    noOpenSortStationsForLocation: string;
    disconnectedStation: string;
    palletComplete: string;
    loadComplete: string;
    revertLoadComplete: string;
    moveToGlass: string;
    sortCompletion: string;
    noUsersFound: string;
    estimatedQuantityShort: string;
    actualQuantityShort: string;
    remainingQuantityShort: string;
    quantityShort: string;
    sortLoads: string;
    sortPieces: string;
    history: string;
    proceedToCloseout: string;
    onlyHistoryForThisSession: string;
    tags: string;
    receiptNotPutAway: string;
    noItemsPutAwayOnTransaction: string;
    newShipmentFrom: string;
    carrierDetails: string;
    loadSequence: string;
    notSelected: string;
    noLocationsAssociated: string;
    noCarriersAvailable: string;
    noTrailerTypesAvailable: string;
    containerDetails: string;
    total: string;
    stopAdded: string;
    added: string;
    removed: string;
    itemOutsideOfConfig: string;
    searchShipments: string;
    submitting: string;
    transactionMovedToInTransit: string;
    shipmentSaved: string;
    updatedShipment: string;
    shippingAndReceiving: string;
    selectAddressType: string;
    due: string;
    actions: string;
    clear: string;
    replace: string;
    scannedBarcodes: string;
    typeToSelectUnitLoadsTo: string;
    disassemble: string;
    disassembled: string;
    assemble: string;
    assembled: string;
    typeOrScanTransactionNumber: string;
    scanOrEnterNewTag: string;
    go: string;
    chooseLanguage: string;
    termsOfService: string;
    privacyPolicy: string;
    shipmentWithIdFrom: string;
    labels: string;
    typeOrScan: string;
    noSort: string;
    flip: string;
    moveFromAvailableWithQuantity: string;
    noBarcodes: string;
    filterOnContainer: string;
    searching: string;
    ofTheTrailerBy: string;
    cubicInches: string;
    pounds: string;
    enter: string;
    youDoNotHavePermissionToAddCarriers: string;
    newCarrier: string;
    editCarrier: string;
    freightCodes: string;
    newFreightCode: string;
    editFreightCode: string;
    itemTypes: string;
    newItemType: string;
    addItemToUnitLoad: string;
    addedNewUser: string;
    addedPlannedPart: string;
    addedProductionPart: string;
    addedRoute: string;
    addPFEPForThisProductionPart: string;
    admin: string;
    allUnsavedDataWillBeLostWhenYouLeaveThisPage: string;
    partQuantityExceedsValueTitle: string;
    partQuantityExceedsValueMessage: string;
    areYouSureYouWantToCancelThisTransaction: string;
    areYouSureYouWantToDeleteThisTransaction: string;
    areYouSureYouWantToRejectThis: string;
    areYouSureYouWantToAllocateThisTransaction: string;
    areYouSureYouWantToAllocateAllSelectedTransactions: string;
    aShippingAndReceivingAddressAlreadyExists: string;
    asnHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly: string;
    partnerOrdersHaveBeenQueuedUp: string;
    assignEditPermissionsForRole: string;
    available: string;
    changedFromTo: string;
    clickAddToStartAddingCombinedItems: string;
    clickAddToStartAddingSortStations: string;
    closingOutDisputedTransaction: string;
    confirmInboundReceiptFrom: string;
    confirmTransaction: string;
    contactEmails: string;
    contactName: string;
    createPFEP: string;
    criticalShortage: string;
    currentInTransitInboundShipmentsFrom: string;
    currentInboundReservationsFrom: string;
    dateRange: string;
    deleted: string;
    deletedUserSuccessfully: string;
    deleteThisUser: string;
    deletingADockDoorCannotBeUndone: string;
    deletingAnAddressCannotBeUndone: string;
    deletingARouteCannotBeUndone: string;
    deleteEquipment: string;
    demandForecastHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly: string;
    dockUpdated: string;
    editItemType: string;
    editSetting: string;
    pullFrom: string;
    emailWillBeUsedForSendingNotificationsToTheLocation: string;
    excessOnHand: string;
    gas: string;
    historyFor: string;
    imageAlreadyAssociatedWithTransaction: string;
    invalidDatesSelected: string;
    locationUpdated: string;
    noCarriers: string;
    noCarrierSelected: string;
    noCombinedItems: string;
    noContainers: string;
    noDockAssigned: string;
    noDocks: string;
    noDockSelected: string;
    noImage: string;
    noInventoryCategories: string;
    noItemsAssociatedWithThisShipment: string;
    noLocations: string;
    noSortStations: string;
    noTimeslot: string;
    noTrailerTypes: string;
    pfepHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly: string;
    potentialShortage: string;
    reject: string;
    rejectedDemandForecast: string;
    rejectedPFEP: string;
    rejectedShippingNotice: string;
    rejectedPartnerOrder: string;
    routeDeleted: string;
    routeUpdated: string;
    searchLocation: string;
    selectPropulsionType: string;
    thisIsWhereAllOfTheItemDetailsWillGo: string;
    transactionAllocated: string;
    transactionConfirmed: string;
    transactionDeleted: string;
    transactionNumberTitle: string;
    transactionRemoved: string;
    transactionsAllocated: string;
    transactionsMovedToHeld: string;
    uniqueIdentifierForThisLocation: string;
    updated: string;
    updatedItem: string;
    updatedNotice: string;
    updatedPlannedPart: string;
    updatedProductionPart: string;
    updatedDemandForecast: string;
    updatedPartnerOrder: string;
    updatedUserSuccessfully: string;
    usernameNotSet: string;
    usersInRole: string;
    namesInRole: string;
    verifyShipmentAtTheDockAndUseTheTransactionBelowToStartReceiving: string;
    verifyShipmentAtTheDockAndUseTheReservationBelow: string;
    warningNegativeFinishedGoodInventory: string;
    withinAcceptableRange: string;
    youDoNotHavePermissionToAddAddresses: string;
    youDoNotHavePermissionToAddDockDoors: string;
    youDoNotHavePermissionToAddEquipment: string;
    youDoNotHavePermissionToAddItems: string;
    youDoNotHavePermissionToAddLocations: string;
    youDoNotHavePermissionToAddPlannedParts: string;
    youDoNotHavePermissionToAddShippingRoutes: string;
    youDoNotHavePermissionToDeleteAddresses: string;
    youDoNotHavePermissionToDeleteShippingRoute: string;
    youDoNotHavePermissionToEditAddresses: string;
    youDoNotHavePermissionToEditDockDoors: string;
    youDoNotHavePermissionToEditEquipment: string;
    youDoNotHavePermissionToEditLocations: string;
    youDoNotHavePermissionToEditPlannedParts: string;
    youDoNotHavePermissionToEditRouteItems: string;
    noDataInResponse: string;
    recordHasBeenUpdated: string;
    releaseNotes: string;
    typeToSearchForItem: string;
    noParts: string;
    value: string;
    personalDailyProductionSummary: string;
    lastEntry: string;
    equipmentTypes: string;
    newEquipmentType: string;
    editEquipmentType: string;
    newEquipment: string;
    editEquipment: string;
    electric: string;
    saveShipmentBeforeCreatingNotes: string;
    areYouSureFinalizeEstimate: string;
    youDoNotHavePermissionToAddRepairParts: string;
    youDoNotHavePermissionToEditRepairParts: string;
    deletingARepairCannotBeUndone: string;
    deletingARepairPartCannotBeUndone: string;
    editRepair: string;
    editRepairPart: string;
    newRepair: string;
    newRepairPart: string;
    repairDetails: string;
    repairPartDeleted: string;
    repairPartAdded: string;
    repairPartUpdated: string;
    repairPartInventoryAdded: string;
    repairPartInventoryUpdated: string;
    repairPartsUsed: string;
    repairPartsYield: string;
    repairSubmitted: string;
    systemRepairBalance: string;
    scanOrEnterEquipmentNumber: string;
    equipmentSafetyInspection: string;
    itemPutAwaySuccessfully: string;
    timeNoticeText: string;
    areYouSureYouWantToResetTheCount: string;
    plannedTransactions: string;
    updatedInventory: string;
    areYouSureMaxLoadPerTimeslot: string;
    calculator: string;
    scheduleDockTimeSlot: string;
    selectTime: string;
    itemTransformedSuccessfully: string;
    selectRepairPerformed: string;
    viewTags: string;
    confirmReservation: string;
    newReservation: string;
    setReservationTrailerStatus: string;
    reasonForBeingLate: string;
    flaggedReservationInfo: string;
    youDoNotHavePermissionToAddFloorLocations: string;
    youDoNotHavePermissionToEditFloorLocations: string;
    inbound: string;
    outbound: string;
    areYouSureYouWantToRenameFloorLocationName: string;
    trailerIsLate: string;
    trailerHasArrived: string;
    newReservationError: string;
    itemsStillRequireLoadComplete: string;
    floorLocation: string;
    done: string;
    weekly: string;
    monthly: string;
    first: string;
    second: string;
    third: string;
    fourth: string;
    fifth: string;
    warehousePutAway: string;
    scanALocation: string;
    scanAnItem: string;
    findFloorLocation: string;
    vacantFloorLocations: string;
    noVacantFloorLocations: string;
    floorLocationsAlreadyHolding: string;
    noFloorLocationsAlreadyHolding: string;
    itemsNotInPickPlan: string;
    skusToPick: string;
    tag: string;
    required: string;
    picked: string;
    warehouseShipping: string;
};

const commonEn: CommonTranslation = {
    all: 'all',
    about: 'about',
    logOff: 'log off',
    selectCurrentLocation: 'select current location',
    selectLocation: 'select location',
    loading: 'loading',
    changeLocation: 'change location',
    changeLanguage: 'change language',
    selected: 'selected',
    typeToSearch: 'type to search',
    emptyTable: 'no table data',
    trailerSpaceUtilized: 'trailer space utilized',
    trailerWeightUtilized: 'trailer weight utilized',
    areYouSure: 'Are you sure?',
    planning: 'planning',
    quantityCannotBeLess: 'quantity cannot be less than total tags scanned for this item',
    clearLocationWarning: 'clearing location will clear all item quantities',
    alreadyAdded: 'already added',
    noItemsFound: 'no items found',
    requestedQuantityRoundedUp: 'requested, rounded up to nearest pallet quantity',
    unableToFindCycleCount: 'unable to find cycle count',
    contactInformationNotFound: 'no contact information found',
    closed: 'closed',
    awaitingApproval: 'awaiting approval',
    cycleCountDashboard: 'cycle count dashboard',
    dashboard: 'dashboard',
    complete: 'complete',
    allCycleCountsComplete: 'you have no cycle counts to complete at this time',
    itemHasAlreadyBeenCounted: 'item has already been counted',
    mustEnterCount: 'you must enter a value for count first',
    transactionsInDeliveryConfirmedStatus: 'you have transactions in Delivery Confirmed Status for this item',
    waitForPreviousCountToFinishSaving: 'please wait for the previous count to finish saving',
    cycleCountReview: 'cycle count review',
    flag: 'flag',
    from: 'from',
    fromLoc: 'from loc',
    to: 'to',
    toLoc: 'to loc',
    search: 'search',
    yes: 'yes',
    no: 'no',
    planForEveryPartTitle: 'Plan For Every Part (PFEP)',
    missingProductionPartAddPermission: 'You do not have permission to add production parts',
    missingProductionPartEditPermission: 'You do not have permission to edit production parts',
    image: 'image',
    showDisabled: 'show disabled',
    general: 'general',
    home: 'home',
    receiving: 'receiving',
    new: 'new',
    estimate: 'estimate',
    sort: 'sort',
    putAway: 'put away',
    closeout: 'closeout',
    shipping: 'shipping',
    existing: 'existing',
    assembleUnitLoads: 'assemble unit loads',
    disassembleUnitLoads: 'disassemble unit loads',
    newUser: 'new user',
    safetyChecklist: 'safety checklist',
    safteyChecklistTrailerLocked: 'Trailer locked out with Gladhand lock or door lock?',
    safetyChecklistWheelsChocked: 'Trailer wheels are chocked?',
    safetyChecklistInspectedForDamage: 'Trailer inspected for damage? (Report any damage to Supervisor immediately)',
    welcomeTo: 'Welcome to',
    homeToGetStarted: 'To get started, open up the nav menu in the upper left hand corner.',
    or: 'or',
    createReceivingTransaction: 'create receiving transaction',
    confirmSave: 'Are you sure you want to save?',
    downloading: 'downloading',
    receivingActions: 'receiving actions',
    saveShipmentSuccess: 'Successfully created shipment',
    scanOrAddToStart: 'Scan or Add Items to get started',
    scanOrLookupTransactionNumber: 'Scan or Lookup Transaction #',
    numberOfLabelsToPrint: 'Number of Labels to Print',
    print: 'print',
    totalEstimatedContainers: 'total estimated items',
    printLabels: 'print labels',
    action: 'action',
    glassFoundInLoad: 'Glass has been found with this load',
    receiptAlreadySorted: 'This receipt has already been sorted',
    connectedUsers: 'connected users',
    noOpenSortStationsForLocation: 'There are no open sort stations for this location',
    connectedUser: 'connected user',
    disconnectedStation: 'disconnect station',
    palletComplete: 'pallet complete',
    loadComplete: 'load complete',
    revertLoadComplete: 'revert load complete',
    moveToGlass: 'Move to Glass',
    sortCompletion: 'sort completion',
    noUsersFound: 'No users found',
    estimatedQuantityShort: 'est. qty',
    remainingQuantityShort: 'remaining qty',
    actualQuantityShort: 'actual qty',
    quantityShort: 'qty',
    sortLoads: 'sort loads',
    sortPieces: 'sort pieces',
    history: 'history',
    proceedToCloseout: 'Proceed to Closeout',
    onlyHistoryForThisSession: 'Only shows history for this session',
    tags: 'tags',
    receiptNotPutAway: 'This receipt has not been put away yet',
    noItemsPutAwayOnTransaction: 'No items have been put away on this transaction',
    newShipmentFrom: 'New Shipment from',
    carrierDetails: 'carrier details',
    loadSequence: 'load sequence',
    notSelected: 'not selected',
    noLocationsAssociated: 'No locations associated',
    noCarriersAvailable: 'No Carriers available',
    noTrailerTypesAvailable: 'No Trailer Types available',
    containerDetails: 'item details',
    total: 'total',
    stopAdded: 'stop {0} added',
    added: 'added',
    removed: 'removed',
    itemOutsideOfConfig: 'item outside of config',
    searchShipments: 'search shipments',
    submitting: 'submitting',
    transactionMovedToInTransit: 'Transaction moved to In-Transit',
    shipmentSaved: 'shipment saved',
    updatedShipment: 'updated shipment',
    shippingAndReceiving: 'Shipping and Receiving',
    selectAddressType: 'select address type',
    due: 'due',
    actions: 'actions',
    clear: 'clear',
    replace: 'replace',
    scannedBarcodes: 'scanned barcodes',
    typeToSelectUnitLoadsTo: 'Type to select Unit Load to',
    disassemble: 'disassemble',
    disassembled: 'disassembled',
    assemble: 'assemble',
    assembled: 'assembled',
    typeOrScanTransactionNumber: 'Type or Scan Transaction #',
    scanOrEnterNewTag: 'Scan or enter new tag',
    go: 'go',
    chooseLanguage: 'choose language',
    termsOfService: 'terms of service',
    privacyPolicy: 'privacy policy',
    shipmentWithIdFrom: 'shipment {0} from',
    labels: 'labels',
    typeOrScan: 'Type or scan',
    noSort: 'no sort',
    flip: 'flip',
    moveFromAvailableWithQuantity: 'Move From: ({0}) available',
    noBarcodes: 'no barcodes',
    filterOnContainer: 'Filter on Item',
    searching: 'searching',
    ofTheTrailerBy: 'of the trailer by',
    cubicInches: 'cubic inches',
    pounds: 'pounds',
    enter: 'enter',
    youDoNotHavePermissionToAddCarriers: 'you do not have permission to add carriers',
    newCarrier: 'new carrier',
    editCarrier: 'edit carrier',
    freightCodes: 'freight codes',
    newFreightCode: 'new freight code',
    editFreightCode: 'edit freight code',
    itemTypes: 'item types',
    newItemType: 'new item type',
    addedNewUser: 'Added new user',
    addedPlannedPart: 'added planned part',
    addedProductionPart: 'added production part',
    addedRoute: 'Added route',
    addItemToUnitLoad: 'add item to unit load',
    addPFEPForThisProductionPart: 'Add PFEP for this production part?',
    admin: 'admin',
    allUnsavedDataWillBeLostWhenYouLeaveThisPage: 'All unsaved data will be lost when you leave this page',
    partQuantityExceedsValueTitle: 'Part quantity exceeds the configured value',
    partQuantityExceedsValueMessage: 'The amount of parts entered exceeds the configured value. Are you sure you want to continue?',
    areYouSureYouWantToCancelThisTransaction: 'Are you sure you want to cancel this transaction?',
    areYouSureYouWantToDeleteThisTransaction: 'Are you sure you want to delete this transaction?',
    areYouSureYouWantToRejectThis: 'Are you sure you want to reject this?',
    areYouSureYouWantToAllocateThisTransaction: 'Are you sure you want to allocate this transaction?',
    areYouSureYouWantToAllocateAllSelectedTransactions: 'Are you sure you want to allocate all selected transactions?',
    aShippingAndReceivingAddressAlreadyExists: 'A shipping and receiving address already exists.',
    asnHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly: 'ASN(s) have been queued up for creation and should be available in the system shortly',
    partnerOrdersHaveBeenQueuedUp: 'Partner order(s) have been queued up for creation and should be available in the system shortly',
    assignEditPermissionsForRole: 'Assign/Edit Permissions for Role',
    available: 'available',
    changedFromTo: 'changed {0} from `{1}` to `{2}`',
    clickAddToStartAddingCombinedItems: 'Click add to start adding combined items',
    clickAddToStartAddingSortStations: 'Click add to start adding sort stations',
    closingOutDisputedTransaction: 'closing out disputed transaction',
    confirmInboundReceiptFrom: 'Confirm inbound receipt #{0} from {1}?',
    confirmTransaction: 'confirm transaction',
    contactEmails: 'contact email(s)',
    contactName: 'contact name',
    createPFEP: 'Create PFEP',
    criticalShortage: 'critical shortage',
    currentInTransitInboundShipmentsFrom: 'Current In-Transit Inbound Shipments From {0}',
    currentInboundReservationsFrom: 'Current Inbound Reservations From {0}',
    dateRange: 'date range',
    deleted: 'deleted',
    deletedUserSuccessfully: 'Deleted user successfully',
    deleteThisUser: 'Delete this user?',
    deletingADockDoorCannotBeUndone: 'Deleting a dock door cannot be undone.',
    deletingAnAddressCannotBeUndone: 'Deleting an address cannot be undone.',
    deletingARouteCannotBeUndone: 'Deleting a route cannot be undone.',
    deleteEquipment: 'delete equipment',
    demandForecastHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly: 'Demand Forecast(s) have been queued up for creation and should be available in the system shortly',
    dockUpdated: 'Dock updated',
    editItemType: 'edit item type',
    editSetting: 'edit setting',
    pullFrom: 'pull from',
    emailWillBeUsedForSendingNotificationsToTheLocation: 'Email will be used for sending notifications to the location. Enter one or more email addresses separated by commas.',
    excessOnHand: 'Excess on-hand',
    gas: 'gas',
    historyFor: 'history for',
    imageAlreadyAssociatedWithTransaction: 'Image already associated with transaction.',
    invalidDatesSelected: 'Invalid dates selected',
    locationUpdated: 'Location updated',
    noCarriers: 'No carriers',
    noCarrierSelected: 'No carrier selected',
    noCombinedItems: 'No combined items',
    noContainers: 'no containers',
    noDockAssigned: 'no dock assigned',
    noDocks: 'No docks',
    noDockSelected: 'No dock selected',
    noImage: 'no image',
    noInventoryCategories: 'No inventory categories',
    noItemsAssociatedWithThisShipment: 'No Items associated with this shipment',
    noLocations: 'No locations',
    noSortStations: 'no sort stations',
    noTimeslot: 'no timeslot',
    noTrailerTypes: 'No trailer types',
    pfepHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly: 'PFEP(s) have been queued up for creation and should be available in the system shortly',
    potentialShortage: 'potential shortage',
    reject: 'reject',
    rejectedDemandForecast: 'Rejected demand forecast',
    rejectedPFEP: 'Rejected PFEP',
    rejectedShippingNotice: 'Rejected shipping notice',
    rejectedPartnerOrder: 'Rejected partner order',
    routeDeleted: 'Route deleted',
    routeUpdated: 'Route updated',
    searchLocation: 'search location',
    selectPropulsionType: 'select propulsion type',
    thisIsWhereAllOfTheItemDetailsWillGo: 'This is where all of the item details will go',
    transactionAllocated: 'transaction allocated',
    transactionConfirmed: 'transaction confirmed',
    transactionDeleted: 'transaction deleted',
    transactionNumberTitle: 'Transaction Number : {0} ({1})',
    transactionRemoved: 'transaction removed',
    transactionsAllocated: 'transactions allocated',
    transactionsMovedToHeld: 'Transactions moved to Held',
    uniqueIdentifierForThisLocation: 'Unique identifier for this location.',
    updated: 'updated',
    updatedItem: 'updated item',
    updatedNotice: 'updated notice',
    updatedPlannedPart: 'updated planned part',
    updatedProductionPart: 'updated production part',
    updatedDemandForecast: 'updated demand forecast',
    updatedPartnerOrder: 'updated partner order',
    updatedUserSuccessfully: 'Updated user successfully',
    usernameNotSet: 'Username not set',
    usersInRole: 'users in role',
    namesInRole: 'all users in role',
    verifyShipmentAtTheDockAndUseTheTransactionBelowToStartReceiving: 'Verify shipment at the dock and use the transaction below to start receiving',
    verifyShipmentAtTheDockAndUseTheReservationBelow: 'Verify shipment at the dock and use the reservation below',
    warningNegativeFinishedGoodInventory: 'Warning: negative Finished Goods inventory',
    withinAcceptableRange: 'Within acceptable range',
    youDoNotHavePermissionToAddAddresses: 'You do not have permission to add addresses',
    youDoNotHavePermissionToAddDockDoors: 'You do not have permission to add dock doors',
    youDoNotHavePermissionToAddEquipment: 'You do not have permission to add equipment',
    youDoNotHavePermissionToAddItems: 'You do not have permission to add items',
    youDoNotHavePermissionToAddLocations: 'You do not have permission to add locations',
    youDoNotHavePermissionToAddPlannedParts: 'You do not have permission to add planned parts',
    youDoNotHavePermissionToAddShippingRoutes: 'You do not have permission to add shipping routes',
    youDoNotHavePermissionToDeleteAddresses: 'You do not have permission to delete addresses',
    youDoNotHavePermissionToDeleteShippingRoute: 'You do not have permission to delete shipping route',
    youDoNotHavePermissionToEditAddresses: 'You do not have permission to edit addresses',
    youDoNotHavePermissionToEditDockDoors: 'You do not have permission to edit dock doors',
    youDoNotHavePermissionToEditEquipment: 'You do not have permission to edit equipment',
    youDoNotHavePermissionToEditLocations: 'You do not have permission to edit locations',
    youDoNotHavePermissionToEditPlannedParts: 'You do not have permission to edit planned parts',
    youDoNotHavePermissionToEditRouteItems: 'You do not have permission to edit route items',
    noDataInResponse: 'No data in server response',
    recordHasBeenUpdated: 'This record has been updated or deleted since opened. Try refreshing your browser',
    releaseNotes: 'Release Notes',
    typeToSearchForItem: 'Type to search for Item',
    noParts: 'No parts',
    value: 'value',
    personalDailyProductionSummary: 'personal daily production summary',
    lastEntry: 'last entry',
    equipmentTypes: 'equipment types',
    newEquipmentType: 'new equipment type',
    editEquipmentType: 'edit equipment type',
    newEquipment: 'new equipment',
    editEquipment: 'edit equipment',
    electric: 'electric',
    saveShipmentBeforeCreatingNotes: 'Shipment must be created before adding notes.',
    areYouSureFinalizeEstimate: 'Are you sure you want to finalize estimates and continue?',
    youDoNotHavePermissionToAddRepairParts: 'You do not have permission to add repair parts',
    youDoNotHavePermissionToEditRepairParts: 'You do not have permission to edit repair parts',
    deletingARepairCannotBeUndone: 'Deleting a repair cannot be undone.',
    deletingARepairPartCannotBeUndone: 'Deleting a repair part cannot be undone.',
    editRepair: 'edit repair',
    editRepairPart: 'edit repair part',
    newRepair: 'new repair',
    newRepairPart: 'new repair part',
    repairDetails: 'repair details',
    repairPartDeleted: 'Repair part deleted',
    repairPartAdded: 'Repair part added',
    repairPartUpdated: 'Repart part updated',
    repairPartInventoryAdded: 'Repair part inventory added',
    repairPartInventoryUpdated: 'Repair part inventory updated',
    repairPartsUsed: 'repair parts used',
    repairPartsYield: 'repair parts yield',
    repairSubmitted: 'repair submitted',
    systemRepairBalance: 'system repair balance',
    scanOrEnterEquipmentNumber: 'Scan or enter equipment number',
    equipmentSafetyInspection: 'equipment safety inspection',
    itemPutAwaySuccessfully: 'Item put away successfully',
    timeNoticeText: '- up to 15 minute delay before changes take effect',
    areYouSureYouWantToResetTheCount: 'Are you sure you want to reset the count?',
    plannedTransactions: 'Planned Transactions',
    updatedInventory: 'updated inventory',
    areYouSureMaxLoadPerTimeslot: 'Timeslot already at capacity. Are you sure you want to overschedule?',
    calculator: 'calculator',
    scheduleDockTimeSlot: 'schedule dock time slot',
    selectTime: 'select time',
    itemTransformedSuccessfully: 'item transformed successfully',
    selectRepairPerformed: 'select repair performed',
    viewTags: 'view tags',
    confirmReservation: 'confirm reservation',
    newReservation: 'new reservation',
    setReservationTrailerStatus: 'set trailer status for {0}',
    reasonForBeingLate: 'reason for being late',
    flaggedReservationInfo: 'change {0} reservation information',
    youDoNotHavePermissionToAddFloorLocations: 'You do not have permission to add floor locations',
    youDoNotHavePermissionToEditFloorLocations: 'You do not have permission to edit floor locations',
    inbound: 'inbound',
    outbound: 'outbound',
    areYouSureYouWantToRenameFloorLocationName: 'Are you sure you want to rename this floor location?',
    trailerIsLate: 'trailer is late',
    trailerHasArrived: 'trailer has arrived',
    newReservationError: 'New reservations cannot be made in edit mode',
    itemsStillRequireLoadComplete: 'Items have been sorted that still require Load Complete to accurately update load inventory',
    floorLocation: 'Floor location',
    done: 'Done',
    weekly: 'weekly',
    monthly: 'monthly',
    first: '1st',
    second: '2nd',
    third: '3rd',
    fourth: '4th',
    fifth: '5th',
    warehousePutAway: 'Warehouse Put Away',
    scanALocation: 'Scan a location',
    scanAnItem: 'Scan an item',
    findFloorLocation: 'find floor location',
    vacantFloorLocations: 'Vacant floor locations',
    noVacantFloorLocations: 'No vacant floor locations',
    floorLocationsAlreadyHolding: 'Floor locations already holding {0}',
    noFloorLocationsAlreadyHolding: 'Or Floor locations already holding {0}',
    itemsNotInPickPlan: 'Items not in pick plan',
    skusToPick: 'SKUs To Pick',
    tag: 'tag',
    required: 'required',
    picked: 'picked',
    warehouseShipping: 'Warehouse shipping',
};

const commonEs: CommonTranslation = {
    all: 'todos',
    about: 'acerca de',
    changeLocation: 'cambiar locación',
    changeLanguage: 'cambiar idioma',
    logOff: 'finalizar la sesión',
    selectCurrentLocation: 'seleccione la ubicación actual',
    selectLocation: 'seleccionar ubicación',
    loading: 'cargando',
    selected: 'seleccionado',
    typeToSearch: 'escribe para buscar',
    emptyTable: 'sin datos de tabla',
    trailerSpaceUtilized: 'espacio de remolque utilizado',
    trailerWeightUtilized: 'peso de trailer utilizado',
    areYouSure: 'Estas seguro?',
    planning: 'planificación',
    quantityCannotBeLess: 'la cantidad no puede ser inferior al total de etiquetas escaneadas para este artículo',
    clearLocationWarning: 'al borrar la ubicación se borrarán todas las cantidades de este artículo',
    alreadyAdded: 'ya agregado',
    noItemsFound: 'No se encontraron artículos',
    requestedQuantityRoundedUp: 'solicitado, redondeado a la cantidad de paleta más cercana',
    unableToFindCycleCount: 'incapaz de encontrar el número de ciclos',
    contactInformationNotFound: 'no se encontró información de contacto',
    closed: 'cerrado',
    awaitingApproval: 'esperando aprobacion',
    cycleCountDashboard: 'tablero de conteo de ciclos',
    dashboard: 'tablero',
    complete: 'completo',
    allCycleCountsComplete: 'no tiene conteo de ciclos para completar en este momento',
    itemHasAlreadyBeenCounted: 'los artículos ya han sido contados',
    mustEnterCount: 'primero debe ingresar un valor para contar',
    transactionsInDeliveryConfirmedStatus: 'usted tiene transacciones en el Status de Entrega Confirmada para este artículo',
    waitForPreviousCountToFinishSaving: 'ePor favor espere a que el conteo anterior termine de guardarse',
    cycleCountReview: 'revisión del conteo de ciclos',
    flag: 'bandera',
    from: 'de',
    fromLoc: 'de ubi',
    to: 'a',
    toLoc: 'a ubi',
    search: 'búsqueda',
    yes: 'sí',
    no: 'no',
    planForEveryPartTitle: 'Plan Para Cada Parte (PFEP)',
    missingProductionPartAddPermission: 'No tienes permiso para agregar piezas de producción',
    missingProductionPartEditPermission: 'No tienes permiso para editar piezas de producción',
    image: 'imagen',
    showDisabled: 'mostrar deshabilitado',
    general: 'general',
    home: 'hogar',
    receiving: 'recepción',
    new: 'nuevo',
    estimate: 'estimar',
    sort: 'clasificar',
    putAway: 'guardar',
    closeout: 'cerrar',
    shipping: 'envío',
    existing: 'existente',
    assembleUnitLoads: 'ensamblar unidades de carga ',
    disassembleUnitLoads: 'desensamblar cargas unitarias',
    newUser: 'nuevo usuario',
    safetyChecklist: 'lista de verificación de seguridad',
    safteyChecklistTrailerLocked: '¿Remolque bloqueado con cerradura Gladhand o cerradura de puerta?',
    safetyChecklistWheelsChocked: '¿Las ruedas del remolque están calzadas?',
    safetyChecklistInspectedForDamage: '¿Remolque inspeccionado por daños? (Reporte cualquier daño al Supervisor inmediatamente)',
    welcomeTo: 'Bienvenido a',
    homeToGetStarted: 'Para comenzar, abra el menú de navegación en la esquina superior izquierda.',
    or: 'O',
    createReceivingTransaction: 'crear transacción de recepción',
    confirmSave: '¿Estás seguro de que quieres guardar?',
    downloading: 'descargando',
    receivingActions: 'Recibir acciones',
    saveShipmentSuccess: 'Envío creado con éxito',
    scanOrAddToStart: 'Escanee o agregue elementos para comenzar',
    scanOrLookupTransactionNumber: 'Escanear o buscar número de transacción',
    numberOfLabelsToPrint: 'Número de etiquetas para imprimir',
    print: 'imprimir',
    totalEstimatedContainers: 'artículos totales estimados',
    printLabels: 'imprimir etiquetas',
    action: 'cantidad estimada',
    glassFoundInLoad: 'Se ha encontrado vidrio en esta carga',
    receiptAlreadySorted: 'Este recibo ya ha sido ordenado',
    connectedUsers: 'usuarios conectados',
    noOpenSortStationsForLocation: 'No hay estaciones de clasificación abiertas para esta ubicación',
    connectedUser: 'Usuario conectado',
    disconnectedStation: 'estación de desconexión',
    palletComplete: 'palet completo',
    loadComplete: 'carga completa',
    revertLoadComplete: 'revertir carga completa',
    moveToGlass: 'Mover a Vidrio',
    sortCompletion: 'clasificar terminación',
    noUsersFound: 'No se encontraron usuarios',
    estimatedQuantityShort: 'cantidad estimada',
    actualQuantityShort: 'cantidad actual',
    remainingQuantityShort: 'cantidad restante',
    quantityShort: 'cantidad',
    sortLoads: 'clasificar cargas',
    sortPieces: 'clasificar piezas',
    history: 'historia',
    proceedToCloseout: 'Proceder al cierre',
    onlyHistoryForThisSession: 'Solo muestra el historial de esta sesión',
    tags: 'etiquetas',
    receiptNotPutAway: 'Este recibo aún no se ha guardado',
    noItemsPutAwayOnTransaction: 'No se han guardado artículos en esta transacción',
    newShipmentFrom: 'Nuevo envío de',
    carrierDetails: 'detalles del transportista',
    loadSequence: 'secuencia de carga',
    notSelected: 'no seleccionado',
    noLocationsAssociated: 'No hay ubicaciones asociadas',
    noCarriersAvailable: 'No hay transportistas disponibles',
    noTrailerTypesAvailable: 'No hay tipos de remolque disponibles',
    containerDetails: 'detalles del artículo',
    total: 'total',
    stopAdded: 'deténgase {0} adicional',
    added: 'adicional',
    removed: 'remota',
    itemOutsideOfConfig: 'elemento fuera de la configuración',
    searchShipments: 'buscar envíos',
    submitting: 'sumisión',
    transactionMovedToInTransit: 'Transacción movida a En tránsito',
    shipmentSaved: 'envío guardado',
    updatedShipment: 'envío actualizado',
    shippingAndReceiving: 'Enviar y Recibir',
    selectAddressType: 'seleccione el tipo de dirección',
    due: 'debido a',
    actions: 'comportamiento',
    clear: 'claro',
    replace: 'reemplazar',
    scannedBarcodes: 'códigos de barras escaneados',
    typeToSelectUnitLoadsTo: 'Escriba para seleccionar Carga unitaria a',
    disassemble: 'desensamblar',
    disassembled: 'desensamblado',
    assemble: 'ensamblar',
    assembled: 'ensamblado',
    typeOrScanTransactionNumber: 'Escriba o escanee el número de transacción',
    scanOrEnterNewTag: 'Escanear o ingresar una nueva etiqueta',
    go: 'ir',
    chooseLanguage: 'elige idioma',
    termsOfService: 'términos de servicio',
    privacyPolicy: 'política de privacidad',
    labels: 'etiquetas',
    shipmentWithIdFrom: 'envío {0} desde',
    typeOrScan: 'Escriba o escanee',
    noSort: 'no hay clasificación',
    flip: 'voltear',
    moveFromAvailableWithQuantity: 'Moverse de: ({0}) disponible',
    noBarcodes: 'sin códigos de barras',
    filterOnContainer: 'Filtrar por artículo',
    searching: 'buscando',
    ofTheTrailerBy: 'del tráiler de',
    cubicInches: 'pulgadas cúbicas',
    pounds: 'libras',
    enter: 'ingresar',
    youDoNotHavePermissionToAddCarriers: 'no tiene permiso para agregar transportistas',
    newCarrier: 'nuevo transportista',
    editCarrier: 'editar portador',
    freightCodes: 'códigos de carga',
    newFreightCode: 'nuevo codigo de carga',
    editFreightCode: 'editar código de flete',
    itemTypes: 'tipos de elementos',
    newItemType: 'nuevo tipo de elemento',
    addedNewUser: 'Nuevo usuario agregado',
    addedPlannedPart: 'pieza planificada añadida',
    addedProductionPart: 'pieza de producción añadida',
    addedRoute: 'Ruta añadida',
    addItemToUnitLoad: 'añadir artículo a la unidad de carga',
    addPFEPForThisProductionPart: 'Agregar PFEP para esta pieza de producción?',
    admin: 'administrador',
    allUnsavedDataWillBeLostWhenYouLeaveThisPage: 'Todos los datos no guardados se perderán cuando salga de esta página',
    partQuantityExceedsValueTitle: 'La cantidad de piezas excede el valor configurado',
    partQuantityExceedsValueMessage: 'La cantidad de piezas ingresadas excede el valor configurado. ¿Estás seguro de que quieres continuar?',
    areYouSureYouWantToCancelThisTransaction: 'Está seguro de que desea cancelar esta transacción?',
    areYouSureYouWantToDeleteThisTransaction: 'Está seguro de que desea eliminar esta transacción?',
    areYouSureYouWantToRejectThis: 'Está seguro de que desea rechazar este?',
    areYouSureYouWantToAllocateThisTransaction: 'Está seguro de que desea asignar esta transacción?',
    areYouSureYouWantToAllocateAllSelectedTransactions: 'Está seguro de que desea asignar todas las transacciones seleccionadas?',
    aShippingAndReceivingAddressAlreadyExists: 'Ya existe una dirección de envío y recepción.',
    asnHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly: 'Los ASN se han puesto en cola para su creación y deberían estar disponibles en el sistema en breve',
    partnerOrdersHaveBeenQueuedUp: 'Los orden de socio se han puesto en cola para su creación y deberían estar disponibles en el sistema en breve',
    assignEditPermissionsForRole: 'Asignar/Editar Permisos para el Rol',
    available: 'disponible',
    changedFromTo: 'cambió {0} de `{1}` a `{2}`',
    clickAddToStartAddingCombinedItems: 'Haga clic en agregar para comenzar a agregar elementos combinados',
    clickAddToStartAddingSortStations: 'Haga clic en agregar para comenzar a agregar estaciones de clasificación',
    closingOutDisputedTransaction: 'cierre de transacción en disputa',
    confirmInboundReceiptFrom: 'Confirmar recibo entrante #{0} de {1}?',
    confirmTransaction: 'confirmar transacción',
    contactEmails: 'correo electrónico de contacto',
    contactName: 'nombre de contacto',
    createPFEP: 'Crear PFEP',
    criticalShortage: 'escasez critica',
    currentInTransitInboundShipmentsFrom: 'Envíos Entrantes En Tránsito Actuales Desde {0}',
    currentInboundReservationsFrom: 'Reservas Entrantes Actuales Desde {0}',
    dateRange: 'rango de fechas',
    deleted: 'eliminado',
    deletedUserSuccessfully: 'Usuario eliminado exitosamente',
    deleteThisUser: 'Eliminar este usuario?',
    deletingADockDoorCannotBeUndone: 'La eliminación de una puerta de muelle no se puede deshacer.',
    deletingAnAddressCannotBeUndone: 'La eliminación de una dirección no se puede deshacer.',
    deletingARouteCannotBeUndone: 'La eliminación de una ruta no se puede deshacer.',
    deleteEquipment: 'eliminar equipo',
    demandForecastHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly:
        'Los pronósticos de demanda se han puesto en cola para su creación y deberían estar disponibles en el sistema en breve',
    dockUpdated: 'Muelle actualizado',
    editItemType: 'editar tipo de elemento',
    editSetting: 'editar la configuración',
    pullFrom: 'tirar de',
    emailWillBeUsedForSendingNotificationsToTheLocation:
        'El correo electrónico se utilizará para enviar notificaciones a la ubicación. Introduzca una o más direcciones de correo electrónico separadas por comas.',
    excessOnHand: 'Exceso disponible',
    gas: 'gas',
    historyFor: 'historia para',
    imageAlreadyAssociatedWithTransaction: 'Imagen ya asociada a la transacción.',
    invalidDatesSelected: 'Fechas no válidas seleccionadas',
    locationUpdated: 'Ubicación actualizada',
    noCarriers: 'Sin transportistas',
    noCarrierSelected: 'Ningún operador seleccionado',
    noCombinedItems: 'Sin elementos combinados',
    noContainers: 'sin contenedores',
    noDockAssigned: 'sin muelle asignado',
    noDocks: 'Sin muelles',
    noDockSelected: 'Ningún muelle seleccionado',
    noImage: 'no hay imagen',
    noInventoryCategories: 'Sin categorías de inventario',
    noItemsAssociatedWithThisShipment: 'No hay artículos asociados con este envío',
    noLocations: 'Sin ubicaciones',
    noSortStations: 'sin estaciones de clasificación',
    noTimeslot: 'sin intervalo de tiempo',
    noTrailerTypes: 'Sin tipos de remolque',
    pfepHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly: 'Los PFEP se han puesto en cola para su creación y deberían estar disponibles en el sistema en breve',
    potentialShortage: 'escasez potencial',
    reject: 'rechazar',
    rejectedDemandForecast: 'Previsión de demanda rechazada',
    rejectedPFEP: 'PFEP rechazado',
    rejectedShippingNotice: 'Aviso de envío rechazado',
    rejectedPartnerOrder: 'Orden de socio rechazado',
    routeDeleted: 'Ruta eliminada',
    routeUpdated: 'Ruta actualizada',
    searchLocation: 'buscar ubicación',
    selectPropulsionType: 'seleccione el tipo de propulsión',
    thisIsWhereAllOfTheItemDetailsWillGo: 'Aquí es donde irán todos los detalles del artículo',
    transactionAllocated: 'transacción asignada',
    transactionConfirmed: 'transacción confirmada',
    transactionDeleted: 'transacción eliminada',
    transactionNumberTitle: 'Número De Transacción : {0} ({1})',
    transactionRemoved: 'transacción removida',
    transactionsAllocated: 'transacciones asignadas',
    transactionsMovedToHeld: 'Transacciones movidas a "En Espera"',
    uniqueIdentifierForThisLocation: 'Identificador único para esta ubicación.',
    updated: 'actualizado',
    updatedItem: 'elemento actualizado',
    updatedNotice: 'aviso actualizado',
    updatedPlannedPart: 'pieza planificada actualizada',
    updatedProductionPart: 'pieza de producción actualizada',
    updatedDemandForecast: 'previsión de demanda actualizada',
    updatedPartnerOrder: 'orden de socio actualizado',
    updatedUserSuccessfully: 'Usuario actualizado con éxito',
    usernameNotSet: 'Nombre de usuario no establecido',
    usersInRole: 'usuarios en rol',
    namesInRole: 'todas las usuarias en rol',
    verifyShipmentAtTheDockAndUseTheTransactionBelowToStartReceiving: 'Verifique el envío en el muelle y use la transacción a continuación para comenzar a recibir',
    verifyShipmentAtTheDockAndUseTheReservationBelow: 'Verifique el envío en el muelle y use la reserva a continuación',
    warningNegativeFinishedGoodInventory: 'Advertencia: Inventario negativo de productos terminados',
    withinAcceptableRange: 'Dentro del rango aceptable',
    youDoNotHavePermissionToAddAddresses: 'No tienes permiso para agregar direcciones',
    youDoNotHavePermissionToAddDockDoors: 'No tienes permiso para agregar puertas de muelle',
    youDoNotHavePermissionToAddEquipment: 'No tienes permiso para agregar equipos',
    youDoNotHavePermissionToAddItems: 'No tienes permiso para agregar elementos',
    youDoNotHavePermissionToAddLocations: 'No tienes permiso para agregar ubicaciones',
    youDoNotHavePermissionToAddPlannedParts: 'No tiene permiso para agregar partes planificadas',
    youDoNotHavePermissionToAddShippingRoutes: 'No tienes permiso para agregar rutas de envío',
    youDoNotHavePermissionToDeleteAddresses: 'No tienes permiso para borrar direcciones',
    youDoNotHavePermissionToDeleteShippingRoute: 'No tiene permiso para eliminar la ruta de envío',
    youDoNotHavePermissionToEditAddresses: 'No tienes permiso para editar direcciones',
    youDoNotHavePermissionToEditDockDoors: 'No tienes permiso para editar las puertas del muelle',
    youDoNotHavePermissionToEditEquipment: 'No tienes permiso para editar equipos',
    youDoNotHavePermissionToEditLocations: 'No tienes permiso para editar ubicaciones',
    youDoNotHavePermissionToEditPlannedParts: 'No tiene permiso para editar partes planificadas',
    youDoNotHavePermissionToEditRouteItems: 'No tienes permiso para editar elementos de ruta',
    noDataInResponse: 'No hay datos en la respuesta del servidor',
    recordHasBeenUpdated: 'Este registro ha sido actualizado o eliminado desde que se abrió. Intenta actualizar tu navegador',
    releaseNotes: 'Notas de Lanzamiento',
    typeToSearchForItem: 'Escriba para buscar el elemento',
    noParts: 'Nin partes',
    value: 'valor',
    personalDailyProductionSummary: 'resumen personal de producción diaria',
    lastEntry: 'última entrada',
    equipmentTypes: 'tipos de equipos',
    newEquipmentType: 'nuevo tipo de equipo',
    editEquipmentType: 'editar tipo de equipo',
    newEquipment: 'nuevo equipamiento',
    editEquipment: 'editar equipo',
    electric: 'eléctrico',
    saveShipmentBeforeCreatingNotes: 'El envío debe crearse antes de agregar notas.',
    areYouSureFinalizeEstimate: 'Está seguro de que desea finalizar las estimaciones y continuar?',
    youDoNotHavePermissionToAddRepairParts: 'No tiene permiso para agregar piezas de reparación',
    youDoNotHavePermissionToEditRepairParts: 'No tienes permiso para editar piezas de reparación',
    deletingARepairCannotBeUndone: 'La eliminación de una reparación no se puede deshacer.',
    deletingARepairPartCannotBeUndone: 'La eliminación de una pieza de reparación no se puede deshacer.',
    editRepair: 'editar reparar',
    editRepairPart: 'editar pieza de reparación',
    newRepair: 'reparación nueva',
    newRepairPart: 'nueva pieza de reparación',
    repairDetails: 'detalles de reparacion',
    repairPartDeleted: 'Pieza de reparación eliminada',
    repairPartAdded: 'Pieza de reparación añadida',
    repairPartUpdated: 'Pieza de reparación actualizada',
    repairPartInventoryAdded: 'Inventario de repuestos agregado',
    repairPartInventoryUpdated: 'Inventario de repuestos actualizado',
    repairPartsUsed: 'repuestos usados',
    repairPartsYield: 'rendimiento de piezas de reparación',
    repairSubmitted: 'reparación enviada',
    systemRepairBalance: 'equilibrio de reparación del sistema',
    scanOrEnterEquipmentNumber: 'Escanear o ingresar número de equipo',
    equipmentSafetyInspection: 'inspección de seguridad del equipo',
    itemPutAwaySuccessfully: 'Artículo guardado correctamente',
    timeNoticeText: '- hasta 15 minutos de retraso antes de que los cambios surtan efecto',
    areYouSureYouWantToResetTheCount: 'Estás seguro de que quieres restablecer la cuenta?',
    plannedTransactions: 'Trans (Planeadas)',
    updatedInventory: 'inventario actualizado',
    areYouSureMaxLoadPerTimeslot: 'El horario ya está al límite de su capacidad. ¿Estás seguro de que quieres sobreprogramar?',
    calculator: 'calculadora',
    scheduleDockTimeSlot: 'programar el horario del muelle',
    selectTime: 'seleccionar hora',
    itemTransformedSuccessfully: 'artículo transformado con éxito',
    selectRepairPerformed: 'seleccionar reparación realizada',
    viewTags: 'ver etiquetas',
    confirmReservation: 'confirmar reserva',
    newReservation: 'nueva reserva',
    setReservationTrailerStatus: 'establecer estado del remolque para {0}',
    reasonForBeingLate: 'razón para llegar tarde',
    flaggedReservationInfo: 'cambiar información de reserva de {0}',
    youDoNotHavePermissionToAddFloorLocations: 'No tiene permiso para agregar ubicaciones de pisos',
    youDoNotHavePermissionToEditFloorLocations: 'No tiene permiso para editar ubicaciones de pisos',
    inbound: 'entrante',
    outbound: 'saliente',
    areYouSureYouWantToRenameFloorLocationName: '¿Está seguro de que desea cambiar el nombre de la ubicación del piso?',
    trailerIsLate: 'el remolque llega tarde',
    trailerHasArrived: 'el remolque ha llegado',
    newReservationError: 'No se pueden hacer nuevas reservas en el modo de edición.',
    itemsStillRequireLoadComplete: 'Se han ordenado los artículos que aún requieren Load Complete para actualizar con precisión el inventario de carga.',
    floorLocation: 'ubicaciones de pisos',
    done: 'hecho',
    weekly: 'semanalmente',
    monthly: 'mensualmente',
    first: '1st',
    second: '2nd',
    third: '3rd',
    fourth: '4th',
    fifth: '5th',
    warehousePutAway: 'Almacén Guardado',
    scanALocation: 'Escanear una ubicación',
    scanAnItem: 'Escanear un artículo',
    findFloorLocation: 'encontrar la ubicación del piso',
    vacantFloorLocations: 'Ubicaciones de pisos vacantes',
    noVacantFloorLocations: 'No hay ubicaciones de pisos vacantes',
    floorLocationsAlreadyHolding: 'Ubicaciones de pisos que ya están disponibles {0}',
    noFloorLocationsAlreadyHolding: 'No hay ubicaciones de piso disponibles {0}',
    itemsNotInPickPlan: 'Artículos no en el plan de selección',
    skusToPick: 'SKU para elegir',
    tag: 'etiqueta',
    required: 'requerido',
    picked: 'escogido',
    warehouseShipping: 'Envío de almacén',
};

export { commonEn, commonEs };
