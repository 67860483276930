import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22160489"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "error"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, {
    class: _normalizeClass(["checkbox-input", {'inline': _ctx.inlineLeft || _ctx.inlineRight, 'inline-left': _ctx.inlineLeft}]),
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs
  }, {
    default: _withCtx(() => [
      (_ctx.label || _ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.error), 1))
              : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_b_form_checkbox, {
        disabled: _ctx.disabled,
        "model-value": _ctx.modelValue,
        class: "custom-checkbox",
        onInput: _ctx.handleChange
      }, null, 8, ["disabled", "model-value", "onInput"])
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs", "class"]))
}