import { TransactionStatus } from '@/domain/TransactionStatus';

export type TransactionStatusTranslation = { [key in keyof typeof TransactionStatus]: string };

const transactionStatusEn: TransactionStatusTranslation = {
    ACTIVE: 'active',
    ALLOCATED: 'allocated',
    AWAITING_PICK: 'awaiting pick',
    CONFIRMED: 'confirmed',
    DELETED: 'deleted',
    DELIVERY_CONFIRMATION_PENDING: 'delivery confirmation pending',
    DELIVERY_CONFIRMED: 'delivery confirmed',
    DISPUTED: 'disputed',
    HELD: 'held',
    IN_TRANSIT: 'in transit',
    PICKED: 'picked',
    PLANNED: 'planned',
    ORDERED: 'ordered',
    SCHEDULED: 'scheduled',
    TENDERED: 'tendered',
};

const transactionStatusEs: TransactionStatusTranslation = {
    ACTIVE: 'activo',
    ALLOCATED: 'asignado',
    AWAITING_PICK: 'en espera de elección',
    CONFIRMED: 'confirmado',
    DELETED: 'eliminado',
    DELIVERY_CONFIRMATION_PENDING: 'confirmación de entrega pendiente',
    DELIVERY_CONFIRMED: 'confirmación de entrega',
    DISPUTED: 'disputa',
    HELD: 'retenido',
    IN_TRANSIT: 'en tránsito',
    PICKED: 'escogido',
    PLANNED: 'planeado',
    ORDERED: 'pedido',
    SCHEDULED: 'programado',
    TENDERED: 'licitado',
};

export { transactionStatusEn, transactionStatusEs };
