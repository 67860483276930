import { reactive } from 'vue';
import { store } from '@/decorators/store';
import { CoreStore } from '@/store/CoreStore';
import { PermissionString } from '@/services/permissions/Permissions';
import Role from '@/domain/Role';
import Permission from '@/domain/Permission';

export type State = {
    roles: Array<Role>;
};

@store
export default class PermissionsStore {
    private coreStore: CoreStore;

    constructor(coreStore: CoreStore) {
        this.coreStore = coreStore;
    }

    private state = reactive<State>({
        roles: [],
    });

    get roles(): Array<Role> {
        return this.state.roles;
    }

    get permissions(): Array<Permission> {
        return this.state.roles.map((r) => r.rolePermissions).flat();
    }

    get permissionStringList(): Array<PermissionString> {
        return this.permissions.map((p) => p.name);
    }

    public setUserRoles(roles: Array<Role>) {
        this.state.roles = roles;
    }
}
