import { useRouter } from 'vue-router';
import { onMounted, Ref, ref } from 'vue';
import { ModuleTypes } from '@/router/routes';

type UseModuleUtil = {
    activeModule: Ref<ModuleTypes | null>
};

export default function useModuleUtil(): UseModuleUtil {
    const router = useRouter();
    const activeModule = ref<ModuleTypes | null>(null);

    onMounted(() => {
        activeModule.value = router.currentRoute.value.meta.module as ModuleTypes;
    });

    router.afterEach(() => {
        activeModule.value = router.currentRoute.value.meta.module as ModuleTypes;
    });

    return {
        activeModule,
    };
}
