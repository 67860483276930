import { computed, ComputedRef } from 'vue';
import getID from '@/components/bootstrap-library/utils/getID';

function useId(id?: string, suffix?: string): ComputedRef<string> {
    const computedId = computed(() => id || getID(suffix));

    return computedId;
}

export default useId;
