import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b2a1e96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "error"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, {
    class: "text-input",
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs
  }, {
    default: _withCtx(() => [
      (_ctx.label || _ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.error), 1))
              : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
          ]))
        : _createCommentVNode("", true),
      (_ctx.textArea)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_b_form_textarea, {
              class: "text-input-form-area",
              "model-value": _ctx.modelValue,
              disabled: _ctx.disabled,
              autofocus: _ctx.autofocus,
              placeholder: _ctx.placeholder,
              onInput: _ctx.handleChange
            }, null, 8, ["model-value", "disabled", "autofocus", "placeholder", "onInput"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_b_form_input, {
              class: "text-input-form-input",
              "model-value": _ctx.modelValue,
              autofocus: _ctx.autofocus,
              disabled: _ctx.disabled,
              placeholder: _ctx.placeholder,
              formatter: _ctx.formatter,
              autocomplete: _ctx.autocomplete,
              readonly: _ctx.readonly,
              "more-info": _ctx.moreInfo,
              type: _ctx.type,
              required: _ctx.required,
              onInput: _ctx.handleChange
            }, null, 8, ["model-value", "autofocus", "disabled", "placeholder", "formatter", "autocomplete", "readonly", "more-info", "type", "required", "onInput"])
          ]))
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs"]))
}