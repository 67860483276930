import { createRouter, createWebHistory, RouterOptions } from 'vue-router';
import { navigationGuard } from '@okta/okta-vue';

import routes from '@/router/routes';

const router = createRouter(<RouterOptions>{
    history: createWebHistory(),
    routes,
});

router.beforeEach(navigationGuard);

export default router;
