import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a2236094"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","flex-grow":"1"} }
const _hoisted_2 = { id: "table-head" }
const _hoisted_3 = {
  key: 0,
  style: {"width":"8px"}
}
const _hoisted_4 = ["id"]
const _hoisted_5 = { style: {"display":"flex"} }
const _hoisted_6 = ["onMouseup"]
const _hoisted_7 = { class: "sort-display-container" }
const _hoisted_8 = {
  key: 2,
  class: "sort-position"
}
const _hoisted_9 = ["onMousedown"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["value", "onClick"]
const _hoisted_13 = ["data-column-name", "onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_17 = { class: "loading-content" }
const _hoisted_18 = {
  key: 1,
  class: "empty-container"
}
const _hoisted_19 = { class: "empty-content" }
const _hoisted_20 = {
  key: 0,
  class: "side-pane-head"
}
const _hoisted_21 = { class: "side-pane-content" }
const _hoisted_22 = {
  key: 0,
  class: "pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_empty_state = _resolveComponent("empty-state")!
  const _component_b_pagination = _resolveComponent("b-pagination")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    id: "table-container",
    class: _normalizeClass(['table-container', { 'is-dragging': _ctx.isDragging }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(['b-table', !_ctx.allowOverflow ? 'table-responsive' : '']),
        style: _normalizeStyle([_ctx.tableClass])
      }, [
        _createElementVNode("table", {
          class: _normalizeClass(['table', { 'table-hover': _ctx.hasRowClick || _ctx.enableMultiselect }, _ctx.verticalTextAlignmentClass]),
          style: {"table-layout":"fixed"}
        }, [
          _createElementVNode("thead", {
            class: "b-table-head",
            style: _normalizeStyle([_ctx.headClass])
          }, [
            _createElementVNode("tr", _hoisted_2, [
              (_ctx.enableMultiselect)
                ? (_openBlock(), _createElementBlock("th", _hoisted_3, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.allSelected) = $event)),
                      type: "checkbox",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectAll && _ctx.selectAll(...args)))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.state.allSelected]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnKeys, (key, index) => {
                return (_openBlock(), _createElementBlock("th", {
                  id: `th-${key}`,
                  key: index,
                  style: _normalizeStyle({ width: _ctx.getColumnWidthByKey(key) }),
                  scope: "col",
                  class: _normalizeClass([{ 'no-cursor': _ctx.getIgnoreSortByKey(key) }])
                }, [
                  _renderSlot(_ctx.$slots, `head(${key})`, {}, () => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", {
                        style: _normalizeStyle({ width: '100%', textAlign: _ctx.getColumnDataByKey(key).align })
                      }, [
                        _createElementVNode("span", {
                          class: "column-head-title",
                          onMouseup: () => _ctx.sortByTableColumn(key)
                        }, _toDisplayString(_ctx.getColumnDataByKey(key).label), 41, _hoisted_6),
                        (!!_ctx.getColumnDataByKey(key).headerBadge)
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getColumnDataByKey(key).headerBadge), {
                              key: 0,
                              class: "header-badge"
                            }))
                          : _createCommentVNode("", true)
                      ], 4),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.sortDirection(key) === 'ascending')
                          ? (_openBlock(), _createBlock(_component_faicon, {
                              key: 0,
                              icon: "arrow-up"
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.sortDirection(key) === 'descending')
                          ? (_openBlock(), _createBlock(_component_faicon, {
                              key: 1,
                              icon: "arrow-down"
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.sortArray.length > 1 && (_ctx.sortPosition(key) ?? 0) > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.sortPosition(key)), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", {
                      class: "table-column-slider",
                      onMousedown: ($event: any) => (_ctx.startDrag(key, $event))
                    }, null, 40, _hoisted_9)
                  ], true)
                ], 14, _hoisted_4))
              }), 128))
            ])
          ], 4),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginatedItems, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                _createElementVNode("tr", {
                  class: _normalizeClass([_ctx.striped ? 'striped' : '', _ctx.accentuateRows ? 'accentuate-row' : '']),
                  style: _normalizeStyle({ height: _ctx.rowHeight }),
                  onClick: () => _ctx.onRowClick(item.value)
                }, [
                  (_ctx.enableMultiselect)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
                          type: "checkbox",
                          value: item.value,
                          onClick: ($event: any) => (_ctx.onRowSelect(item.value))
                        }, null, 8, _hoisted_12), [
                          [_vModelCheckbox, _ctx.selectedItems]
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfColumns, (num) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: num,
                      style: _normalizeStyle({ textAlign: _ctx.getColumnDataByIndex(num - 1).align }),
                      "data-column-name": _ctx.getColumnDataByIndex(num - 1).key,
                      onClick: ($event: any) => (_ctx.getColumnDataByIndex(num - 1).key !== 'action' && _ctx.onRowSelect(item.value))
                    }, [
                      _renderSlot(_ctx.$slots, `cell(${_ctx.getColumnDataByIndex(num - 1).key})`, {
                        item: item.value
                      }, () => [
                        _createTextVNode(_toDisplayString(_ctx.getColumnDataForUI(num - 1, item.value as object)), 1)
                      ], true)
                    ], 12, _hoisted_13))
                  }), 128))
                ], 14, _hoisted_10),
                (item.hasChildren())
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.getChildren(), (groupItem, groupIndex) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: `${index}-${groupIndex}`,
                        class: "group-row",
                        style: _normalizeStyle({ height: _ctx.rowHeight }),
                        onClick: () => _ctx.onRowClick(groupItem.value)
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfColumns, (num) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: num,
                            onClick: ($event: any) => (_ctx.getColumnDataByIndex(num - 1).key !== 'action' && _ctx.onRowSelect(item.value))
                          }, [
                            _renderSlot(_ctx.$slots, `cell(${_ctx.getColumnDataByIndex(num - 1).key})`, {
                              item: groupItem.value
                            }, () => [
                              _createTextVNode(_toDisplayString(_ctx.getColumnDataForUI(num - 1, groupItem.value as object)), 1)
                            ], true)
                          ], 8, _hoisted_15))
                        }), 128))
                      ], 12, _hoisted_14))
                    }), 128))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ], 2),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _renderSlot(_ctx.$slots, "loading", {}, () => [
                  _createVNode(_component_b_spinner)
                ], true)
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loading && _ctx.showEmpty && _ctx.items.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _renderSlot(_ctx.$slots, "empty", {}, () => [
                  _createVNode(_component_empty_state, {
                    style: {"background-color":"white"},
                    title: _ctx.emptyState.title,
                    icon: _ctx.emptyState.icon,
                    button: _ctx.emptyState.button,
                    message: _ctx.emptyState.message
                  }, null, 8, ["title", "icon", "button", "message"])
                ], true)
              ])
            ]))
          : _createCommentVNode("", true)
      ], 6),
      (_ctx.hasSidePane)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "side-pane-container",
            style: _normalizeStyle({ minWidth: _ctx.computedSidePaneOptions.width, borderLeft: '1px solid $gray-400', ..._ctx.tableClass })
          }, [
            (!_ctx.computedSidePaneOptions.hideHeader)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, "side-pane-head", {}, () => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.computedSidePaneOptions.title), 1)
                    ], true)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_21, [
              _renderSlot(_ctx.$slots, "side-pane", {}, undefined, true)
            ])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showPagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createVNode(_component_b_pagination, {
            modelValue: _ctx.currentPage,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentPage) = $event)),
            "total-rows": _ctx.filteredItems.length,
            "per-page": _ctx.perPage
          }, null, 8, ["modelValue", "total-rows", "per-page"])
        ]))
      : _createCommentVNode("", true)
  ], 2)), [
    [_vShow, _ctx.show]
  ])
}