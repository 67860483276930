export default class InventoryCategory {
    constructor(init?: Partial<InventoryCategory>) {
        Object.assign(this, init);
    }

    public id!: number;

    public description!: string;

    public canMove!: boolean;

    public staticTypeIdentifier?: string;

    public toKey(): string {
        return `category${this.id}`;
    }
}

export enum StaticInventoryCategory {
    InboundGoods = 'InboundGoods',
    Wash = 'Wash',
    Scrap = 'Scrap',
    FinishedGoods = 'FinishedGoods',
    LostAndFound = 'LostAndFound',
    Glass = 'Glass',
    Repair = 'Repair',
}

export type InventoryCategorySelectItem = {
    value: number | null;
    text: String;
    disabled?: boolean;
};
