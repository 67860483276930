import { reactive } from 'vue';
import { store } from '@/decorators/store';
import { CoreStore } from '@/store/CoreStore';
import PEMSettingsDTO from '@/dtos/PEMSettingsDTO';

type State = {
    orderDateRangeStart: number;
    orderDateRangeStop: number;
    dashboardUrl: string;
};

@store
export default class PEMSettingsStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        orderDateRangeStart: 10,
        orderDateRangeStop: 30,
        dashboardUrl: '',
    });

    public init(data: PEMSettingsDTO) {
        this.state.orderDateRangeStart = data.orderDateRangeStart;
        this.state.orderDateRangeStop = data.orderDateRangeEnd;
        this.state.dashboardUrl = data.dashboardUrl;
    }

    get orderDateRangeStart(): number {
        return this.state.orderDateRangeStart;
    }

    get orderDateRangeEnd(): number {
        return this.state.orderDateRangeStop;
    }

    get dashboardUrl(): string {
        return this.state.dashboardUrl;
    }
}
