import PushNotificationSubscription from '@/store/utility/PushNotificationSubscription';
import SideBarMenuChild from './SideBarMenuChild';

// TODO - I cant find what type this should be, the function is defineComponent
type VueDefineComponent = unknown;

export default class SideBarMenuItem {
    public static TYPE = {
        HEADER: 'header',
        BUTTON: 'button',
        PARENT: 'parent',
        HOVER: 'hover',
        EXTERNAL: 'external',
    };

    public id: number = 0;

    public title: string = '';

    public icon?: string = '';

    public routeName?: string = '';

    public externalUrl?: string = '';

    public children?: SideBarMenuChild[] = [];

    public type: string = ''; // header, parent, button

    public show?: boolean = true;

    public pushNotificationSubscription?: PushNotificationSubscription;

    public hover?: VueDefineComponent; // not the correct type, just put this here to help indicate what it should be

    public isSectionHeader?: boolean = false;

    public isDefaultExpanded?: boolean = false;

    constructor(init?: Partial<SideBarMenuItem>) {
        Object.assign(this, init);
    }
}
