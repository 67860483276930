import RepairPart from '@/domain/Repair/RepairPart';

export default class RepairPartQuantity {
    public repairPart: RepairPart = new RepairPart();

    public repairId: number = 0;

    public repairPartId: number = 0;

    public quantity: number = 0;

    public constructor(init?: Partial<RepairPartQuantity>) {
        Object.assign(this, init);
    }

    public toJSON() {
        return {
            repairId: this.repairId,
            repairPartId: this.repairPartId,
            quantity: this.quantity,
        };
    }
}
