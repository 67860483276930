import { reactive, ref } from 'vue';
import { getTranslation } from '@/services/TranslationService';

export enum NotificationID {
    SHIPPING_SCAN_NOT_FOUND = 'shipping-scan-not-found',
    RECEIVING_SCAN_NOT_FOUND = 'receiving-scan-not-found',
    INVENTORY_SCAN_NOT_FOUND = 'inventory-scan-not-found',
    MISSING_FIELDS = 'missing-fields',
}

export type NotificationType = 'success' | 'warning' | 'danger';

export type NotificationProp = {
    id?: string; // only used if you want to update the message of an existing notification
    head?: string;
    message: Array<string> | string; // can either display as single message or display as a list
    time?: number; // ms
    type?: NotificationType;
    refresh?: boolean; // only for when popups with ids that persist, this resets the message array
};

interface Notification extends NotificationProp {
    visible: boolean;
    message: Array<string>;
}

export const notifications = ref<Array<Notification>>([]);

export function useNotification() {
    function cleanup() {
        for (let i = 0; notifications.value.length > i; i++) {
            if (notifications.value[i].visible === false || notifications.value[i].message.length === 0) {
                notifications.value.splice(i, 1);
            }
        }
    }

    function close(id: string) {
        for (let i = 0; notifications.value.length > i; i++) {
            if (notifications.value[i].id === id) {
                notifications.value.splice(i, 1);
            }
        }
    }

    function formatMessage(message: Array<string> | string): Array<string> {
        return message instanceof Array ? message : [message];
    }

    function show(props: NotificationProp) {
        cleanup();
        const index = notifications.value.findIndex((notif) => notif.id === props.id);
        if (props.id && index > -1) {
            // if the id of the notification exists in the active list, update the message, and push it to beginning of array
            const updatedNotification: Notification = {
                ...notifications.value[index],
                message: props.refresh ? formatMessage(props.message) : [...formatMessage(props.message), ...notifications.value[index].message],
            };
            notifications.value.splice(index, 1);
            notifications.value.push(updatedNotification);
        } else {
            const notification = reactive<Notification>({
                visible: true,
                id: props.id,
                head: props.head,
                message: formatMessage(props.message),
                time: props.time,
                type: props.type,
            });
            if (props.time) {
                setTimeout(() => {
                    notification.visible = false;
                }, props.time);
            }
            notifications.value.push(notification);
        }
    }

    function showSuccess(message?: string, id?: string, time?: number) {
        show({
            id,
            head: getTranslation('core.validation.success').toUpperCase(),
            message: message != null ? message : '',
            type: 'success',
            time: time || 3000,
        });
    }

    function showErrorList(message: Array<string>, id?: string, time?: number) {
        show({
            id,
            head: getTranslation('core.validation.error').toUpperCase(),
            message,
            type: 'danger',
            time: time || 15000,
        });
    }

    function showError(message?: string, id?: string, time?: number) {
        showErrorList([message || ''], id, time);
    }

    function showValidationError(errors: Array<string>, id?: string, time?: number) {
        show({
            id,
            head: getTranslation('core.validation.formError').toUpperCase(),
            message: errors,
            type: 'danger',
            refresh: true,
            time: time || 10000,
        });
    }

    function showWarning(message?: string, id?: string, time?: number) {
        show({
            id,
            head: getTranslation('core.validation.warning').toUpperCase(),
            message: message != null ? message : '',
            type: 'warning',
            time: time || 3000,
        });
    }

    return {
        notifications,
        show,
        close,
        showSuccess,
        showError,
        showErrorList,
        showWarning,
        showValidationError,
    };
}
