export type DomainTranslation = {
    id: string;
    location: string;
    locations: string;
    status: string;
    carrier: string;
    trailer: string;
    supplier: string;
    transactions: string;
    cycleCount: string;
    inventory: string;
    itemTransformation: string;
    needBy: string;
    shortName: string;
    name: string;
    quantityOnHand: string;
    palletQty: string;
    quantity: string;
    counts: string;
    totalPlannedContainers: string;
    toLocation: string;
    fromLocation: string;
    actualQuantity: string;
    plannedQuantity: string;
    receivedQuantity: string;
    direction: string;
    placementDate: string;
    deliveryDate: string;
    outbound: string;
    inbound: string;
    transactionNumber: string;
    shipDate: string;
    carrierReferenceNumber: string;
    partnerReferenceNumber: string;
    plannedDeliveryDate: string;
    licensePlateNumber: string;
    trailerType: string;
    trailerTypes: string;
    trailerNumber: string;
    disputeNotes: string;
    disputeImages: string;
    transactionItems: string;
    containerNumber: string;
    cycleCountReport: string;
    image: string;
    containerName: string;
    dateCounted: string;
    newCount: string;
    systemCount: string;
    variance: string;
    adjustmentQuantity: string;
    count: string;
    locationType: string;
    locationTypes: string;
    dueDate: string;
    totalItems: string;
    totalCounted: string;
    percentComplete: string;
    adjustmentAvailableCount: string;
    closedDate: string;
    productionPart: string;
    productionParts: string;
    partNumber: string;
    shipFrom: string;
    shipTo: string;
    effectiveFromDate: string;
    effectiveFromDateShort: string;
    effectiveToDate: string;
    effectiveToDateShort: string;
    quantityPerContainer: string;
    palletized: string;
    palletContainerNumber: string;
    containersPerPallet: string;
    includeTopCap: string;
    enabled: string;
    description: string;
    shippingReferenceNumber: string;
    dockArrivalTime: string;
    dock: string;
    driver: string;
    notes: string;
    shipment: string;
    shipmentWithNumberSymbol: string;
    transactionWithNumberSymbol: string;
    dockDoor: string;
    estimatedQuantity: string;
    sortStation: string;
    repair: string;
    repairParts: string;
    wash: string;
    scrap: string;
    finishedGoods: string;
    glass: string;
    wet: string;
    item: string;
    inventoryCategoryShort: string;
    itemTags: string;
    newTransaction: string;
    items: string;
    allItems: string;
    onRoute: string;
    supplierReferenceNumberShort: string;
    carrierReferenceNumberShort: string;
    driverName: string;
    licensePlateNumberShort: string;
    lotNumberShort: string;
    trailerNumberShort: string;
    sealNumberShort: string;
    shippingCloseout: string;
    shipmentCreated: string;
    shipmentSaved: string;
    warehouseMovement: string;
    onHandInventory: string;
    replaceTag: string;
    oldTag: string;
    newTag: string;
    addTag: string;
    version: string;
    barcode: string;
    fromCategory: string;
    toCategory: string;
    inboundReceipt: string;
    receiptWithNumberSymbol: string;
    active: string;
    addNewRoute: string;
    awaitingReview: string;
    calendar: string;
    carriers: string;
    change: string;
    city: string;
    closedReports: string;
    closeTime: string;
    collapsedHeight: string;
    collapsible: string;
    completedAndAwaitingReview: string;
    config: string;
    contact: string;
    containersToCount: string;
    country: string;
    countStatus: string;
    createdOn: string;
    cubicVolume: string;
    cycleCountCreationDaysBeforeDue: string;
    dateAndTime: string;
    dateFrom: string;
    dateRequired: string;
    dateTo: string;
    days: string;
    demandForecast: string;
    details: string;
    displayName: string;
    dockDoors: string;
    shippingPlanner: string;
    dockTimeSlot: string;
    doorNumber: string;
    dotRegistration: string;
    editAddress: string;
    editDetails: string;
    editDock: string;
    editItem: string;
    editItems: string;
    editLocationType: string;
    editRoute: string;
    editTrailerType: string;
    effectiveDateFrom: string;
    effectiveDateTo: string;
    email: string;
    emailAddress: string;
    emailOptional: string;
    emailReminderNotificationDaysBeforeDue: string;
    equipment: string;
    externalUser: string;
    firstName: string;
    freightCode: string;
    frequency: string;
    fromLocations: string;
    height: string;
    hours: string;
    importErrors: string;
    importErrorsAdvancedShippingNotice: string;
    importErrorsDemandForecast: string;
    importErrorsPlanForEveryPart: string;
    importErrorsPartnerOrder: string;
    inboundInTransit: string;
    inService: string;
    inventoryAdjustmentThreshold: string;
    inventoryCategory: string;
    isUnitLoad: string;
    unitLoadPieces: string;
    itemNumber: string;
    itemTable: string;
    itemType: string;
    language: string;
    lastName: string;
    length: string;
    licenseNumberShort: string;
    loadType: string;
    localUser: string;
    locationAddresses: string;
    locationNumber: string;
    locationTypeDetails: string;
    loginType: string;
    lotNumber: string;
    make: string;
    masterData: string;
    maxLoadCapacity: string;
    minutes: string;
    model: string;
    newAddress: string;
    newLocation: string;
    newLocationType: string;
    number: string;
    includeInPushPlanning: string;
    newTrailerType: string;
    nonWorkDaysCalendar: string;
    open24Hours: string;
    openTime: string;
    operations: string;
    partnerOrderMultiple: string;
    outboundInTransit: string;
    owned: string;
    partItemNumber: string;
    partItemQuantity: string;
    partnerOrder: string;
    partnerReferenceNumberShort: string;
    pastDue: string;
    phone: string;
    phoneNumber: string;
    phoneNumberOptional: string;
    plannedDepartureDateTime: string;
    preferredServiceCenter: string;
    preferredServiceCenters: string;
    preferredTrailerType: string;
    propulsion: string;
    requestedQuantityRoundedUp: string;
    riskFlags: string;
    risks: string;
    role: string;
    roleDescription: string;
    roleName: string;
    rolePermissions: string;
    roles: string;
    routeConfiguration: string;
    scac: string;
    seal: string;
    sealNumber: string;
    secondPreferredServiceCenter: string;
    serialNumber: string;
    setting: string;
    settings: string;
    shipFromInventoryCategories: string;
    shipFromInventoryCategory: string;
    shippingNotice: string;
    sortStations: string;
    state: string;
    streetAddress: string;
    targetDaysOnHandOverride: string;
    taxId: string;
    teamSort: string;
    thirdPreferredServiceCenter: string;
    timeZone: string;
    toLocations: string;
    topCapItemNumber: string;
    transaction: string;
    transactionLines: string;
    transactionNumberShort: string;
    transitTime: string;
    type: string;
    typeId: string;
    unitCostUSD: string;
    unitLoad: string;
    usageType: string;
    user: string;
    userDetails: string;
    userLocations: string;
    username: string;
    userRoles: string;
    users: string;
    weight: string;
    width: string;
    zip: string;
    shippingMethod: string;
    defaultCarrier: string;
    minTrailerPercent: string;
    maxLTLDayRange: string;
    primaryPlanningItem: string;
    planningItemOverride: string;
    averageDailyUsage: string;
    overrideQuantity: string;
    overrideExpirationDate: string;
    expires: string;
    averageDailyUsageOverride: string;
    errorsOnForm: string;
    partnerReferenceNumberSmall: string;
    equipmentType: string;
    equipmentInspectionInterval: string;
    sortInventoryCategories: string;
    adjustedForSorts: string;
    systemInventory: string;
    dashboardUsername: string;
    additionalEstimatedQuantity: string;
    finalizeEstimate: string;
    confirmFinalizeMessage: string;
    estimateMustBeFinalized: string;
    estimateFinalized: string;
    reorderPoint: string;
    availableRepairs: string;
    billingPrice: string;
    laborBillingRate: string;
    repairable: string;
    repairDescription: string;
    repairName: string;
    repairPart: string;
    repairPartInventory: string;
    repairPartConfirmDelete: string;
    repairPartQuantities: string;
    repairs: string;
    repairType: string;
    includeZeroQtyRows: string;
    inHouseRepairs: string;
    equipmentNumber: string;
    adjust: string;
    countItemsUnderLoad: string;
    underLoadPieces: string;
    itemNotRepairable: string;
    selectRepairToContinue: string;
    transactionId: string;
    saveShipment: string;
    arrivalDate: string;
    originalTransactionNumber: string;
    overridePutAwayLocation: string;
    processedPutAway: string;
    processedPutAwayPullFrom: string;
    putAwayLocation: string;
    loosePiecesShort: string;
    underLoadPiecesShort: string;
    maxLoadPerTimeslot: string;
    timeslotDuration: string;
    totalCount: string;
    activeDirectory: string;
    activeDirectoryUser: string;
    billTo: string;
    fromItem: string;
    toItem: string;
    noTaggedItems: string;
    scanItemMismatch: string;
    productionPlant: string;
    productionDate: string;
    stackHeight: string;
    fullTrailerLoad: string;
    forkliftCertified: string;
    forkliftCertificationExpiration: string;
    forkliftCertificationDate: string;
    forkliftCertificationMessage: string;
    reservationDateTime: string;
    floorLocation: string;
    floorLocations: string;
    dimensions: string;
    pickable: string;
    editFloorLocation: string;
    inventoryCategoriesToCount: string;
    category: string;
    receiptDate: string;
    cycleCountManager: string;
    cycleCountReview: string;
    categories: string;
    createCycleCount: string;
    schedule: string;
    action: string;
    repeatEvery: string;
    repeat: string;
    isActive: string;
};

const domainEn: DomainTranslation = {
    id: 'id',
    location: 'location',
    locations: 'locations',
    status: 'status',
    carrier: 'carrier',
    trailer: 'trailer',
    supplier: 'supplier',
    transactions: 'transactions',
    cycleCount: 'cycle count',
    inventory: 'inventory',
    itemTransformation: 'item transformation',
    needBy: 'need by',
    shortName: 'short name',
    name: 'name',
    quantityOnHand: 'quantity on hand',
    palletQty: 'pallet quantity',
    quantity: 'quantity',
    counts: 'counts',
    totalPlannedContainers: 'total planned items',
    toLocation: 'to location',
    fromLocation: 'from location',
    actualQuantity: 'actual quantity',
    plannedQuantity: 'planned quantity',
    receivedQuantity: 'received quantity',
    direction: 'direction',
    transactionNumber: 'transaction number',
    placementDate: 'placement date',
    deliveryDate: 'delivery date',
    outbound: 'outbound',
    inbound: 'inbound',
    shipDate: 'ship date',
    carrierReferenceNumber: 'carrier reference number',
    partnerReferenceNumber: 'partner reference number',
    plannedDeliveryDate: 'planned delivery date',
    licensePlateNumber: 'license plate number',
    trailerNumber: 'trailer number',
    trailerType: 'trailer type',
    trailerTypes: 'trailer types',
    disputeNotes: 'dispute notes',
    disputeImages: 'dispute images',
    transactionItems: 'transaction items',
    containerNumber: 'item number',
    cycleCountReport: 'cycle count report',
    image: 'image',
    containerName: 'item name',
    dateCounted: 'date counted',
    newCount: 'new count',
    systemCount: 'system count',
    variance: 'variance',
    adjustmentQuantity: 'adjustment quantity',
    count: 'count',
    locationType: 'location type',
    locationTypes: 'location types',
    dueDate: 'due date',
    totalItems: 'total items',
    totalCounted: 'total counted',
    percentComplete: 'percent complete',
    adjustmentAvailableCount: 'adjustments available',
    closedDate: 'closed date',
    productionPart: 'PFEP',
    productionParts: 'production parts',
    partNumber: 'part number',
    shipFrom: 'ship from',
    shipTo: 'ship to',
    effectiveFromDate: 'effective from date',
    effectiveFromDateShort: 'eff. from date',
    effectiveToDate: 'effective to date',
    effectiveToDateShort: 'eff. to date',
    quantityPerContainer: 'qty per item',
    palletized: 'palletized',
    palletContainerNumber: 'pallet item number',
    containersPerPallet: 'items per pallet',
    includeTopCap: 'include top cap',
    enabled: 'enabled',
    description: 'description',
    shippingReferenceNumber: 'shipping reference number',
    dockArrivalTime: 'dock arrival time',
    dock: 'dock',
    driver: 'driver',
    notes: 'notes',
    shipment: 'shipment',
    shipmentWithNumberSymbol: 'shipment #',
    transactionWithNumberSymbol: 'transaction #',
    dockDoor: 'dock door',
    estimatedQuantity: 'estimated quantity',
    sortStation: 'sort station',
    repair: 'repair',
    repairParts: 'repair parts',
    wash: 'wash',
    scrap: 'scrap',
    finishedGoods: 'finished goods',
    glass: 'class',
    wet: 'wet',
    item: 'item',
    inventoryCategoryShort: 'inv. category',
    itemTags: 'item tags',
    newTransaction: 'new transaction',
    items: 'items',
    allItems: 'all items',
    onRoute: 'on route',
    supplierReferenceNumberShort: 'supplier ref. #',
    carrierReferenceNumberShort: 'carrier ref. #',
    driverName: 'driver name',
    licensePlateNumberShort: 'license plate #',
    lotNumberShort: 'lot #',
    trailerNumberShort: 'trailer #',
    sealNumberShort: 'seal #',
    shippingCloseout: 'shipping closeout',
    shipmentCreated: 'shipment created',
    shipmentSaved: 'shipment saved',
    warehouseMovement: 'warehouse movement',
    onHandInventory: 'on hand inventory',
    replaceTag: 'replace tag',
    oldTag: 'old tag',
    newTag: 'new tag',
    addTag: 'add tag',
    version: 'version',
    barcode: 'barcode',
    fromCategory: 'from category',
    toCategory: 'to category',
    inboundReceipt: 'inbound receipt',
    receiptWithNumberSymbol: 'receipt #',
    active: 'active',
    addNewRoute: 'add new route',
    awaitingReview: 'awaiting review',
    calendar: 'calendar',
    carriers: 'carriers',
    change: 'change',
    city: 'city',
    closedReports: 'closed reports',
    closeTime: 'close time',
    collapsedHeight: 'collapsed height',
    collapsible: 'collapsible',
    completedAndAwaitingReview: 'completed and awaiting review',
    config: 'config',
    contact: 'contact',
    containersToCount: 'items to count',
    country: 'country',
    countStatus: 'count status',
    createdOn: 'created on',
    cubicVolume: 'cubic volume',
    cycleCountCreationDaysBeforeDue: 'cycle count creation - days before due',
    dateAndTime: 'date and time',
    dateFrom: 'date from',
    dateRequired: 'date required',
    dateTo: 'date to',
    days: 'days',
    demandForecast: 'demand forecast',
    details: 'details',
    displayName: 'display name',
    dockDoors: 'dock doors',
    shippingPlanner: 'shipping planner',
    dockTimeSlot: 'dock time slot',
    doorNumber: 'door number',
    dotRegistration: 'dot registration',
    editAddress: 'edit address',
    editDetails: 'edit details',
    editDock: 'edit dock',
    editItem: 'edit item',
    editItems: 'edit items',
    editLocationType: 'edit location type',
    editRoute: 'edit route',
    editTrailerType: 'edit trailer type',
    effectiveDateFrom: 'effective date from',
    effectiveDateTo: 'effective date to',
    email: 'email',
    emailAddress: 'email address',
    emailOptional: 'Email (Optional)',
    emailReminderNotificationDaysBeforeDue: 'email reminder notification - days before due',
    equipment: 'equipment',
    externalUser: 'external user',
    firstName: 'first name',
    freightCode: 'freight code',
    frequency: 'frequency',
    fromLocations: 'from locations',
    height: 'height',
    hours: 'hours',
    importErrors: 'import errors',
    importErrorsAdvancedShippingNotice: 'import errors - advanced shipping notice',
    importErrorsDemandForecast: 'import errors - demand forecast',
    importErrorsPlanForEveryPart: 'Import Errors - Plan For Every Part (PFEP)',
    importErrorsPartnerOrder: 'import errors - partner order',
    inboundInTransit: 'Inbound In-Transit',
    inService: 'in service',
    inventoryAdjustmentThreshold: 'inventory adjustment threshold',
    inventoryCategory: 'inventory category',
    isUnitLoad: 'is unit load',
    itemNumber: 'item number',
    itemTable: 'item table',
    itemType: 'item type',
    language: 'language',
    lastName: 'last name',
    length: 'length',
    licenseNumberShort: 'license #',
    loadType: 'load type',
    localUser: 'local user',
    locationAddresses: 'location addresses',
    locationNumber: 'location number',
    locationTypeDetails: 'location type details',
    loginType: 'login type',
    lotNumber: 'lot number',
    make: 'make',
    masterData: 'master data',
    maxLoadCapacity: 'max load capacity',
    minutes: 'minutes',
    model: 'model',
    newAddress: 'new address',
    newLocation: 'new location',
    newLocationType: 'new location type',
    number: 'number',
    includeInPushPlanning: 'include in push planning',
    newTrailerType: 'new trailer type',
    nonWorkDaysCalendar: 'Non-Work Days Calendar',
    open24Hours: 'open 24 hours',
    openTime: 'open time',
    operations: 'operations',
    partnerOrderMultiple: 'partner order multiple',
    outboundInTransit: 'Outbound In-Transit',
    owned: 'owned',
    partItemNumber: 'Part/Item Number',
    partItemQuantity: 'Part/Item Quantity',
    partnerOrder: 'Partner Order',
    partnerReferenceNumberShort: 'partner reference #',
    pastDue: 'past due',
    phone: 'phone',
    phoneNumber: 'phone number',
    phoneNumberOptional: 'Phone Number (Optional)',
    plannedDepartureDateTime: 'Planned Departure Date Time',
    preferredServiceCenter: 'preferred service center',
    preferredServiceCenters: 'preferred service centers',
    preferredTrailerType: 'preferred trailer type',
    propulsion: 'propulsion',
    requestedQuantityRoundedUp: 'requested quantity rounded up',
    riskFlags: 'risk flags',
    risks: 'risks',
    role: 'role',
    roleDescription: 'role description',
    roleName: 'role name',
    rolePermissions: 'role permissions',
    roles: 'roles',
    routeConfiguration: 'route configuration',
    scac: 'SCAC',
    seal: 'seal',
    sealNumber: 'seal number',
    secondPreferredServiceCenter: 'second preferred service center',
    serialNumber: 'serial number',
    setting: 'setting',
    settings: 'settings',
    shipFromInventoryCategories: 'ship from inventory categories',
    shipFromInventoryCategory: 'ship from inventory category',
    shippingNotice: 'shipping notice',
    sortStations: 'sort stations',
    state: 'state',
    streetAddress: 'street address',
    targetDaysOnHandOverride: 'target days on hand override',
    taxId: 'tax id',
    teamSort: 'team sort',
    thirdPreferredServiceCenter: 'third preferred service center',
    timeZone: 'timezone',
    toLocations: 'to locations',
    topCapItemNumber: 'top cap item number',
    transaction: 'transaction',
    transactionLines: 'transaction lines',
    transactionNumberShort: 'transaction #',
    transitTime: 'transit time',
    type: 'type',
    typeId: 'type id',
    unitCostUSD: 'Unit Cost (USD)',
    unitLoad: 'unit load',
    unitLoadPieces: 'unit load pieces',
    usageType: 'usage type',
    user: 'user',
    userDetails: 'user details',
    userLocations: 'user locations',
    username: 'username',
    userRoles: 'user roles',
    users: 'users',
    weight: 'weight',
    width: 'width',
    zip: 'zip',
    shippingMethod: 'shipping method',
    defaultCarrier: 'default carrier',
    minTrailerPercent: 'min trailer %',
    maxLTLDayRange: 'Max LTL Day Range',
    primaryPlanningItem: 'primary planning item',
    planningItemOverride: 'planning item override',
    averageDailyUsage: 'average daily usage',
    overrideQuantity: 'override quantity',
    overrideExpirationDate: 'override expiration date',
    expires: 'expires',
    averageDailyUsageOverride: 'average daily usage override',
    errorsOnForm: 'errors on form',
    partnerReferenceNumberSmall: 'partner ref #',
    equipmentType: 'equipment type',
    equipmentInspectionInterval: 'equipment inspection interval (hours)',
    sortInventoryCategories: 'sort inventory categories',
    adjustedForSorts: 'adjusted for sorts',
    systemInventory: 'system inventory',
    dashboardUsername: 'dashboard username',
    additionalEstimatedQuantity: 'additional estimated quantity',
    finalizeEstimate: 'finalize estimate',
    confirmFinalizeMessage: 'Are you sure you want to finalize estimation?',
    estimateMustBeFinalized: 'Estimate must be finalized',
    estimateFinalized: 'Estimate finalized',
    reorderPoint: 'reorder point',
    availableRepairs: 'available repairs',
    billingPrice: 'billing price',
    laborBillingRate: 'labor billing rate',
    repairable: 'repairable',
    repairDescription: 'repair description',
    repairName: 'repair name',
    repairPart: 'repair part',
    repairPartInventory: 'inventory',
    repairPartConfirmDelete: 'Are you sure you want to delete this repair part for all locations?',
    repairPartQuantities: 'repair part quantities',
    repairs: 'repairs',
    repairType: 'repair type',
    includeZeroQtyRows: 'Include zero quantity rows',
    inHouseRepairs: 'In-House Repairs',
    equipmentNumber: 'Equipment Number',
    adjust: 'adjust',
    countItemsUnderLoad: 'count items under load',
    underLoadPieces: 'under load pieces',
    itemNotRepairable: 'Item is not repairable',
    selectRepairToContinue: 'Select a repair to continue',
    transactionId: 'transaction id',
    saveShipment: 'Are you sure you want to save this shipment?',
    arrivalDate: 'arrival date',
    originalTransactionNumber: 'original transaction number',
    overridePutAwayLocation: 'override put away location',
    processedPutAway: 'processed put away',
    processedPutAwayPullFrom: 'processed put away pull from',
    putAwayLocation: 'put away location',
    loosePiecesShort: 'loose pieces',
    underLoadPiecesShort: 'under load pieces',
    maxLoadPerTimeslot: 'max load per timeslot',
    timeslotDuration: 'timeslot duration',
    totalCount: 'total count',
    activeDirectory: 'active directory',
    activeDirectoryUser: 'active directory user',
    billTo: 'bill to',
    fromItem: 'from item',
    toItem: 'to item',
    noTaggedItems: 'no tagged items',
    scanItemMismatch: 'Item scanned does not match the selected item type',
    productionPlant: 'production plant',
    productionDate: 'production date',
    stackHeight: 'stack height',
    fullTrailerLoad: 'full trailer load',
    forkliftCertified: 'Forklift Certified',
    forkliftCertificationExpiration: 'Certification Expiration Date',
    forkliftCertificationDate: 'Forklift Certification',
    forkliftCertificationMessage: 'Forklift Certification Expiration Date must be in the future',
    reservationDateTime: 'reservation time',
    floorLocation: 'floor location',
    floorLocations: 'floor locations',
    dimensions: 'dimensions',
    pickable: 'pickable',
    editFloorLocation: 'edit floor location',
    inventoryCategoriesToCount: 'categories to count',
    category: 'category',
    receiptDate: 'Receipt Date',
    cycleCountManager: 'cycle count manager',
    cycleCountReview: 'cycle count review',
    categories: 'categories',
    createCycleCount: 'create cycle count',
    schedule: 'schedule',
    action: 'action',
    repeatEvery: 'repeat every',
    repeat: 'repeat',
    isActive: 'is active'
};

const domainEs: DomainTranslation = {
    id: 'id',
    location: 'ubicación',
    locations: 'ubicaciones',
    status: 'estado',
    carrier: 'transportista',
    trailer: 'remolque',
    supplier: 'proveedor',
    transactions: 'transacciones',
    cycleCount: 'conteo ciclico',
    inventory: 'inventario',
    itemTransformation: 'transformación de artículos',
    needBy: 'fecha del requerimiento',
    shortName: 'nombre corto',
    name: 'nombre',
    quantityOnHand: 'cantidad actual',
    palletQty: 'cantidad de palets',
    quantity: 'cantidad',
    counts: 'cuenta',
    totalPlannedContainers: 'elementos planificados totales',
    toLocation: 'a la ubicación',
    fromLocation: 'desde la ubicación',
    actualQuantity: 'cantidad actual',
    plannedQuantity: 'cantidad planificada',
    receivedQuantity: 'cantidad recibida',
    direction: 'dirección',
    transactionNumber: 'número de transacción',
    placementDate: 'fecha de colocación',
    deliveryDate: 'fecha de entrega',
    outbound: 'salidas',
    inbound: 'entradas',
    shipDate: 'fecha de envío',
    carrierReferenceNumber: 'número de referencia del transportista',
    partnerReferenceNumber: 'número de referencia del socio',
    plannedDeliveryDate: 'fecha de entrega planificada',
    licensePlateNumber: 'numero de licencia',
    trailerNumber: 'número de trailer',
    trailerType: 'tipo de trailer',
    trailerTypes: 'tipos de trailers',
    disputeNotes: 'notas de disputa',
    disputeImages: 'imagen de disputa',
    transactionItems: 'artículos de transacción',
    containerNumber: 'número de artículo',
    cycleCountReport: 'reporte de conteo de ciclos',
    image: 'imagen',
    containerName: 'nombre del árticulo',
    dateCounted: 'fecha contada',
    newCount: 'nuevo conteo',
    systemCount: 'conteo de sistema',
    variance: 'diferencia',
    adjustmentQuantity: 'ajuste de cantidad',
    count: 'conteo',
    locationType: 'tipo de ubicacion',
    locationTypes: 'tipos de ubicación',
    dueDate: 'fecha de vencimiento',
    totalItems: 'total de artículos',
    totalCounted: 'total contados',
    percentComplete: 'porcentaje completo',
    adjustmentAvailableCount: 'ajustes disponibles',
    closedDate: 'fecha cerrada',
    productionPart: 'PFEP',
    productionParts: 'piezas de producción',
    partNumber: 'numero de pieza',
    shipFrom: 'enviar de',
    shipTo: 'enviar a',
    effectiveFromDate: 'fecha efectiva desde',
    effectiveFromDateShort: 'ef. desde la fecha',
    effectiveToDate: 'fecha efectiva a',
    effectiveToDateShort: 'ef. hasta la fecha',
    quantityPerContainer: 'cantidad por artículo',
    palletized: 'paletizado',
    palletContainerNumber: 'número de artículo de la tarima',
    containersPerPallet: 'artículos por tarima',
    includeTopCap: 'incluir tapa superior',
    enabled: 'activada',
    description: 'descripción',
    shippingReferenceNumber: 'número de referencia de envío',
    dockArrivalTime: 'hora de llegada al muelle',
    dock: 'muelle',
    driver: 'conductor',
    notes: 'notas',
    shipment: 'envio',
    shipmentWithNumberSymbol: 'envío #',
    transactionWithNumberSymbol: 'transacción #',
    dockDoor: 'puerta de muelle',
    estimatedQuantity: 'cantidad estimada',
    sortStation: 'estación de clasificación',
    repair: 'reparar',
    repairParts: 'partes de refacción',
    wash: 'lavar',
    scrap: 'chatarra',
    finishedGoods: 'productos terminados',
    glass: 'vidrio',
    wet: 'húmedo',
    item: 'artículo',
    inventoryCategoryShort: 'categoría de inventario',
    itemTags: 'etiquetas de artículos',
    newTransaction: 'nueva transacción',
    items: 'elementos',
    allItems: 'todos los artículos',
    onRoute: 'en ruta',
    supplierReferenceNumberShort: 'referencia del proveedor #',
    carrierReferenceNumberShort: 'referencia del transportista #',
    driverName: 'nombre del conductor',
    licensePlateNumberShort: 'licencia de placa #',
    lotNumberShort: 'lote #',
    trailerNumberShort: 'remolque #',
    sealNumberShort: 'sello #',
    shippingCloseout: 'cierre de envío',
    shipmentCreated: 'Envío creado',
    shipmentSaved: 'envío guardado',
    warehouseMovement: 'movimiento almacén',
    onHandInventory: 'inventario disponible',
    replaceTag: 'reemplazar etiqueta',
    oldTag: 'etiqueta antigua',
    newTag: 'nueva etiqueta',
    addTag: 'agregar etiqueta',
    version: 'versión',
    barcode: 'código de barras',
    fromCategory: 'de la categoría',
    toCategory: 'a categoría',
    inboundReceipt: 'recibo entrante',
    receiptWithNumberSymbol: 'recibo #',
    active: 'activo',
    addNewRoute: 'añadir nueva ruta',
    awaitingReview: 'pendiente de revisión',
    calendar: 'calendario',
    carriers: 'portadores',
    change: 'cambio',
    city: 'ciudad',
    closedReports: 'informes cerrados',
    closeTime: 'hora de cierre',
    collapsedHeight: 'altura colapsada',
    collapsible: 'colapsable',
    completedAndAwaitingReview: 'completado y pendiente de revisión',
    config: 'configuración',
    contact: 'contacto',
    containersToCount: 'contenedores para contar',
    country: 'país',
    countStatus: 'estado de conteo',
    createdOn: 'creado en',
    cubicVolume: 'volumen cubico',
    cycleCountCreationDaysBeforeDue: 'creación de recuento de ciclos - días antes de la fecha de vencimiento',
    dateAndTime: 'fecha y hora',
    dateFrom: 'fecha de',
    dateRequired: 'fecha requerida',
    dateTo: 'fecha para',
    days: 'dias',
    demandForecast: 'previsión de la demanda',
    details: 'detalles',
    displayName: 'nombre para mostrar',
    dockDoors: 'puertas de muelle',
    shippingPlanner: 'planificador de envíos',
    dockTimeSlot: 'franja horaria del muelle',
    doorNumber: 'numero de puerta',
    dotRegistration: 'registro de puntos',
    editAddress: 'editar dirección',
    editDetails: 'editar detalles',
    editDock: 'editar muelle',
    editItem: 'editar elemento',
    editItems: 'editar elementos',
    editLocationType: 'editar tipo de ubicación',
    editRoute: 'editar ruta',
    editTrailerType: 'editar tipo de tráiler',
    effectiveDateFrom: 'fecha efectiva desde',
    effectiveDateTo: 'fecha efectiva hasta',
    email: 'email',
    emailAddress: 'dirección de correo electrónico',
    emailOptional: 'Email (Opcional)',
    emailReminderNotificationDaysBeforeDue: 'notificación de recordatorio por correo electrónico - días antes del vencimiento',
    equipment: 'equipo',
    externalUser: 'usuario externo',
    firstName: 'primer nombre',
    freightCode: 'código de transporte',
    frequency: 'frecuencia',
    fromLocations: 'desde ubicaciones',
    height: 'altura',
    hours: 'horas',
    importErrors: 'errores de import',
    importErrorsAdvancedShippingNotice: 'errores de importación - aviso de envío avanzado',
    importErrorsDemandForecast: 'errores de importación - previsión de demanda',
    importErrorsPlanForEveryPart: 'Errores De Importación - Plan Para Cada Pieza (PFEP)',
    importErrorsPartnerOrder: 'errores de importación - orden de socio',
    inboundInTransit: 'Entrante En Tránsito',
    inService: 'en servicio',
    inventoryAdjustmentThreshold: 'rango de ajuste de inventario',
    inventoryCategory: 'categoría de inventario',
    isUnitLoad: 'es carga unitaria',
    itemNumber: 'número de artículo',
    itemTable: 'tabla de elementos',
    itemType: 'tipo de artículo',
    language: 'idioma',
    lastName: 'apellido',
    length: 'largo',
    licenseNumberShort: 'número de licencia',
    loadType: 'tipo de carga',
    localUser: 'usuario local',
    locationAddresses: 'direcciones de ubicación',
    locationNumber: 'número de ubicación',
    locationTypeDetails: 'detalles del tipo de ubicación',
    loginType: 'tipo de inicio de sesión',
    lotNumber: 'numero de lote',
    make: 'hacer',
    masterData: 'datos maestros',
    maxLoadCapacity: 'capacidad de carga máxima',
    minutes: 'minutos',
    model: 'modelo',
    newAddress: 'nueva direccion',
    newLocation: 'nueva ubicacion',
    newLocationType: 'nuevo tipo de ubicación',
    number: 'número',
    includeInPushPlanning: 'incluir en la planificación de inserción',
    newTrailerType: 'nuevo tipo de trailer',
    nonWorkDaysCalendar: 'Calendario de Días No Laborables',
    open24Hours: 'abierto las 24 horas',
    openTime: 'hora de apertura',
    operations: 'operaciones',
    partnerOrderMultiple: 'socio orden multiple',
    outboundInTransit: 'Saliente En Tránsito',
    owned: 'propiedad',
    partItemNumber: 'Número De Pieza/Artículo',
    partItemQuantity: 'Cantidad De Piezas/Artículos',
    partnerOrder: 'orden de socio',
    partnerReferenceNumberShort: 'número de referencia del socio',
    pastDue: 'vencido',
    phone: 'teléfono',
    phoneNumber: 'número de teléfono',
    phoneNumberOptional: 'Numero De Teléfono (Opcional)',
    plannedDepartureDateTime: 'fecha de salida prevista hora',
    preferredServiceCenter: 'centro de servicio preferido',
    preferredServiceCenters: 'centros de servicio preferidos',
    preferredTrailerType: 'tipo de trailer preferido',
    propulsion: 'propulsión',
    requestedQuantityRoundedUp: 'Cantidad Solicitada redondeada',
    riskFlags: 'aviso de riesgo',
    risks: 'riesgos',
    role: 'papel',
    roleDescription: 'descripción del rol',
    roleName: 'nombre de rol',
    rolePermissions: 'permisos de rol',
    roles: 'roles',
    routeConfiguration: 'configuración de ruta',
    scac: 'SCAC',
    seal: 'sello',
    sealNumber: 'número de sello',
    secondPreferredServiceCenter: 'segundo centro de servicio preferido',
    serialNumber: 'número de serie',
    setting: 'configuración',
    settings: 'ajustes',
    shipFromInventoryCategories: 'enviar desde categorías de inventario',
    shipFromInventoryCategory: 'enviar desde la categoría de inventario',
    shippingNotice: 'aviso de envío',
    sortStations: 'estaciones de clasificación',
    state: 'estado',
    streetAddress: 'dirección',
    targetDaysOnHandOverride: 'días objetivo en anulación manual',
    taxId: 'identificación del impuesto',
    teamSort: 'equipo de clasificación',
    thirdPreferredServiceCenter: 'tercer centro de servicio preferido',
    timeZone: 'Zona horaria',
    toLocations: 'a ubicaciones',
    topCapItemNumber: 'número de artículo de la tapa superior',
    transaction: 'transacción',
    transactionLines: 'líneas de transacción',
    transactionNumberShort: 'transacción #',
    transitTime: 'tiempo de transito',
    type: 'tipo',
    typeId: 'tipo de identificación',
    unitCostUSD: 'Costo Unitario (USD)',
    unitLoad: 'unidad de carga',
    unitLoadPieces: 'piezas de la unidad de carga',
    usageType: 'tipo de uso',
    user: 'usuario',
    userDetails: 'detalles de usuario',
    userLocations: 'ubicaciones de usuarios',
    username: 'nombre de usuario',
    userRoles: 'roles del usuario',
    users: 'usuarios',
    weight: 'peso',
    width: 'ancho',
    zip: 'código postal',
    shippingMethod: 'método de envío',
    defaultCarrier: 'operador predeterminado',
    minTrailerPercent: '% mín de tráiler',
    maxLTLDayRange: 'Rango máximo de días LTL',
    primaryPlanningItem: 'elemento de planificación principal',
    planningItemOverride: 'anulación de elemento de planificación',
    averageDailyUsage: 'uso diario promedio',
    overrideQuantity: 'anular la cantidad',
    overrideExpirationDate: 'anular la fecha de caducidad',
    expires: 'caduca',
    averageDailyUsageOverride: 'anulación del uso diario promedio',
    errorsOnForm: 'errores en el formulario',
    partnerReferenceNumberSmall: 'de Ref. del Socio',
    equipmentType: 'tipo de equipo',
    equipmentInspectionInterval: 'intervalo de inspección del equipo (horas)',
    sortInventoryCategories: 'ordenar categorías de inventario',
    adjustedForSorts: 'ajustado por tipo',
    systemInventory: 'inventario del sistema',
    dashboardUsername: 'nombre de usuario del tablero',
    additionalEstimatedQuantity: 'cantidad estimada adicional',
    finalizeEstimate: 'finalizar estimación',
    confirmFinalizeMessage: 'Estás seguro de que quieres finalizar la estimación?',
    estimateMustBeFinalized: 'La estimación debe ser finalizada',
    estimateFinalized: 'Estimación finalizada',
    reorderPoint: 'punto de pedido',
    availableRepairs: 'reparaciones disponibles',
    billingPrice: 'precio de facturación',
    laborBillingRate: 'tasa de facturacion laboral',
    repairable: 'reparable',
    repairDescription: 'descripción de la reparación',
    repairName: 'nombre de reparación',
    repairPart: 'pieza de reparación',
    repairPartInventory: 'inventario',
    repairPartConfirmDelete: 'Está seguro de que desea eliminar esta pieza de reparación para todas las ubicaciones?',
    repairPartQuantities: 'cantidades de repuestos',
    repairs: 'refacción',
    repairType: 'tipo de reparación',
    includeZeroQtyRows: 'Incluir filas de cantidad cero',
    inHouseRepairs: 'Reparaciones Internas',
    equipmentNumber: 'número de equipo',
    adjust: 'ajustar',
    countItemsUnderLoad: 'contar artículos con material',
    underLoadPieces: 'piezas bajo carga',
    itemNotRepairable: 'el artículo no es reparable',
    selectRepairToContinue: 'Seleccione una reparación para continuar',
    transactionId: 'transacción id',
    saveShipment: 'Está seguro de que desea guardar este envío?',
    arrivalDate: 'fecha de llegada',
    originalTransactionNumber: 'número de transacción original',
    overridePutAwayLocation: 'anular la ubicación guardada',
    processedPutAway: 'procesado guardado',
    processedPutAwayPullFrom: 'procesado guardar tirar de',
    putAwayLocation: 'guardar ubicación',
    loosePiecesShort: 'piezas sueltas',
    underLoadPiecesShort: 'piezas bajo carga',
    maxLoadPerTimeslot: 'carga máxima por intervalo de tiempo',
    timeslotDuration: 'duración del intervalo de tiempo',
    totalCount: 'cuenta total',
    activeDirectory: 'directorio activo',
    activeDirectoryUser: 'usuario del directorio activo',
    billTo: 'facturar a',
    fromItem: 'desde el artículo',
    toItem: 'al artículo',
    noTaggedItems: 'sin elementos etiquetados',
    scanItemMismatch: 'El artículo escaneado no coincide con el tipo de artículo seleccionado',
    productionPlant: 'planta de producción',
    productionDate: 'fecha de producción',
    forkliftCertified: 'montacargas certificado',
    forkliftCertificationExpiration: 'Fecha de vencimiento de la certificación',
    forkliftCertificationDate: 'Certificación de montacargas',
    forkliftCertificationMessage: 'La fecha de vencimiento de la certificación de montacargas debe ser futura',
    reservationDateTime: 'hora de reserva',
    floorLocation: 'ubicación del piso',
    floorLocations: 'ubicaciones de piso',
    dimensions: 'dimensiones',
    pickable: 'seleccionable',
    editFloorLocation: 'editar ubicación del piso',
    stackHeight: 'altura de la pila',
    fullTrailerLoad: 'carga completa del remolque',
    inventoryCategoriesToCount: 'categorías para contar',
    category: 'categoría',
    receiptDate: 'Fecha de recibo',
    cycleCountManager: 'administrador de conteo ciclico',
    cycleCountReview: 'revisión de conteo ciclico',
    categories: 'categorías',
    createCycleCount: 'crear conteo ciclico',
    schedule: 'horario',
    action: 'acción',
    repeatEvery: 'repetir cada',
    repeat: 'repetir',
    isActive: 'esta activa',
};

export { domainEn, domainEs };
