import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67de6209"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.uid,
    class: "popover-container",
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseenter && _ctx.mouseenter(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mouseleave && _ctx.mouseleave(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.state.showContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['popover-content-container', { ready: _ctx.state.isReady }]),
          style: _normalizeStyle({
                left: _ctx.state.position.x + 'px',
                top: _ctx.state.position.y + 'px',
                transform: `translate(${_ctx.state.position.translateX}px, ${_ctx.state.position.translateY}px)`,
            })
        }, [
          _createElementVNode("div", {
            ref: "popoverContainer",
            class: "popover-content",
            style: _normalizeStyle({ maxHeight: `${_ctx.state.maxHeight}px` }),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.preventParentClick && _ctx.preventParentClick(...args)))
          }, [
            _renderSlot(_ctx.$slots, "content", {}, () => [
              _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
            ], true)
          ], 4)
        ], 6))
      : _createCommentVNode("", true)
  ], 40, _hoisted_1))
}