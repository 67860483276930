import axios from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class ClientIdConfig {
    public static hostname = window.location.href;

    public static getSubdomain() {
        const fullHostName = this.hostname;
        if (fullHostName.includes('localhost')) return 'localhost';
        return fullHostName.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('.')[0];
    }

    private static async getConfig(subdomain: string): Promise<DataAccessResponse<string>> {
        const response = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/system/${subdomain}/config`,
        });

        return new DataAccessResponseClass<string>(response).response;
    }

    public static async getClientIdBySubdomain(): Promise<string> {
        const subdomain = this.getSubdomain();

        if (subdomain === 'localhost') {
            return '0oa218oj2b2ToQONW1d7'; // clientId for dev application(in okta) with localhost redirects
        }
        const config = await this.getConfig(subdomain);
        if (config.data) return config.data;
        return '';
    }

    public static getRedirectAuth(): string {
        const subdomain = this.getSubdomain();
        if (subdomain === 'localhost') {
            return 'http://localhost:8081/login/callback';
        }

        return `https://${subdomain}.smarttrak360.com/login/callback`;
    }
}
