
import { defineComponent, PropType } from 'vue';
import { Variant } from '@/types';
import BButton from '@/components/bootstrap-library/BButton.vue';

type ButtonProps = {
    text: string,
    variant?: Variant,
    onClick: () => void,
    disabled: boolean,
}

export type EmptyStateProps = {
    title: string
    message?: string
    icon?: string;
    button?: ButtonProps;
}

export default defineComponent({
    name: 'empty-state',
    components: { BButton },
    props: {
        title: { type: String, required: true },
        message: { type: String, default: undefined },
        icon: { type: String, default: undefined },
        button: { type: Object as PropType<ButtonProps>, default: undefined },
    },
});
