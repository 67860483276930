import { reactive } from 'vue';
import { store } from '@/decorators/store';
import { CoreStore } from '@/store/CoreStore';
import Location from '@/domain/Location';
import Language from '@/domain/Language';
import ApplicationUser from '@/domain/ApplicationUser';
import { Logos, OrbisLogo } from '@/domain/Logo';
import LocationService from '@/services/LocationService';
import ThemeService from '@/services/ThemeService';
import ThemeCreator from '@/components/bootstrap-library/ThemeCreator';
import { getTranslation } from '@/services/TranslationService';
import SafetyInspection from '@/domain/SafetyInspection';

export type State = {
    firstName: string;
    lastName: string;
    loggedIn: boolean;
    username: string;
    id: number;
    groups: Array<string>;
    locations: Array<Location>;
    userLocation: Location;
    userLanguage?: Language;
    forkliftCertified: boolean;
    certExpirationDate?: Date;
    profileLoaded: boolean;
    companyImg: string;
    tableColumnMap: Map<string, Array<string>>;
    equipmentInspection?: SafetyInspection;
    key: number; // hacky way to force update, vue cannot watch changing maps or deep objects
};

@store
export default class ProfileStore {
    private coreStore: CoreStore;

    private locationService: LocationService;

    private themeService: ThemeService;

    constructor(coreStore: CoreStore) {
        this.coreStore = coreStore;
        this.locationService = new LocationService();
        this.themeService = new ThemeService();
    }

    // state
    private state = reactive<State>({
        firstName: '',
        lastName: '',
        loggedIn: false,
        username: '',
        id: 0,
        groups: [],
        locations: [],
        userLocation: new Location(),
        userLanguage: new Language(),
        forkliftCertified: false,
        certExpirationDate: undefined,
        profileLoaded: false,
        companyImg: '',
        tableColumnMap: new Map(),
        equipmentInspection: undefined,
        key: 0, // hacky way to force update, vue cannot watch changing maps or deep objects
    });

    // getters
    get loggedIn(): boolean {
        return this.state.loggedIn;
    }

    get locations(): Array<Location> {
        return this.state.locations;
    }

    get isUserLocationSet(): boolean {
        return this.state.userLocation.id > 0;
    }

    get userLocation(): Location {
        return this.state.userLocation;
    }

    get userLanguage(): Language | undefined {
        return this.state.userLanguage;
    }

    set userLanguage(language: Language | undefined) {
        this.state.userLanguage = language;
    }

    public async setUserLocation(locationId: number) {
        const location = await this.locationService.getLocationById(locationId);
        if (location) {
            this.state.userLocation = location;
            const response = await this.locationService.getCanShipToLocations(location.id);
            if (response.success) {
                this.state.userLocation.availableShipToLocations = response.locations;
            } else {
                // eslint-disable-next-line no-console
                console.warn(`Could not set ship-to locations for selected user location: ${locationId}`);
            }
        } else {
            // eslint-disable-next-line no-console
            console.warn('UNABLE TO SET USER LOCATION', `Location id of ${locationId} was not found`);
            this.clearUserLocation();
        }
    }

    public async setDefaultUserLocation() {
        if (this.locations.length > 0) {
            const defaultLoc = this.locations[0];
            await this.setUserLocation(defaultLoc.id);
        } else {
            console.warn('UNABLE TO SET DEFAULT USER LOCATION', 'User does not have any locations in their list');
        }
    }

    public clearUserLocation() {
        this.state.userLocation = new Location();
    }

    get companyImg(): string {
        return this.state.companyImg;
    }

    get username(): string {
        return this.state.username;
    }

    get id(): number {
        return this.state.id;
    }

    get firstName(): string {
        return this.state.firstName;
    }

    get lastName(): string {
        return this.state.lastName;
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get isProfileLoaded(): boolean {
        return this.state.profileLoaded;
    }

    get equipmentInspection(): SafetyInspection | undefined {
        return this.state.equipmentInspection;
    }

    set equipmentInspection(inspection: SafetyInspection | undefined) {
        this.state.equipmentInspection = inspection;
    }

    get forkliftCertified(): boolean {
        return this.state.forkliftCertified;
    }

    set forkliftCertified(certRequired: boolean) {
        this.state.forkliftCertified = certRequired;
    }

    get certExpirationDate(): Date | undefined {
        return this.state.certExpirationDate;
    }

    set certExpirationDate(expirationDate: Date | undefined) {
        this.state.certExpirationDate = expirationDate;
    }


    // actions
    public async initProfile(profile: ApplicationUser) {
        this.state.loggedIn = true;
        this.state.firstName = profile.firstName || '';
        this.state.lastName = profile.lastName || '';
        this.state.groups = [];
        this.state.locations = profile.locations;
        this.state.username = profile.username;
        this.state.id = profile.id;
        this.state.userLanguage = profile.language;
        this.state.forkliftCertified = profile.forkliftCertified;
        this.state.certExpirationDate = profile.certExpirationDate;
        if (profile.locations.length === 1) {
            this.setDefaultUserLocation();
        }
        this.coreStore.permissionsStore.setUserRoles(profile.userRoles);
        this.state.profileLoaded = true;
        await this.initTheme();
    }

    public clearProfile() {
        this.state.loggedIn = false;
        this.state.groups = [];
        this.state.locations = [];
        this.state.username = getTranslation('core.common.usernameNotSet');
        this.coreStore.permissionsStore.setUserRoles([]);
    }

    public updateTableColumnKeys(tableKey: string, columnKeys: Array<string>) {
        this.state.tableColumnMap.set(tableKey, columnKeys);
        this.update();
    }

    public getTableColumnsByKey(key: string): Array<string> | null {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.state.key; // hacky way to force update, vue cannot watch changing maps or deep objects
        const columnKeys = this.state.tableColumnMap.get(key);
        if (columnKeys) return columnKeys;
        return null;
    }

    public async initTheme() {
        const { logoUrl, ...theme } = await this.themeService.getTheme();
        if (this.setCompanyLogo(logoUrl)) {
            const themeCreator = await new ThemeCreator(theme);
            themeCreator.init();
        }
    }

    private setCompanyLogo(filename: string): boolean {
        const matchingLogo = Logos.find((logo) => logo.filename === filename);
        this.state.companyImg = matchingLogo?.path || OrbisLogo.path;
        return Boolean(matchingLogo);
    }

    private update() {
        this.state.key++;
    }
}
