import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e8c9206"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "error"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "datepicker-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, {
    class: "date-input",
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs
  }, {
    default: _withCtx(() => [
      (_ctx.label || _ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.error), 1))
              : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_datepicker, {
          modelValue: _ctx.internalDate,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalDate) = $event)),
            _ctx.handleChange
          ],
          class: "form-control datepicker",
          "input-format": _ctx.inputFormat,
          "lower-limit": _ctx.lowerLimit,
          "upper-limit": _ctx.upperLimit,
          disabled: _ctx.disabled,
          "disabled-dates": { dates: _ctx.disabledDates },
          "week-starts-on": 0,
          "starting-view": _ctx.startingView,
          "minimum-view": _ctx.minimumView
        }, null, 8, ["modelValue", "input-format", "lower-limit", "upper-limit", "disabled", "disabled-dates", "starting-view", "minimum-view", "onUpdate:modelValue"]),
        (_ctx.clearable)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "clear-btn",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearDate && _ctx.clearDate(...args)))
            }, [
              _createVNode(_component_faicon, {
                style: {"margin":"auto"},
                icon: "times"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs"]))
}