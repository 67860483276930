import ApiErrorWrapper from '../interceptors/ApiErrorWrapper';
import ApiErrorResponseStrategy from './ApiErrorResponseStrategy';
import BadRequestApiResponseStrategy from './BadRequestApiResponseStrategy';
import ConflictResponseStrategy from './ConflictResponseStrategy';
import DefaultApiErrorResponseStrategy from './DefaultApiErrorResponseStrategy';
import InternalServerErrorResponseStrategy from './InternalServerErrorResponseStrategy';
import UnauthorizedApiResponseStrategy from './UnauthorizedApiResponseStrategy';
import UnprocessableEntityResponseStrategy from './UnprocessableEntityResponseStrategy';

export default class ApiErrorResponseHandlerFactory {
    private apiError: ApiErrorWrapper;

    constructor(apiError: ApiErrorWrapper) {
        this.apiError = apiError;
    }

    public GetErrorResponder(): ApiErrorResponseStrategy {
        const status = this.apiError.statusCode;

        if (status) {
            switch (status) {
            case 401:
                return new UnauthorizedApiResponseStrategy(this.apiError);
            case 400:
                return new BadRequestApiResponseStrategy(this.apiError);
            case 409:
                return new ConflictResponseStrategy(this.apiError);
            case 500:
                return new InternalServerErrorResponseStrategy(this.apiError);
            case 422:
                return new UnprocessableEntityResponseStrategy(this.apiError);
            default:
                return new DefaultApiErrorResponseStrategy(this.apiError);
            }
        } else {
            return new DefaultApiErrorResponseStrategy(this.apiError);
        }
    }
}
