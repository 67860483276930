
import { computed, defineComponent } from 'vue';

export default defineComponent({
    name: 'b-dropdown-item',
    props: {
        disabled: {
            type: Boolean,
            default: () => false,
        },
        disabledMessage: String,
    },
    emits: ['click'],
    setup(props, context) {
        const classArray = computed(
            (): Array<string> => {
                const arr = ['dropdown-item'];
                if (props.disabled) arr.push('btn-disabled');
                return arr;
            },
        );

        function handleClick() {
            if (!props.disabled) context.emit('click');
        }

        return {
            handleClick,
            classArray,
        };
    },
});
