import axios, { AxiosResponse } from 'axios';
import FreightCode from '@/domain/FreightCode';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class FreightCodeApiService {
    public async getFreightCodes(): Promise<DataAccessResponse<Array<FreightCode>>> {
        const response: AxiosResponse<DataAccessResponse<Array<FreightCode>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/FreightCodes`,
        });
        return new DataListAccessResponseClass<FreightCode>(response, FreightCode).response;
    }

    public async addNewFreightCode(freightCode: FreightCode): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/FreightCodes`,
            data: freightCode,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateFreightCode(freightCode: FreightCode): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/FreightCodes`,
            data: freightCode,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async deleteFreightCode(freightCode: FreightCode): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/FreightCodes`,
            data: freightCode,
        });
        return new DataAccessResponseClass<number>(response).response;
    }
}
