import SortStationActivity from '@/domain/SortStationActivity';

export default class SortStation {
    public id: number;
    public name: string;
    public isTeamSort: boolean;
    public locationId: number;
    public sortStationActivity?: Array<SortStationActivity>;

    constructor(init: SortStation) {
        this.id = init.id;
        this.name = init.name;
        this.isTeamSort = init.isTeamSort;
        this.locationId = init.locationId;
        this.sortStationActivity = init.sortStationActivity?.map((x) => new SortStationActivity(x));
    }
}
