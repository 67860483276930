export default class NonWorkDates {
    constructor(init?: Partial<NonWorkDates>) {
        Object.assign(this, init);

        if (init?.nonWorkDate) {
            this.nonWorkDate = new Date(init?.nonWorkDate);
        }
    }

    public id!: number;

    public nonWorkDate!: Date;

    public nonWorkingTimeSetId!: number;
}
