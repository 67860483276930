export default class EquipmentType {
    constructor(init?: Partial<EquipmentType>) {
        Object.assign(this, init);
    }

    public id!: number;

    public description!: string;

    public deleted!: boolean;
}
