import { useNotification } from '@/composable/useNotifications';
import ApiErrorWrapper from '../interceptors/ApiErrorWrapper';

abstract class ApiErrorResponseStrategy {
    protected error: ApiErrorWrapper;

    protected notification = useNotification;

    constructor(apiError: ApiErrorWrapper) {
        this.error = apiError;
    }

    abstract handleError(): void;
}

export default ApiErrorResponseStrategy;
