import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-390b1547"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 2,
  class: "loading-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_header = _resolveComponent("sub-header")!
  const _component_b_spinner = _resolveComponent("b-spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'full-screen': _ctx.full}),
    style: _normalizeStyle({height: `${_ctx.screenHeight - _ctx.heightOffset}px`})
  }, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_sub_header, {
          key: 0,
          title: _ctx.title,
          "go-back": _ctx.goBack
        }, null, 8, ["title", "go-back"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['screen-content', {'padded': _ctx.padded}])
    }, [
      (_ctx.heading)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.heading), 1))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_b_spinner),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('core.common.loading')), 1)
            ])
          ]))
    ], 2),
    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
  ], 6))
}