import { ref, Ref } from 'vue';
import { store } from '@/decorators/store';
import { CoreStore } from '../CoreStore';

interface PushNotificationSubscriptionTreeNode {
    children: PushNotificationSubscriptionTreeNode[];
    count: Ref<number>;
    key: string;
}

@store
export default class PushNotificationStore {
    private rootStore: CoreStore;

    private subscriptionTree: PushNotificationSubscriptionTreeNode;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
        this.subscriptionTree = {
            children: [],
            count: ref(0),
            key: 'root',
        };
    }

    private hydrateTreeWithNewValue(node: PushNotificationSubscriptionTreeNode, treePath: string[], newValue: number) : Ref<number> {
        let newValueRef: Ref<number>;

        if (treePath.length > 0) {
            const currentNodeName = treePath[0];
            const remainingNodeNames = treePath.slice(1);
            let currentNode = node.children.find((childNode) => childNode.key === currentNodeName);

            if (!currentNode) {
                currentNode = {
                    children: [],
                    count: ref(0),
                    key: currentNodeName,
                };

                node.children.push(currentNode);
            }

            newValueRef = this.hydrateTreeWithNewValue(currentNode, remainingNodeNames, newValue);

            if (currentNode.children.length > 0) {
                currentNode.count.value = currentNode.children.reduce((previousValue, currNode) => previousValue + currNode.count.value, 0);
            }
        } else {
            const currentNodeCountRef = node.count;

            currentNodeCountRef.value = newValue;
            newValueRef = currentNodeCountRef;
        }

        return newValueRef;
    }

    public publish(key: string, newValue: number) : Ref<number> {
        const treePath = key.split(':');

        return this.hydrateTreeWithNewValue(this.subscriptionTree, treePath, newValue);
    }
}
