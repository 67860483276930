

import { defineComponent } from 'vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';

export default defineComponent({
    name: 'checkbox-input',
    components: { BCol, BFormCheckbox },
    props: {
        modelValue: {
            type: [Boolean],
        },
        cols: {
            type: String,
            default: () => '',
        },
        lg: {
            type: String,
            default: () => '',
        },
        md: {
            type: String,
            default: () => '',
        },
        sm: {
            type: String,
            default: () => '',
        },
        xs: {
            type: String,
            default: () => '',
        },
        label: {
            type: String,
            default: () => '',
        },
        disabled: Boolean,
        moreInfo: {
            type: String,
            default: () => '',
        },
        inlineLeft: Boolean,
        inlineRight: Boolean,
        error: {
            type: String,
            default: () => '',
        },
    },
    emits: ['update:modelValue', 'change', 'input'],
    setup(props, context) {
        function handleChange(e: any) {
            context.emit('update:modelValue', e);
            context.emit('input', e); // updates v-model
            context.emit('change', e); // triggers optional function in parent
        }

        return {
            handleChange,
        };
    },
});

