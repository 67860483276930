
import {defineComponent, reactive, ref, onMounted, onBeforeUnmount} from 'vue';
import {useNotification} from "@/composable/useNotifications";

type State = {
    capturedImageData: string;
    cameraError: boolean;
    width: number;    // We will scale the photo width to this
    height: number;     // This will be computed based on the input stream
    streaming: boolean;
}

export default defineComponent({
    name: 'camera',
    components: {},
    props: {},
    emits: ['close', 'takePicture'],
    setup(props, context) {
        let videoStream!: MediaStream;

        const { show } = useNotification()

        let video = ref<HTMLVideoElement | null>(null); // : Ref<HTMLVideoElement>; // = ref<HTMLVideoElement>(new HTMLVideoElement());
        let cameraCanvas = ref<HTMLCanvasElement | null>(null); // : Ref<HTMLCanvasElement>; // = ref<HTMLCanvasElement>(new HTMLCanvasElement());
        const showPictureResultModal = ref(false);
        const showTakePictureModal = ref(true);
        const state = reactive<State>({
            capturedImageData: '',
            cameraError: false,
            width: 320,    // We will scale the photo width to this
            height: 0,    // This will be computed based on the input stream
            streaming: false,
        });

        onMounted(() => {
            turnOnCamera();
        });

        onBeforeUnmount(() => {
            turnOffCamera();
        });

        function emitClose() {
            context.emit('close');
        }

        function emitPicture() {
            takePicture();
            context.emit('takePicture', state.capturedImageData); // TODO: take picture function should return  data
        }

        function turnOnCamera() {
            state.cameraError = false;
            navigator.mediaDevices.getUserMedia({video: {facingMode: {ideal: 'environment'}}, audio: false})
                .then((stream) => {
                    if (video.value) {
                        video.value.srcObject = stream;
                        videoStream = stream;
                        video.value.play();
                    } else {
                        throw new Error('Error getting video stream');
                    }
                })
                .catch((err) => {
                    console.log("Error using the camera: " + err);
                    state.cameraError = true;
                    show({
                        message: 'ERROR: No Camera Found',
                        type: 'danger'
                    });
                });
        }

        function turnOffCamera() {
            if (videoStream) {
                videoStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            
            state.streaming = false;
        }

        function clearphoto() {
            if (cameraCanvas && cameraCanvas.value) {
                const context = <CanvasRenderingContext2D>cameraCanvas.value.getContext('2d');
                context.fillStyle = '#AAA';
                context.fillRect(0, 0, cameraCanvas.value.width, cameraCanvas.value.height);
                const data = cameraCanvas.value.toDataURL('image/png');
                state.capturedImageData = data;
            }
        }

        function beginPlaying() {
            if (!state.streaming && video && video.value) {
                state.height = video.value.videoHeight / (video.value.videoWidth / state.width);
                state.streaming = true;
            }
        }

        function takePicture() {
            if (cameraCanvas.value && video.value) {
                const context = <CanvasRenderingContext2D>cameraCanvas.value.getContext('2d');
                if (state.width && state.height) {
                    cameraCanvas.value.width = state.width;
                    cameraCanvas.value.height = state.height;
                    context.drawImage(video.value, 0, 0, state.width, state.height);
                    const data = cameraCanvas.value.toDataURL('image/png');
                    state.capturedImageData = data;
                } else {
                    clearphoto();
                }
            }            
        }

        return {
            video,
            cameraCanvas,
            state,
            turnOnCamera,
            turnOffCamera,
            clearphoto,
            beginPlaying,
            takePicture,
            showPictureResultModal,
            showTakePictureModal,
            emitClose,
            emitPicture
        }
    }
});
