
import { computed, defineComponent, ref } from 'vue';
import Datepicker from 'vue3-datepicker';

export default defineComponent({
    name: 'b-form-datepicker',
    components: { Datepicker },
    props: {
        cols: String,
        lg: String,
        md: String,
        sm: String,
        xs: String,
        label: String,
        disabled: Boolean,
        moreInfo: String,
        upperLimit: Date,
        lowerLimit: Date,
        readonly: { type: Boolean, default: () => false },
        inputFormat: { type: String, default: 'MM-dd-yyyy' },
    },
    emits: ['update:modelValue', 'input'],
    setup(props, context) {
        const picked = ref(new Date());

        function onInput(evt: any) {
            const { value } = evt.target;
            context.emit('update:modelValue', value);
            context.emit('input', value);
        }

        const isDisabled = computed(() => props.readonly || props.disabled);

        return {
            picked,
            onInput,
            isDisabled,
        };
    },
});
