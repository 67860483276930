import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_select_option = _resolveComponent("b-form-select-option")!

  return (_openBlock(), _createElementBlock("optgroup", { label: _ctx.label }, [
    _renderSlot(_ctx.$slots, "first"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formOptions, (option, index) => {
      return (_openBlock(), _createBlock(_component_b_form_select_option, _mergeProps({
        key: `option_${index}`,
        value: option.value,
        disabled: option.disabled
      }, _ctx.$attrs, {
        textContent: _toDisplayString(option.text),
        innerHTML: option.html
      }), null, 16, ["value", "disabled", "textContent", "innerHTML"]))
    }), 128)),
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}