import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5993a740"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "btn-popover"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_popover = _resolveDirective("popover")!

  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("a", {
      class: _normalizeClass(_ctx.classArray),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.disabled && _ctx.disabledMessage)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
            [
              _directive_popover,
              _ctx.disabledMessage,
              void 0,
              { hover: true }
            ]
          ])
        : _createCommentVNode("", true)
    ], 2)
  ]))
}