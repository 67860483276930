import { reactive } from 'vue';
import { OktaAuth, AuthState } from '@okta/okta-auth-js';
import ApiConfig from '@/config/api/api-config';
import UserService from '@/services/UserService';
import { useLoading } from '@/composable/useLoading';

type State = {
    ready: boolean;
    username: string | undefined;
    isAuthenticated: boolean;
    userFullName: string | undefined;
    clientId: string | undefined;
    samAccountName: string | undefined;
};

export class AuthStore {
    private userService = new UserService();

    private static instance: AuthStore;

    private oktaAuth: OktaAuth | null = null;

    private authState = reactive<State>({
        username: '',
        isAuthenticated: false,
        ready: false,
        userFullName: '',
        clientId: undefined,
        samAccountName: undefined,
    });

    public static getInstance(): AuthStore {
        if (!AuthStore.instance) {
            AuthStore.instance = new AuthStore();
        }
        return AuthStore.instance;
    }

    public async onAuthChange(val: AuthState) {
        this.authState.ready = false;
        if (val.isAuthenticated) {
            this.authState.samAccountName = val.accessToken?.claims.samAccountName;
            if (this.authState.isAuthenticated) {
                // user was already authenticated. got here when okta refreshed the token. save the new token for requests to our backend
                ApiConfig.setToken(val.accessToken?.accessToken || '');
            } else {
                this.authState.isAuthenticated = true;
            }
        }

        this.authState.ready = true;
    }

    public async initUserProfile() {
        const loading = useLoading();
        loading.show();

        return this.oktaAuth
            ?.getUser()
            .then(async (claims) => {
                ApiConfig.setToken(this.oktaAuth?.getAccessToken() || '');
                this.authState.username = claims.preferred_username;
                this.authState.userFullName = claims.name;

                if (this.authState.username) {
                    await this.userService.getUserProfileByUsername(this.authState.username);
                }
            })
            .catch(this.signOut)
            .finally(loading.hide);
    }

    public async initAuth(auth: OktaAuth) {
        this.oktaAuth = auth;
        this.authState.clientId = auth.options.clientId;
        auth.authStateManager.subscribe(this.onAuthChange.bind(this));
    }

    public signOut() {
        this.oktaAuth?.signOut();
    }

    get ready(): boolean {
        return this.authState.ready;
    }

    get username(): string | undefined {
        return this.authState.username;
    }

    get userFullName() {
        return this.authState.userFullName;
    }

    get isAuthenticated(): boolean {
        return this.authState.isAuthenticated;
    }

    get samAccountName(): string | undefined {
        return this.authState.samAccountName;
    }

    get clientId(): string {
        if (this.authState.clientId === undefined) {
            throw new Error('ClientId not found');
        }
        return this.authState.clientId;
    }
}

const authStore = AuthStore;
export default authStore;
