import { OktaAuthOptions } from '@okta/okta-auth-js';
import { ISSUER } from '@/config/env';
import ClientIdConfig from '@/config/okta/ClientIdConfig';

const redirectUri = ClientIdConfig.getRedirectAuth();
const scopes = ['openid', 'profile', 'email', 'privacy_policy_terms', 'offline_access'];

const oidcConfig = async (): Promise<OktaAuthOptions> => ({
    issuer: ISSUER,
    redirectUri,
    clientId: await ClientIdConfig.getClientIdBySubdomain(),
    scopes,
});

export default oidcConfig;
