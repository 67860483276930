import { useNotification } from '@/composable/useNotifications';
import UserApiService from '@/services/api/UserApiService';
import ApplicationUser from '@/domain/ApplicationUser';
import { CoreStore } from '@/store/CoreStore';
import ProfileStore from '@/store/modules/ProfileStore';
import LanguageService from '@/services/LanguageService';
import { getTranslation } from './TranslationService';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class UserService {
    private userApiService: UserApiService;
    private notification = useNotification();
    private profileStore: ProfileStore;

    constructor() {
        this.userApiService = new UserApiService();
        this.profileStore = CoreStore.getInstance().profileStore;
    }

    public async getUserProfileByUsername(username: string): Promise<boolean> {
        const { success, data } = await this.userApiService.getUserProfileByUsername(username);
        if (success) {
            await this.profileStore.initProfile(data);
            LanguageService.initDefaultLanguage();
        }
        return success;
    }

    public async getAllUsers(): Promise<{ users: Array<ApplicationUser>; success: boolean }> {
        const response = await this.userApiService.getAllUsers();
        if (!response) {
            return {
                users: [],
                success: false,
            };
        }
        return {
            users: response,
            success: true,
        };
    }

    private async createNewUser(createUserViaApiCallback: () => Promise<DataAccessResponse<ApplicationUser>>) : Promise<DataAccessResponse<ApplicationUser>> {
        const response = await createUserViaApiCallback();
        if (response?.success) {
            this.notification.showSuccess(getTranslation('core.common.addedNewUser'));
        }
        return response;
    }

    public async createNewSSOUser(user: ApplicationUser): Promise<DataAccessResponse<ApplicationUser>> {
        return this.createNewUser(async () => this.userApiService.createNewSSOUser(user));
    }

    public async createNewActiveDirectoryUser(user: ApplicationUser): Promise<DataAccessResponse<ApplicationUser>> {
        return this.createNewUser(async () => this.userApiService.createNewActiveDirectoryUser(user));
    }

    public async createNewLocalUser(user: ApplicationUser): Promise<DataAccessResponse<ApplicationUser>> {
        return this.createNewUser(async () => this.userApiService.createNewLocalUser(user));
    }

    public async linkAppUserToAuthServer(user: ApplicationUser): Promise<boolean> {
        const response = await this.userApiService.linkAppUserToAuthServer(user);

        if (!response || !response.success) {
            return false;
        }

        return true;
    }

    public async updateUser(user: ApplicationUser): Promise<DataAccessResponse<boolean>> {
        const response = await this.userApiService.updateUser(user);

        if (response && response.success) {
            this.notification.showSuccess(getTranslation('core.common.updatedUserSuccessfully'));
        }

        return response;
    }

    public async deleteUser(userId: number): Promise<DataAccessResponse<boolean>> {
        const response = await this.userApiService.deleteUser(userId);

        if (response && response.success) {
            this.notification.showSuccess(getTranslation('core.common.deletedUserSuccessfully'));
        }

        return response;
    }

    public async getTableauToken(): Promise<DataAccessResponse<string>> {
        const response = await this.userApiService.getTableauToken();
        return response;
    }

    public async resetPassword(userName: string): Promise<DataAccessResponse<string>> {
        const response = await this.userApiService.resetPassword(userName);

        if (response?.success) {
            this.notification.showSuccess(getTranslation('core.common.passwordResetSuccessful'));
        }

        return response;
    }
}
