import { reactive } from 'vue';
import { store } from '@/decorators/store';
import Language from '@/domain/Language';
import { CoreStore } from '../CoreStore';

type State = {
    languages: Array<Language>;
    loaded: boolean;
};

@store
export default class LanguageStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        languages: [],
        loaded: false,
    });

    public initLanguages(value: Array<Language>) {
        this.state.languages = value;
        this.state.loaded = true;
    }

    get loaded(): boolean {
        return this.state.loaded;
    }

    get languages(): Array<Language> {
        return this.state.languages;
    }
}
