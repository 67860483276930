
import { computed, defineComponent, PropType } from 'vue';
import { Variant } from '@/types';

export default defineComponent({
    name: 'b-spinner',
    props: {
        label: { type: String },
        role: { type: String, default: 'status' },
        small: { type: Boolean, default: false },
        tag: { type: String, default: 'span' },
        type: { type: String as PropType< 'border' | 'grow'>, default: 'border' },
        variant: { type: String as PropType<Variant> },
    },
    setup(props) {
        const classes = computed(() => ({
            'spinner-border': props.type === 'border',
            'spinner-border-sm': props.type === 'border' && props.small,
            'spinner-grow': props.type === 'grow',
            'spinner-grow-sm': props.type === 'grow' && props.small,
            [`text-${props.variant}`]: !!props.variant,
        }));
        return {
            classes,
        };
    },
});
