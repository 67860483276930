import { APPLICATION_DISPLAY_NAME } from '../constants/env';
import { MODULES } from '../constants/module';
import { getTitleCaseTranslation } from '../services/TranslationService';

export const normalizeTitle = (title: string) => getTitleCaseTranslation(replaceModuleNameInTitle(title, MODULES,'').replaceAll('-',' ')) ?? APPLICATION_DISPLAY_NAME;


function replaceModuleNameInTitle(title: string, modules: string[], replacement: string): string {
    const regex = new RegExp(modules.join('|'), 'g');
    return title?.replace(regex, replacement);
}
