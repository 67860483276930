import ProfileStore from '@/store/modules/ProfileStore';
import PermissionsStore from '@/store/modules/PermissionsStore';
import CarrierStore from '@/store/modules/CarrierStore';
import ConfigStore from '@/store/modules/ConfigStore';
import TimeSetStore from '@/store/modules/NonWorkingTimeSetStore';
import InventoryCategoryStore from '@/store/modules/InventoryCategoryStore';
import MetaDataStore from '@/store/modules/MetaDataStore';
import PushNotificationStore from '@/store/modules/PushNotificationStore';
import LanguageStore from '@/store/modules/LanguageStore';
import CarrierService from '@/modules/master-data/services/CarrierService';
import InventoryCategoryService from '@/services/InventoryCategoryService';
import TrailerTypeApiService from '@/services/api/TrailerTypeApiService';
import { useLoading } from '@/composable/useLoading';
import NonWorkingDaysTimeSetService from '@/services/NonWorkingDaysTimeSetService';
import SystemApiService from '@/services/api/SystemApiService';
import LanguageApiService from '@/services/api/LanguageApiService';
import ShippingLoadTypeApiService from '@/services/api/ShippingLoadTypeApiService';
import ShippingLoadTypeStore from '@/store/modules/ShippingLoadTypeStore';
import AdminSettingsStore from './modules/AdminSettingsStore';
import PEMSettingsStore from '@/store/modules/PEMSettingsStore';

export class CoreStore {
    profileStore = new ProfileStore(this);
    permissionsStore = new PermissionsStore(this);
    carrierStore = new CarrierStore(this);
    configStore = new ConfigStore(this);
    languageStore = new LanguageStore(this);
    timeSetStore = new TimeSetStore(this);
    inventoryCategoryStore = new InventoryCategoryStore(this);
    metaDataStore = new MetaDataStore(this);
    pushNotificationStore = new PushNotificationStore(this);
    shippingLoadTypeStore = new ShippingLoadTypeStore(this);
    adminSettingsStore = new AdminSettingsStore(this);
    pemSettingsStore = new PEMSettingsStore(this);

    private static instance: CoreStore;

    private loading = useLoading();
    private carrierService: CarrierService;
    private inventoryCategoryService: InventoryCategoryService;
    private trailerTypeApiService: TrailerTypeApiService;
    private timeSetService: NonWorkingDaysTimeSetService;
    private systemApiService: SystemApiService;
    private languageApiService: LanguageApiService;
    private shippingLoadTypeApiService: ShippingLoadTypeApiService;

    public static getInstance(): CoreStore {
        if (!CoreStore.instance) {
            CoreStore.instance = new CoreStore();
        }

        return CoreStore.instance;
    }

    public static isInitialized() {
        return Boolean(CoreStore.instance);
    }

    constructor() {
        this.trailerTypeApiService = new TrailerTypeApiService();
        this.carrierService = new CarrierService();
        this.inventoryCategoryService = new InventoryCategoryService();
        this.timeSetService = new NonWorkingDaysTimeSetService();
        this.systemApiService = new SystemApiService();
        this.languageApiService = new LanguageApiService();
        this.shippingLoadTypeApiService = new ShippingLoadTypeApiService();
    }

    public async start() {
        this.loading.show();

        try {
            const [
                trailerTypeResult,
                carriersResult,
                inventoryCategoryResult,
                timeSetResult,
                metaData,
                inventorySettingsResult,
                languageResult,
                loadTypeResult,
                adminSettingsResult,
                pemSettingsResult,
            ] = await Promise.all([
                this.trailerTypeApiService.getTrailerTypes(),
                this.carrierService.getAllCarriers(),
                this.inventoryCategoryService.getCategoryDetails(),
                this.timeSetService.getAllNonWorkingTimeSets(),
                this.systemApiService.getMetaData(),
                this.systemApiService.getInventorySettings(),
                this.languageApiService.getAllLanguages(),
                this.shippingLoadTypeApiService.getAllShippingLoadTypes(),
                this.systemApiService.getAdminSettings(),
                this.systemApiService.getPEMSettings(),
            ]);

            if (trailerTypeResult.success) {
                this.configStore.initTrailerTypes(trailerTypeResult.data);
            }

            if (carriersResult.success) {
                this.carrierStore.initCarriers(carriersResult.carriers);
            }

            if (inventoryCategoryResult.success) {
                this.inventoryCategoryStore.initCategories(inventoryCategoryResult.categories);
            }

            if (timeSetResult.success) {
                this.timeSetStore.initTimeSets(timeSetResult.nonWorkingTimeSets);
            }

            if (metaData.success) {
                this.metaDataStore.init(metaData.data);
            }

            if (inventorySettingsResult.success) {
                this.metaDataStore.initCanPrintUnitLoadLabels(inventorySettingsResult.data);
            }

            if (languageResult.success) {
                this.languageStore.initLanguages(languageResult.data);
            }

            if (loadTypeResult.success) {
                this.shippingLoadTypeStore.initShippingLoadTypes(loadTypeResult.data);
            }

            if (adminSettingsResult.success) {
                this.adminSettingsStore.init(adminSettingsResult.data);
            }

            if (pemSettingsResult.success) {
                this.pemSettingsStore.init(pemSettingsResult.data);
            }
        } finally {
            this.loading.hide();
        }
    }
}

const coreStore = CoreStore;
export default coreStore;
