import { reactive } from 'vue';
import { store } from '@/decorators/store';
import NonWorkingTimeSet from '@/domain/Dates/NonWorkingTimeSet';
import { CoreStore } from '../CoreStore';

type State = {
    timeSets: Array<NonWorkingTimeSet>,
    loaded: boolean
};

@store
export default class NonWorkingTimeSetStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        timeSets: [],
        loaded: false,
    });

    public initTimeSets(value: Array<NonWorkingTimeSet>) {
        this.state.timeSets = value;
        this.state.loaded = true;
    }

    get allTimeSets(): Array<NonWorkingTimeSet> {
        return this.state.timeSets;
    }

    get defaultTimeSet(): NonWorkingTimeSet | undefined {
        return this.state.timeSets.find((x) => x.isDefaultTimeSet);
    }

    get loaded(): boolean {
        return this.state.loaded;
    }
}
