import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import NonWorkingTimeSet from '@/domain/Dates/NonWorkingTimeSet';

export default class NonWorkingDaysTimeSetApiService {
    public async getAllNonWorkingTimeSets(): Promise<DataAccessResponse<Array<NonWorkingTimeSet>>> {
        const response: AxiosResponse<DataAccessResponse<Array<NonWorkingTimeSet>>> = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/NonWorkingTimeSet/`,
        });

        return new DataListAccessResponseClass<NonWorkingTimeSet>(response, NonWorkingTimeSet).response;
    }
}
