import { reactive } from 'vue';
import { store } from '@/decorators/store';
import { CoreStore } from '../CoreStore';
import MetaDataDTO from '@/dtos/MetaDataDTO';
import WikiRouteDTO from '@/dtos/WikiRouteDTO';
import InventorySettingsDTO from '@/dtos/InventorySettingsDTO';

type State = {
    canPrintUnitLoadLabels: boolean;
    companyDisplayName: string;
    rfidPrefix: string;
    wikiRoutes: WikiRouteDTO[];
};

@store
export default class MetaDataStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        canPrintUnitLoadLabels: false,
        companyDisplayName: '',
        rfidPrefix: '',
        wikiRoutes: [],
    });

    public initCanPrintUnitLoadLabels(data: InventorySettingsDTO) {
        this.state.canPrintUnitLoadLabels = data.printUnitLoadLabels;
    }

    public init(data: MetaDataDTO) {
        this.state.companyDisplayName = data.companyDisplayName;
        this.state.rfidPrefix = data.rfidPrefix;
        this.state.wikiRoutes = data.wikiRoutes;
    }

    get canPrintUnitLoadLabels(): boolean {
        return this.state.canPrintUnitLoadLabels;
    }

    get companyDisplayName(): string {
        return this.state.companyDisplayName;
    }

    get rfidPrefix(): string {
        return this.state.rfidPrefix;
    }

    public findWikiRoute(clientPath: string): WikiRouteDTO | undefined {
        return this.state.wikiRoutes.find((x) => x.clientPath === clientPath);
    }
}
