import coreStore from '@/store/CoreStore';
import ADMINISTRATION_PERMISSIONS, { AdministrationPermission } from './modules/Administration';
import FLOORTRAK_PERMISSION, { FloortrakPermission } from '@/services/permissions/modules/Floortrak';
import PARTNER_ENGAGEMENT_PERMISSIONS, { PartnerEngagementPermission } from '@/services/permissions/modules/PartnerEngagement';

export type PermissionString = '' | AdministrationPermission | FloortrakPermission | PartnerEngagementPermission;

export function hasPermission(permission: PermissionString): boolean {
    const data = coreStore.getInstance().permissionsStore.permissionStringList.find((p) => p === permission);
    return !!data;
}

const Permissions = {
    ADMINISTRATION: ADMINISTRATION_PERMISSIONS,
    FLOORTRAK: FLOORTRAK_PERMISSION,
    PARTNER_ENGAGEMENT: PARTNER_ENGAGEMENT_PERMISSIONS,
};

export default Permissions;
