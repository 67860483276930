import { reactive } from 'vue';
import { store } from '@/decorators/store';
import { CoreStore } from '../CoreStore';
import InventoryCategory, { StaticInventoryCategory, InventoryCategorySelectItem } from '@/domain/InventoryCategory';

type State = {
    categories: Array<InventoryCategory>;
    loaded: boolean;
};

@store
export default class InventoryCategoryStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        categories: [],
        loaded: false,
    });

    public initCategories(value: Array<InventoryCategory>) {
        this.state.categories = value;
        this.state.loaded = true;
    }

    get loaded(): boolean {
        return this.state.loaded;
    }

    get categories(): Array<InventoryCategory> {
        return this.state.categories;
    }

    get canMoveInventoryCategories(): Array<InventoryCategory> {
        return this.state.categories.filter((invCategory) => invCategory.canMove);
    }

    public getFinishedGoodsCategory() {
        return this.state.categories.find((x) => x.staticTypeIdentifier === StaticInventoryCategory.FinishedGoods);
    }

    public getById(id: number) {
        return this.state.categories.find((x) => x.id === id);
    }

    public getInboundGoodsCategory() {
        return this.state.categories.find((x) => x.staticTypeIdentifier === StaticInventoryCategory.InboundGoods);
    }

    public getLostAndFoundCategory() {
        return this.state.categories.find((x) => x.staticTypeIdentifier === StaticInventoryCategory.LostAndFound);
    }

    public getScrapCategory() {
        return this.state.categories.find((x) => x.staticTypeIdentifier === StaticInventoryCategory.Scrap);
    }

    public getWashCategory() {
        return this.state.categories.find((x) => x.staticTypeIdentifier === StaticInventoryCategory.Wash);
    }

    public getGlassCategory() {
        return this.state.categories.find((x) => x.staticTypeIdentifier === StaticInventoryCategory.Glass);
    }

    public getRepairCategory() {
        return this.state.categories.find((x) => x.staticTypeIdentifier === StaticInventoryCategory.Repair);
    }

    public getMovableListItems() {
        return this.state.categories.filter((x) => x.canMove).map((x) => ({ value: x.id, text: x.description } as InventoryCategorySelectItem));
    }
}
