
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'sub-header',
    props: {
        title: { type: String, default: undefined },
        goBack: { type: Function as PropType<() => void>, default: undefined },
        boldTitle: { type: Boolean, default: false },
    },
    setup() {
        return {};
    },
});
