import RepairType from '@/domain/enums/RepairType';
import RepairPartQuantity from '@/domain/Repair/RepairPartQuantity';

export default class Repair {
    public id: number = 0;

    public deleted: boolean = false;

    public description: string = '';

    public name: string = '';

    public laborBillingRate: number = 0;

    public type: RepairType = RepairType.InLine;

    public repairPartQuantities: RepairPartQuantity[] = [];

    public constructor(init?: Partial<Repair>) {
        Object.assign(this, init);
        this.repairPartQuantities = init?.repairPartQuantities?.map((repairPartQty) => new RepairPartQuantity(repairPartQty)) ?? [];
    }
}
