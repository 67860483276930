import { hasPermission } from '@/services/permissions/Permissions';

export type AdministrationPermission =
    | 'Items'
    | 'Locations'
    | 'Carriers'
    | 'Production Parts'
    | 'Users'
    | 'Roles'
    | 'Config'
    | 'Transactions'
    | 'Planning'
    | 'Cycle Count Review'
    | 'Inventory'
    | 'Dispute Resolution'
    | 'Shipping Planner View'
    | 'Shipping Planner Maintenance'
    | 'Delete Transaction'
    | 'Resolve Import Errors'
    | 'Customer Settings'
    | 'Equipment'
    | 'Dashboard'
    | 'Repair'
    | 'AdjustConfirm'
    | 'Item Transformation'
    | 'Cycle Count Manager';

const ADMINISTRATION_PERMISSIONS = {
    canAccessModule(): boolean {
        return (
            hasPermission('Items')
            || hasPermission('Locations')
            || hasPermission('Carriers')
            || hasPermission('Production Parts')
            || hasPermission('Users')
            || hasPermission('Roles')
            || hasPermission('Config')
            || hasPermission('Transactions')
            || hasPermission('Planning')
            || hasPermission('Cycle Count Review')
            || hasPermission('Inventory')
            || hasPermission('Shipping Planner Maintenance')
            || hasPermission('Shipping Planner View')
            || hasPermission('Delete Transaction')
            || hasPermission('Resolve Import Errors')
            || hasPermission('Customer Settings')
            || hasPermission('Equipment')
            || hasPermission('Dashboard')
            || hasPermission('Repair')
            || hasPermission('AdjustConfirm')
            || hasPermission('Item Transformation')
            || hasPermission('Cycle Count Manager')
        );
    },
    canAccessUserAdmin(): boolean {
        return hasPermission('Users') || hasPermission('Roles') || hasPermission('Config');
    },
    canEditItems(): boolean {
        return hasPermission('Items');
    },
    canEditLocations(): boolean {
        return hasPermission('Locations');
    },
    canEditCarriers(): boolean {
        return hasPermission('Carriers');
    },
    canEditProductionPartData(): boolean {
        return hasPermission('Production Parts');
    },
    canEditUsers(): boolean {
        return hasPermission('Users');
    },
    canEditRoles(): boolean {
        return hasPermission('Roles');
    },
    canEditConfig(): boolean {
        return hasPermission('Config');
    },
    canEditTransactions(): boolean {
        return hasPermission('Transactions');
    },
    canEditPlanning(): boolean {
        return hasPermission('Planning');
    },
    canEditCycleCounts(): boolean {
        return hasPermission('Cycle Count Review');
    },
    canCloseoutDisputedTransactions(): boolean {
        return hasPermission('Dispute Resolution');
    },
    canViewInventory(): boolean {
        return hasPermission('Inventory');
    },
    canEditShippingPlanner(): boolean {
        return hasPermission('Shipping Planner Maintenance');
    },
    canViewShippingPlanner(): boolean {
        return hasPermission('Shipping Planner View');
    },
    canTransformItem(): boolean {
        return hasPermission('Item Transformation');
    },
    canDeleteTransaction(): boolean {
        return hasPermission('Delete Transaction');
    },
    canResolveImportErrors(): boolean {
        return hasPermission('Resolve Import Errors');
    },
    canEditCustomerSettings(): boolean {
        return hasPermission('Customer Settings');
    },
    canEditEquipment(): boolean {
        return hasPermission('Equipment');
    },
    canAccessDashboard(): boolean {
        return hasPermission('Dashboard');
    },
    canRepair(): boolean {
        return hasPermission('Repair');
    },
    canAdjustConfirmed(): boolean {
        return hasPermission('AdjustConfirm');
    },
    canUseCycleCountManager(): boolean {
        return hasPermission('Cycle Count Manager');
    },
};

export default ADMINISTRATION_PERMISSIONS;
