
import { computed, defineComponent, PropType } from 'vue';
import { Variant } from '@/types';

export default defineComponent({
    name: 'b-banner',
    props: {
        variant: {
            type: String as PropType<Variant>,
            default: () => 'secondary',
        },
    },
    setup(props) {
        const classes = computed(() => [
            'b-banner',
            `bg-${props.variant}`,
        ]);

        return {
            classes,
        };
    },
});
