// TODO: need to figure out how to pass type in
import { SidebarStore } from '../SidebarStore';

export class CreateStore {
    private static instance: any;

    constructor(value: any) {
        CreateStore.instance = value;
    }

    public getInstance(): SidebarStore {
        if (!CreateStore.instance) {
            CreateStore.instance = new CreateStore.instance();
        }

        return CreateStore.instance;
    }
}
