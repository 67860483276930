import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "sidebar-inner noselect" }
const _hoisted_2 = ["onMouseenter"]
const _hoisted_3 = {
  key: 0,
  class: "nav-item-head"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "hover-icon-slide"
}
const _hoisted_7 = { key: 4 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_side_bar_nav_badge = _resolveComponent("side-bar-nav-badge")!

  return (_openBlock(), _createElementBlock("div", {
    id: "orbis-side-bar",
    class: "component-container",
    style: _normalizeStyle(_ctx.sidebar.sideBarVisible ? _ctx.containerOpenCss: null)
  }, [
    _createElementVNode("nav", {
      class: "sidebar",
      style: _normalizeStyle(_ctx.sidebar.sideBarVisible ? _ctx.sideBarOpenCss: _ctx.sideBarCloseCss)
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            onMouseenter: ($event: any) => (_ctx.onHover(item.id, item.hover ? item.hover : null)),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onHover(0, null)))
          }, [
            (item.type === _ctx.SideBarMenuItemTypes.HEADER && item.show)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.title), 1))
              : _createCommentVNode("", true),
            (item.type === _ctx.SideBarMenuItemTypes.EXTERNAL && item.show)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "btn-nav-parent",
                  onClick: ($event: any) => (_ctx.openExternalLink(item))
                }, [
                  _createVNode(_component_faicon, {
                    icon: item.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", null, _toDisplayString(item.title), 1)
                ], 8, _hoisted_4))
              : _createCommentVNode("", true),
            (item.type === _ctx.SideBarMenuItemTypes.BUTTON && item.show)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass({'btn-nav-parent': true, 'bg-primary': _ctx.$route.name === item.routeName}),
                  onClick: ($event: any) => (_ctx.navClick(item.routeName))
                }, [
                  _createVNode(_component_faicon, {
                    icon: item.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", null, _toDisplayString(item.title) + " ", 1),
                  (item.pushNotificationSubscription)
                    ? (_openBlock(), _createBlock(_component_side_bar_nav_badge, {
                        key: 0,
                        "push-notification-subscription-key": item.pushNotificationSubscription.key,
                        "push-notification-subscriptions": _ctx.pushNotificationSubscriptions
                      }, null, 8, ["push-notification-subscription-key", "push-notification-subscriptions"]))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_5))
              : _createCommentVNode("", true),
            (item.type === _ctx.SideBarMenuItemTypes.HOVER && item.show)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: _normalizeClass({'btn-nav-parent': true, 'btn-nav-parent-active': _ctx.$route.meta.parent === item.title.toLowerCase()})
                }, [
                  _createVNode(_component_faicon, {
                    icon: item.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", null, _toDisplayString(item.title), 1),
                  (_ctx.state.dynamicComponent && _ctx.state.hoverId === item.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_faicon, { icon: "long-arrow-alt-right" })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.state.dynamicComponent)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "hover-component-wrapper",
                        style: _normalizeStyle(_ctx.hoverComponentCss)
                      }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.state.dynamicComponent), { class: "hover-component" })),
                        _createElementVNode("div", {
                          class: "button button-close-hover-component",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onHover(0, null)))
                        }, [
                          _createVNode(_component_faicon, { icon: "times" })
                        ])
                      ], 4))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true),
            (item.type === _ctx.SideBarMenuItemTypes.PARENT && item.show)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass({'btn-nav-parent': true, 'btn-nav-parent-active': _ctx.$route.meta.parent === item.title.toLowerCase(), 'nav-item-head': !!item.isSectionHeader}),
                    onClick: ($event: any) => (_ctx.toggleAccordion(item.id))
                  }, [
                    (item.icon)
                      ? (_openBlock(), _createBlock(_component_faicon, {
                          key: 0,
                          icon: item.icon
                        }, null, 8, ["icon"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(item.title) + " ", 1),
                    (item.pushNotificationSubscription)
                      ? (_openBlock(), _createBlock(_component_side_bar_nav_badge, {
                          key: 1,
                          "push-notification-subscription-key": item.pushNotificationSubscription.key,
                          "push-notification-subscriptions": _ctx.pushNotificationSubscriptions
                        }, null, 8, ["push-notification-subscription-key", "push-notification-subscriptions"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_faicon, {
                      class: _normalizeClass({'rotate-chevron-90': _ctx.state.accordion.includes(item.id), 'rotate-chevron-0': !_ctx.state.accordion.includes(item.id), 'chevron-icon': true}),
                      icon: "chevron-right"
                    }, null, 8, ["class"])
                  ], 10, _hoisted_8),
                  _createElementVNode("div", {
                    class: _normalizeClass({'btn-accordion-open': true, 'accordion-content-open': _ctx.state.accordion.includes(item.id), 'accordion-content-closed': !_ctx.state.accordion.includes(item.id)})
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children.filter(c => c.show), (child) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: child.key
                      }, [
                        (child.type && child.type === _ctx.SideBarMenuChildTypes.PARENT)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("div", {
                                class: _normalizeClass({'btn-nav-parent': true, 'btn-nav-sub-active': _ctx.$route.meta.parent === child.title.toLowerCase(), 'btn-nav-parent-sub': true}),
                                onClick: ($event: any) => (_ctx.toggleAccordion(child.id))
                              }, [
                                (child.icon)
                                  ? (_openBlock(), _createBlock(_component_faicon, {
                                      key: 0,
                                      icon: child.icon
                                    }, null, 8, ["icon"]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", null, _toDisplayString(child.title) + " ", 1),
                                (child.pushNotificationSubscription)
                                  ? (_openBlock(), _createBlock(_component_side_bar_nav_badge, {
                                      key: 1,
                                      "push-notification-subscription-key": child.pushNotificationSubscription.key,
                                      "push-notification-subscriptions": _ctx.pushNotificationSubscriptions
                                    }, null, 8, ["push-notification-subscription-key", "push-notification-subscriptions"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_faicon, {
                                  class: _normalizeClass({'rotate-chevron-90': _ctx.state.accordion.includes(child.id), 'rotate-chevron-0': !_ctx.state.accordion.includes(child.id), 'chevron-icon': true}),
                                  icon: "chevron-right"
                                }, null, 8, ["class"])
                              ], 10, _hoisted_10),
                              _createElementVNode("div", {
                                class: _normalizeClass({
                                            'btn-accordion-open': true,
                                            'accordion-content-open': _ctx.state.accordion.includes(child.id),
                                            'accordion-content-closed': !_ctx.state.accordion.includes(child.id),
                                        })
                              }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(child.children.filter((c) => c.show), (nestedChild) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: nestedChild.key
                                  }, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass({'btn-nav-parent': true, 'bg-primary': nestedChild.activeRouteNameArray.includes(_ctx.$route.name), 'btn-nav-sub-sub': true}),
                                      onClick: ($event: any) => (_ctx.navClick(nestedChild.routeName))
                                    }, [
                                      (nestedChild.icon)
                                        ? (_openBlock(), _createBlock(_component_faicon, {
                                            key: 0,
                                            icon: nestedChild.icon
                                          }, null, 8, ["icon"]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", null, _toDisplayString(nestedChild.title) + " ", 1),
                                      (nestedChild.pushNotificationSubscription)
                                        ? (_openBlock(), _createBlock(_component_side_bar_nav_badge, {
                                            key: 1,
                                            "push-notification-subscription-key": nestedChild.pushNotificationSubscription.key,
                                            "push-notification-subscriptions": _ctx.pushNotificationSubscriptions
                                          }, null, 8, ["push-notification-subscription-key", "push-notification-subscriptions"]))
                                        : _createCommentVNode("", true)
                                    ], 10, _hoisted_11)
                                  ]))
                                }), 128))
                              ], 2)
                            ]))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: _normalizeClass({'btn-nav-sub': true, 'bg-primary': child.activeRouteNameArray.includes(_ctx.$route.name), 'btn-nav-sub-sub': child.type === 'child'}),
                              onClick: ($event: any) => (_ctx.navClick(child.routeName))
                            }, [
                              (child.icon)
                                ? (_openBlock(), _createBlock(_component_faicon, {
                                    key: 0,
                                    icon: child.icon
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", null, _toDisplayString(child.title) + " ", 1),
                              (child.pushNotificationSubscription)
                                ? (_openBlock(), _createBlock(_component_side_bar_nav_badge, {
                                    key: 1,
                                    "push-notification-subscription-key": child.pushNotificationSubscription.key,
                                    "push-notification-subscriptions": _ctx.pushNotificationSubscriptions
                                  }, null, 8, ["push-notification-subscription-key", "push-notification-subscriptions"]))
                                : _createCommentVNode("", true)
                            ], 10, _hoisted_12))
                      ]))
                    }), 128))
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ], 40, _hoisted_2))
        }), 128))
      ])
    ], 4),
    _createElementVNode("div", {
      class: _normalizeClass(['content', {'content-disable': _ctx.state.hoverId > 0 && _ctx.state.dynamicComponent}]),
      style: _normalizeStyle(_ctx.contentCss)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 6)
  ], 4))
}