import LocationType, { StaticLocationType } from '@/domain/LocationType';
import LocationAddress from '@/domain/LocationAddress';
import Language from '@/domain/Language';
import UpdateLocationDTO from '@/modules/master-data/dtos/UpdateLocationDTO';
import CreateNewLocationDTO from '@/dtos/CreateNewLocationDTO';
import NonWorkingTimeSet from './Dates/NonWorkingTimeSet';
import FreightCode from './FreightCode';
import { LocationAddressType } from './enums/LocationAddressType';
import SortStation from '@/domain/SortStation';

const defaultOpenTimeHour = 7;
const defaultCloseTimeHour = 17;

export default class Location {
    id: number = 0;

    name: string = '';

    shortName!: string;

    type: LocationType = new LocationType();

    typeId!: number;

    locationNumber?: string;

    lastCountDate: Date = new Date(); // datetime

    nextCountDate: Date = new Date(); // datetime

    displayName!: string;

    searchByName!: string;

    firstPreferredServiceCenter?: Location;

    secondPreferredServiceCenter?: Location;

    thirdPreferredServiceCenter?: Location;

    availableShipToLocations!: Array<Location>;

    nonWorkingTimeSetId?: number;

    nonWorkingTimeSet?: NonWorkingTimeSet;

    freightCodeId?: number;

    freightCode?: FreightCode;

    locationAddresses?: Array<LocationAddress> = new Array<LocationAddress>();

    isOpen24Hours: boolean = false;

    f_openTime: Date = new Date(new Date().setHours(defaultOpenTimeHour, 0, 0, 0));

    f_closeTime: Date = new Date(new Date().setHours(defaultCloseTimeHour, 0, 0, 0));

    taxId?: string;

    language?: Language;

    targetDaysOnHand?: number;

    sortStations: Array<SortStation> = [];

    equipmentInspectionInterval?: number;

    maxLoadPerTimeslot?: number;

    processedPutAwayPullFromLocationId?: number;

    timeslotDuration?: number;

    billToLocations: Array<Location> = [];

    numberAndName: string = '';

    constructor(init?: Partial<Location>) {
        Object.assign(this, init);
        this.type = new LocationType(init?.type);
        this.availableShipToLocations = new Array<Location>();
        this.typeId = this.type.id;
        if (init?.lastCountDate === null) {
            this.lastCountDate = new Date();
        }
        if (init?.nextCountDate === null) {
            this.nextCountDate = new Date();
        }
        this.locationAddresses = init?.locationAddresses?.map((add) => new LocationAddress(add));
        if (init?.sortStations) {
            this.sortStations = init.sortStations.map((s) => new SortStation(s));
        }
        if (init?.billToLocations) {
            this.billToLocations = init.billToLocations.map((location) => new Location(location));
        }
    }

    get isServiceCenter(): boolean {
        return this.type.staticTypeIdentifier === StaticLocationType.ServiceCenter;
    }

    get isManagedWarehouse(): boolean {
        return this.type.staticTypeIdentifier === StaticLocationType.ManagedWarehouse;
    }

    toCreateNewDTO(): CreateNewLocationDTO {
        return new CreateNewLocationDTO(this);
    }

    toUpdateDTO(): UpdateLocationDTO {
        return new UpdateLocationDTO(this);
    }

    getSafeTime(time: Date | string | undefined, defaultHour: number): Date {
        if (time) {
            const dateObj = new Date(time);

            return new Date(new Date().setHours(dateObj.getHours(), dateObj.getMinutes(), 0, 0));
        }

        return new Date(new Date().setHours(defaultHour, 0, 0, 0));
    }

    public get openTime(): Date {
        return this.f_openTime;
    }

    public set openTime(value: Date | undefined) {
        this.f_openTime = this.getSafeTime(value, defaultOpenTimeHour);
    }

    public get closeTime(): Date {
        return this.f_closeTime;
    }

    public set closeTime(value: Date | undefined) {
        this.f_closeTime = this.getSafeTime(value, defaultCloseTimeHour);
    }

    public get typeDescription() {
        return this.type.toString();
    }

    public get receivingAddress() {
        const shippingAndReceivingAddresses = this.locationAddresses?.filter((x) => x.type === LocationAddressType.ShippingAndReceiving);
        if (shippingAndReceivingAddresses?.length) {
            return shippingAndReceivingAddresses[0];
        }
        const receivingAddresses = this.locationAddresses?.filter((x) => x.type === LocationAddressType.Receiving);
        if (receivingAddresses?.length) {
            return receivingAddresses[0];
        }
        return undefined;
    }

    get nameAndNumber(): string {
        return !this.name ? '' : [this.name, this.locationNumber].join(' | ');
    }
}
