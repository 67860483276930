export default class Carrier {
    constructor(init?: Partial<Carrier>) {
        Object.assign(this, init);
    }

    public id!: number;

    public name!: string;

    public shortName!: string;

    public phone!: string;

    public email!: string;

    public contact!: string;

    public scac!: string;

    public dotRegistration!: string;

    public companyLogoUrl!: string;

    public companyUrl!: string;

    public deleted!: boolean;
}
