import coreStore from '@/store/CoreStore';
import { i18n } from '@/translation/i18n';
import Language from '@/domain/Language';
import LanguageApiService from '@/services/api/LanguageApiService';

export default class LanguageService {
    private languageApiService: LanguageApiService;

    private static readonly defaultLanguage = new Language({
        culture: window.navigator.language,
    });

    constructor() {
        this.languageApiService = new LanguageApiService();
    }

    public static initDefaultLanguage() {
        const { userLanguage } = coreStore.getInstance().profileStore;
        const { language: locationLanguage } = coreStore.getInstance().profileStore.userLocation;
        switch (true) {
        case !!userLanguage:
            LanguageService.setLanguage(userLanguage as Language);
            break;
        case !!locationLanguage:
            LanguageService.setLanguage(locationLanguage as Language);
            break;
        default:
            LanguageService.setLanguage(LanguageService.defaultLanguage);
        }
    }

    public async changeUserLanguage(language: Language) {
        await this.languageApiService.setUserLanguage(language);
        LanguageService.setLanguage(language);
        coreStore.getInstance().profileStore.userLanguage = language;
    }

    private static setLanguage(language: Language) {
        // @ts-ignore, this is typed incorrectly by the package, this is either a ref or computed, it needs value.
        i18n.global.locale.value = language.culture;
    }
}
