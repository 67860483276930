// functionality courtesy of https://www.vuemastery.com/blog/vue-3-data-down-events-up/
import {
    computed, SetupContext, watch, WritableComputedRef,
} from 'vue';

// wrap a modelValue so we don't try to change it directly and anger Vue
// will handle emitting updates to the parent as long as this is used on v-model props
export default function useModelWrapper(props: any, context: SetupContext<any>, name = 'modelValue'): WritableComputedRef<any> {
    return computed({
        get: () => props[name],
        set: (value) => context.emit(`update:${name}`, value),
    });
}
