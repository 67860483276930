import { AxiosError } from 'axios';

export default class ApiErrorWrapper {
    private _apiError: AxiosError;

    constructor(axiosError: AxiosError) {
        this._apiError = axiosError;
    }

    get statusCode() : number | undefined {
        return this._apiError.response?.status;
    }

    get responseBody() : ApiResponseBody {
        return { data: this._apiError.response?.data, message: this.responseMessage };
    }

    get responseMessage() : string {
        const statusText = this._apiError.response?.statusText;
        const msg = this._apiError.message;
        return `${statusText}\n${msg}`;
    }
}

export type ApiResponseBody<T = any> = {
    data: T;
    message: string;
};
