export default class RepairPart {
    id: number = 0;

    partNumber: string = '';

    description: string = '';

    reorderPoint: number = 0;

    quantity: string = '';

    billingPrice: number = 0.0;

    constructor(init?: Partial<RepairPart>) {
        Object.assign(this, init);
    }
}
