import { ref } from 'vue';

export type ScreenYPosition = 'top' | 'bottom';

const topBreakpoint = 0.7; // 70% of the screen

export default function useScreenDetector() {
    const element = ref<HTMLElement | null>(null);

    function isTopHalf(): boolean {
        if (element.value) {
            return (window.innerHeight * topBreakpoint) > element.value?.getBoundingClientRect().top;
        }
        return true;
    }

    function getPosition(): ScreenYPosition {
        if (isTopHalf()) return 'top';
        return 'bottom';
    }

    return {
        element,
        getPosition,
    };
}
