
import {
    computed, defineComponent, nextTick, onBeforeMount, onBeforeUnmount, onMounted, PropType, reactive, Ref, watch,
} from 'vue';
import sidebarStore from './store/SidebarStore';
import SideBarMenuItem from './domain/SideBarMenuItem';
import BBadge from '@/components/bootstrap-library/BBadge.vue';
import { routesHideSideBar } from '@/components/sidebar/settings';
import SideBarNavBadge from '@/components/sidebar/SideBarNavBadge.vue';
import router from '@/router';
import SideBarMenuChild from './domain/SideBarMenuChild';

type State = {
    screenWidth: number;
    screenHeight: number;
    accordion: Array<number>;
    dynamicComponent: Promise<any> | null;
    hoverId: number;
};

export default defineComponent({
    name: 'side-bar-nav',
    components: {
        BBadge,
        SideBarNavBadge,
    },
    props: {
        hide: {
            type: Boolean,
            required: false,
        },
        menuItems: {
            type: Array as () => Array<SideBarMenuItem>,
            required: true,
        },
        pushNotificationSubscriptions: {
            type: Object as PropType<Map<string, Ref<number>>>,
            default: () => new Map<string, Ref<number>>(),
            required: false,
        },
    },
    setup(props) {
        const sidebar = sidebarStore.getInstance();

        const state = reactive<State>({
            screenWidth: 0,
            screenHeight: 0,
            accordion: [],
            hoverId: 0,
            dynamicComponent: null,
        });

        watch(
            () => [props.hide],
            (val) => {
                if (val) sidebar.sideBarVisible = false;
            },
            { immediate: true },
        );

        watch(
            () => [sidebar.sideBarVisible],
            () => {
                state.accordion = [];
            },
            { immediate: true },
        );

        router.afterEach(() => {
            // after each route change, if routesHideSideBar is included, hide
            nextTick(() => {
                if (router.currentRoute.value.name && routesHideSideBar.includes(router.currentRoute.value.name as string)) {
                    sidebar.sideBarVisible = false;
                }
            });
            state.dynamicComponent = null;
            state.hoverId = 0;
        });

        const sideBarWidth = computed((): number => sidebar.sideBarWidth);

        const sideBarOpenCss = computed(() => ({
            width: `${sideBarWidth.value}px`,
            left: 0,
        }));

        const sideBarCloseCss = computed(() => ({
            left: `${-sideBarWidth.value}px`,
            transition: 'all 0.3s',
            width: `${sideBarWidth.value}px`,
        }));

        const containerOpenCss = computed(() => ({
            width: `calc(100% - ${sideBarWidth.value}px) !important`,
        }));

        const hoverComponentCss = computed(() => ({
            width: 'fit-content',
            left: `${sideBarWidth.value - 100}px`,
        }));

        const contentCss = computed(() => ({
            height: `${state.screenHeight - 56}px`,
        }));

        function handleResize() {
            state.screenWidth = window.innerWidth;
            state.screenHeight = window.innerHeight;
        }

        function toggleAccordion(value: number): void {
            if (state.accordion.includes(value)) {
                const index = state.accordion.indexOf(value);
                if (index !== -1) {
                    state.accordion.splice(index, 1);
                }
            } else {
                state.accordion.push(value);
            }
        }

        function hideSideBar(): void {
            sidebar.sideBarVisible = false;
        }

        function navClick(route: string): void {
            router.push({ name: route });
        }

        function activeParentRoute(parent: SideBarMenuItem): boolean {
            return parent.children?.some((x) => x.routeName === router.currentRoute.value.name) ?? false;
        }

        onBeforeMount(async () => {
            window.addEventListener('resize', handleResize);
            handleResize();
            if (!props.hide) {
                sidebar.sideBarVisible = true;
            }
        });

        function expandDefaultMenu() {
            const defaultMenu = props.menuItems.find((menu) => !!menu.isDefaultExpanded);
            if (defaultMenu?.id) {
                toggleAccordion(defaultMenu.id);
            }
        }

        onMounted(() => {
            expandDefaultMenu();
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });

        function onHover(id: number, component: Promise<any> | null = null) {
            state.hoverId = id;
            state.dynamicComponent = component;
        }

        function openExternalLink(item: SideBarMenuItem) {
            window.open(item.externalUrl, '_blank');
        }

        return {
            sidebar,
            state,
            sideBarOpenCss,
            sideBarCloseCss,
            sideBarWidth,
            containerOpenCss,
            toggleAccordion,
            hideSideBar,
            navClick,
            activeParentRoute,
            SideBarMenuItemTypes: SideBarMenuItem.TYPE,
            hoverComponentCss,
            onHover,
            contentCss,
            openExternalLink,
            SideBarMenuChildTypes: SideBarMenuChild.TYPE,
        };
    },
});
