import { UOMLength, UOMWeight } from '@/measurement/types';

export type UOMTranslation = {
    [key in UOMLength | UOMWeight]: string;
};

const uomEn: UOMTranslation = {
    feet: 'feet',
    inches: 'inches',
    pounds: 'pounds',
};

const uomEs: UOMTranslation = {
    feet: 'pies',
    inches: 'pulgadas',
    pounds: 'libras',
};

export { uomEn, uomEs };
