import { reactive } from 'vue';
import { store } from '@/decorators/store';
import TrailerType from '@/domain/TrailerType';
import { CoreStore } from '../CoreStore';

export type State = {
    trailerTypes: Array<TrailerType>
};

@store
export default class ConfigStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    // state
    private state = reactive<State>({
        trailerTypes: [],
    });

    public initTrailerTypes(value: Array<TrailerType>) {
        this.state.trailerTypes = value;
    }

    get trailerTypes(): Array<TrailerType> {
        return this.state.trailerTypes;
    }

    get preferredTrailerType(): TrailerType {
        return this.state.trailerTypes.find((x) => x.isPreferred) as TrailerType;
    }

    public findTrailerType(id: number): TrailerType {
        return this.state.trailerTypes.find((x) => x.id === id) as TrailerType;
    }

    public addTrailerType(value: TrailerType) {
        this.state.trailerTypes.unshift(value);
    }

    public deleteTrailerType(value: TrailerType) {
        const index = this.state.trailerTypes.findIndex((data) => data.id === value.id);
        this.state.trailerTypes.splice(index, 1);
    }

    public updateTrailerType(value: TrailerType) {
        this.state.trailerTypes = this.state.trailerTypes.map((data) => {
            if (data.id === value.id) {
                return value;
            }
            return data;
        });
    }
}
