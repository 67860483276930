export default class FreightCode {
    constructor(init?: Partial<FreightCode>) {
        Object.assign(this, init);
    }

    public id!: number;

    public description!: string;

    public deleted!: boolean;
}
