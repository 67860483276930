import router from '@/router';
import CoreStore from '@/store/CoreStore';

export default function useHelp() {
    const wikiBaseLink = process.env.VUE_APP_WIKI_LINK;

    function helpLink(): string {
        const coreStore = CoreStore.getInstance();
        const lang = `/${coreStore.profileStore.userLanguage?.culture ?? 'en'}`;
        const path = router.currentRoute.value.matched[router.currentRoute.value.matched.length - 1].path ?? router.currentRoute.value.fullPath;
        const wikiPath = coreStore.metaDataStore.findWikiRoute(path)?.wikiPath ?? path;
        return `${wikiBaseLink + lang + wikiPath}`;
    }

    function help() {
        window.open(helpLink(), '_blank');
    }

    return {
        help,
    };
}
