class Logo {
    constructor(init: Logo) {
        this.filename = init.filename;
        this.path = init.path;
    }

    public filename: string;

    public path: string;
}

const CanpackLogo: Logo = {
    filename: 'canpack.png',
    path: require('@/assets/canpack.png'),
};

const CatLogo: Logo = {
    filename: 'caterpillar.png',
    path: require('@/assets/caterpillar.png'),
};

const Unilever: Logo = {
    filename: 'unilever.png',
    path: require('@/assets/unilever.png'),
};

export const OrbisLogo: Logo = {
    filename: 'orbis.png',
    path: require('@/assets/orbis.png'),
};

export const Logos: Logo[] = [OrbisLogo, CanpackLogo, CatLogo, Unilever];
