
import {
    computed, defineComponent, reactive, watch,
} from 'vue';
import useScreenDetector, { ScreenYPosition } from '@/composable/useScreenDetector';
import useScreenSize from '@/composable/useScreenSize';

type MenuStyle = {
    bottom?: number | string;
    right?: number | string;
    left?: number | string;
};

type State = {
    position: ScreenYPosition;
    show: boolean;
}

export default defineComponent({
    name: 'b-dropdown',
    props: {
        title: { type: String, default: undefined },
        right: { type: Boolean, default: true },
        left: Boolean,
        closeOnClick: Boolean,
        noCaret: { type: Boolean, default: false },
        dropUp: { type: Boolean, default: false },
        openOnHover: { type: Boolean, default: false },
        disabled: Boolean,
        isButton: { type: Boolean, default: false },
        maxHeight: { type: String, default: undefined },
    },
    setup(props) {
        const { element, getPosition } = useScreenDetector();
        const { isMobile } = useScreenSize();

        const state = reactive<State>({
            position: 'top',
            show: false,
        });

        const menuClass = props.dropUp ? 'dropup' : 'dropdown';

        const menuStyle = computed(
            (): MenuStyle => {
                const style = new Map<string, string>();

                if (props.maxHeight) {
                    style.set('max-height', props.maxHeight);
                }
                if (props.dropUp || state.position === 'bottom') {
                    style.set('bottom', '2.4rem');
                }
                style.set('right', props.left ? '0' : 'auto');
                style.set('left', props.left ? 'auto' : '0');

                return Object.fromEntries(style);
            },
        );

        watch(() => state.show, () => {
            state.position = getPosition();
        });

        function handleClickOutside() {
            if (state.show) {
                state.show = false;
            }
        }

        function handleClick() {
            if (!props.disabled) {
                state.show = !state.show;
            }
        }

        function handleClickDropdownItem() {
            if (props.closeOnClick) {
                state.show = false;
            }
        }

        function handleMouseOver() {
            if (props.openOnHover && !props.disabled && !isMobile.value) {
                state.show = true;
            }
        }

        return {
            state,
            menuClass,
            menuStyle,
            element,
            handleClickOutside,
            handleClick,
            handleClickDropdownItem,
            handleMouseOver,
        };
    },
});
