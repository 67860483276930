import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4de2f80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "datepicker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, {
    id: "date-input",
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs
  }, {
    default: _withCtx(() => [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_datepicker, {
          modelValue: _ctx.picked,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.picked) = $event)),
          class: _normalizeClass(['form-control', {'readonly': _ctx.readonly}, _ctx.$style.datepicker]),
          "input-format": _ctx.inputFormat,
          "lower-limit": _ctx.lowerLimit,
          "upper-limit": _ctx.upperLimit,
          disabled: _ctx.isDisabled,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput($event)))
        }, null, 8, ["modelValue", "class", "input-format", "lower-limit", "upper-limit", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs"]))
}