

import { defineComponent, onBeforeMount, PropType } from 'vue';

type InputType =
    'text'
    | 'number'
    | 'email'
    | 'password'
    | 'search'
    | 'url'
    | 'tel'
    | 'date'
    | 'time'
    | 'range'
    | 'color';

export default defineComponent({
    name: 'text-input',
    components: {},
    props: {
        modelValue: {
            type: [String, Number],
        },
        cols: String,
        lg: String,
        md: String,
        sm: String,
        xs: String,
        label: String,
        max: Number,
        disabled: Boolean,
        placeholder: String,
        moreInfo: String,
        textArea: Boolean,
        loading: Boolean,
        nullIfEmpty: Boolean,
        autocomplete: {
            type: String,
            default: () => 'off',
        },
        required: {
            type: Boolean,
            default: () => false,
        },
        readonly: {
            type: Boolean,
            default: () => false,
        },
        error: String,
        autofocus: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String as PropType<InputType>,
            default: 'text',
        },
    },
    emits: ['update:modelValue', 'input', 'change'],
    setup(props, context) {
        onBeforeMount(() => {
            handleChange(props.modelValue);
        });

        function handleChange(e: any) {
            if (!props.readonly) {
                //clear undefined or empty string to null
                if (props.nullIfEmpty && typeof e === "string") {
                    e = e || null;
                }

                context.emit('input', e);
                context.emit('change', e);
                context.emit('update:modelValue', e);
            }
        }

        function formatter(value: string): string {
            if (props.max) {
                return value.substring(0, props.max);
            }
            return value;
        }

        return {
            handleChange,
            formatter,
        };
    },
});

