
import {
    computed, defineComponent, onBeforeUnmount, onMounted, PropType, reactive, ref, StyleValue, watch,
} from 'vue';
import type { CSSProperties } from 'vue';
import { Modal } from 'bootstrap';
import useEventListener from '@/components/bootstrap-library/composables/useEventListener';
import BButton from '@/components/bootstrap-library/BButton.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import uuid from '@/functions/uuid';

type State = {
    clickingOk: boolean;
}

export default defineComponent({
    name: 'b-modal',
    components: {
        BButton,
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        fade: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: [Boolean, String],
            default: false,
        },
        id: { type: String },
        title: { type: String },
        okButton: { type: String, default: () => getTitleCaseTranslation('core.button.ok') },
        cancelButton: { type: String, default: () => getTitleCaseTranslation('core.button.cancel') },
        scrollable: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
        size: { type: String },
        staticBackdrop: { type: Boolean }, // prevents closing modal when clicking backdrop
        disableEscapeKey: Boolean,
        hideFooter: { type: Boolean, default: () => false },
        hideHeader: { type: Boolean, default: () => false },
        hideHeaderClose: { type: Boolean, default: () => false },
        modalDialogStyle: {
            type: Object as PropType<CSSProperties>,
            default: () => {
            },
        },
        onEnter: {
            type: Function,
            default: undefined,
        },
        zIndex: {
            type: Number,
            default: undefined,
        },
    },
    emits: [
        'update:modelValue',
        'show',
        'shown',
        'hide',
        'hidden',
        'hide-prevented',
        'ok',
        'cancel',
    ],
    setup(props, { emit }) {
        const state = reactive<State>({
            clickingOk: false,
        });
        const componentId = props.id === undefined ? uuid() : props.id;

        const element = ref<HTMLElement>();
        const instance = ref<Modal>();
        const focusHere = ref<HTMLElement>();
        const classes = computed(() => ({
            fade: props.fade,
            show: props.show,
        }));
        const styles = computed(() => ((props.zIndex ? { zIndex: `${props.zIndex}` } : {}) as StyleValue));

        const modalDialogClasses = computed(() => ({
            'modal-fullscreen': typeof props.fullscreen === 'boolean' ? props.fullscreen : false,
            [`modal-fullscreen-${props.fullscreen}-down`]: typeof props.fullscreen === 'string' ? props.fullscreen : false,
            [`modal-${props.size}`]: props.size,
            'modal-dialog-centered': props.centered,
            'modal-dialog-scrollable': props.scrollable,
        }));

        useEventListener(element, 'shown.bs.modal', () => {
            emit('shown');
            // prefer to avoid direct DOM interaction. but required here because of how this singleton works
            const btn = element.value?.getElementsByClassName('btn-primary');
            if (btn?.length) {
                (btn[0] as HTMLElement).focus();
            }
        });

        useEventListener(element, 'hidden.bs.modal', () => emit('hidden'));
        useEventListener(element, 'hidePrevented.bs.modal', () => emit('hide-prevented'));
        useEventListener(element, 'show.bs.modal', () => {
            emit('show');
            emit('update:modelValue', true);
        });

        useEventListener(element, 'hide.bs.modal', () => {
            emit('hide');
            emit('update:modelValue', false);
        });

        if (props.onEnter) {
            useEventListener(element, 'keydown', (event) => {
                if ((event as KeyboardEvent).key === 'Enter') {
                    state.clickingOk = true;
                    focusHere.value?.focus();
                    props.onEnter!();
                    state.clickingOk = false;
                }
            });
        }

        onMounted(() => {
            instance.value = new Modal(element.value as HTMLElement, {
                backdrop: 'static',
                keyboard: !props.disableEscapeKey,
            });
            if (props.modelValue) {
                instance.value?.show();
            }
        });

        // if you dont close the modal in the correct order, sometimes the modal-backdrop persists
        // this is probably due to the element being teleported outside to the body
        // i think this is an OK fix for now
        onBeforeUnmount(() => {
            Array.from(document.getElementsByClassName('modal-backdrop')).forEach((el) => el.remove());
        });

        watch(() => props.modelValue, (value) => {
            if (value) {
                instance.value?.show();
                state.clickingOk = false;
            } else {
                instance.value?.hide();
                state.clickingOk = false;
            }
        });

        return {
            state,
            element,
            focusHere,
            classes,
            modalDialogClasses,
            componentId,
            styles,
        };
    },
});
