
import {
    computed, defineComponent, nextTick, onActivated, onMounted, onUpdated, PropType, reactive, ref,
} from 'vue';
import useNumberIncrementer from './composables/useNumberIncrementer';
import Popover from '@/components/bootstrap-library/Popover.vue';

type InputEvaluationResult = {
    returnedValue?: number | string,
    attemptedValue?: number | string,
}

type State = {
    componentKey: number
}

type InputType =
    'text'
    | 'number'
    | 'email'
    | 'password'
    | 'search'
    | 'url'
    | 'tel'
    | 'date'
    | 'time'
    | 'range'
    | 'color';

interface NumberOptions {
    allowsDecimal?: boolean
    allowsNegative?: boolean
}

export default defineComponent({
    name: 'b-form-input',
    components: {
        Popover,
    },
    props: {
        modelValue: [String, Number],
        label: String,
        placeholder: String,
        autocomplete: { type: String },
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: { type: Boolean, default: false },
        max: Number,
        min: Number,
        step: { type: Number, default: 1 },
        type: {
            type: String as PropType<InputType>,
            default: 'text',
        },
        hideStepper: { type: Boolean, default: true },
        trim: {
            type: Boolean,
            default: true,
        },
        number: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        error: String,
        appendGroupText: String,
        numberOptions: Object as PropType<NumberOptions>,
        moreInfo: String,
        preventTyping: Boolean,
    },
    emits: ['update:modelValue', 'change', 'input', 'keypress', 'focus', 'blur'],
    setup(props, context) {
        const input = ref<HTMLInputElement>();
        const state = reactive<State>({
            componentKey: 0,
        });

        // lifecycle events
        const handleAutofocus = () => {
            nextTick(() => {
                if (props.autofocus) {
                    input.value?.focus();
                }
            });
        };

        onMounted(handleAutofocus);
        onActivated(handleAutofocus);
        onUpdated(handleAutofocus);

        function onInput(evt: Event) {
            const { value } = evt.target as HTMLTextAreaElement;
            context.emit('input', value);
        }

        function onKeyPress(event: KeyboardEvent) {
            if (props.type === 'number' && !isValidKeyForNumber(event.key, props.numberOptions)) {
                event.preventDefault();
            } else {
                context.emit('keypress', event);
            }
        }

        function onKeyDown(event: KeyboardEvent) {
            if (props.preventTyping) {
                event.preventDefault();
            }
        }

        function onChange(evt: Event) {
            const inputElValue = (evt.target as HTMLTextAreaElement).value;
            let formattedValue: number | string | undefined = inputElValue;
            let currentModelValue = props.modelValue;

            if (props.type === 'number') {
                formattedValue = convertToNumber(inputElValue);
                currentModelValue = convertToNumber(currentModelValue);
            }

            const { attemptedValue, returnedValue } = evaluateMaxMin(formattedValue);
            const valueToEmit = returnedValue;

            context.emit('update:modelValue', valueToEmit);

            if (currentModelValue !== valueToEmit) {
                context.emit('change', valueToEmit, attemptedValue);
            }

            if (attemptedValue !== returnedValue) {
                state.componentKey++;
            }
        }

        function onFocus() {
            if (props.type === 'number' && props.modelValue === 0) {
                input.value!.value = '';
            }
            context.emit('focus');
        }

        function onBlur() {
            // check if text box should be trimmed
            if (props.trim && typeof props.modelValue === 'string') {
                const trimmed = props.modelValue.trim();

                // if trim made a difference, set input element to new value and emit events
                if (props.modelValue !== trimmed) {
                    context.emit('update:modelValue', trimmed);
                    context.emit('input', trimmed);
                    context.emit('change', trimmed);
                }
            }

            if (input.value && props.type === 'number' && props.required && props.modelValue === 0) {
                input.value.value = '0';
            }

            context.emit('blur');
        }

        const isDisabled = computed(() => props.disabled || props.readonly);

        const showStepper = computed((): boolean => !(props.hideStepper || props.type !== 'number'));

        function handleStep(value: number) {
            if (props.type === 'number') {
                const stepFactor = value * props.step;
                const modelValue = convertToNumber(props.modelValue) ?? 0;

                const { returnedValue } = evaluateMaxMin(modelValue + stepFactor);

                context.emit('update:modelValue', returnedValue);
                context.emit('input', returnedValue);
                context.emit('change', returnedValue);
            }
        }

        function isValidKeyForNumber(keyboardKey: string, numberOptions?: NumberOptions): boolean {
            if (numberOptions?.allowsDecimal && keyboardKey === '.') {
                return true;
            }

            if (numberOptions?.allowsNegative && keyboardKey === '-') {
                return true;
            }

            return !!(keyboardKey.match(/^\d$/));
        }

        const { stop, numberChange } = useNumberIncrementer({ changeCallback: handleStep });

        function convertToNumber(value?: string | number): number | undefined {
            if (!props.required && !value) {
                return undefined;
            }
            if (typeof value === 'number') {
                return value;
            }

            return !value ? 0 : parseFloat(value);
        }

        function evaluateMaxMin(value?: number | string): InputEvaluationResult {
            const result: InputEvaluationResult = { attemptedValue: value, returnedValue: value };
            if (typeof value === 'number') {
                if (props.min && value < props.min) {
                    result.returnedValue = props.min;
                }
                if (props.max && value > props.max) {
                    result.returnedValue = props.max;
                }
                return result;
            }
            return result;
        }

        const hasAppendGroupSlot = computed((): boolean => !!context.slots.appendGroupText);

        const hasAppendGroup = computed((): boolean => !!props.appendGroupText || hasAppendGroupSlot.value);

        return {
            input,
            onInput,
            onKeyPress,
            onChange,
            onFocus,
            onBlur,
            onKeyDown,
            isDisabled,
            showStepper,
            stop,
            numberChange,
            state,
            hasAppendGroup,
            hasAppendGroupSlot,
        };
    },
});
