import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import ShippingLoadType from '@/domain/ShippingLoadType';

export default class ShippingLoadTypeApiService {
    public async getAllShippingLoadTypes(): Promise<DataAccessResponse<Array<ShippingLoadType>>> {
        const response: AxiosResponse<DataAccessResponse<Array<ShippingLoadType>>> = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/shippingLoadTypes`,
        });

        return new DataListAccessResponseClass<ShippingLoadType>(response, ShippingLoadType).response;
    }
}
