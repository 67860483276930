import axios, { AxiosResponse } from 'axios';
import LocationType from '@/domain/LocationType';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class LocationTypeApiService {
    public async getLocationTypes(): Promise<DataAccessResponse<Array<LocationType>>> {
        const response: AxiosResponse<DataAccessResponse<Array<LocationType>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/LocationTypes`,
        });

        return new DataListAccessResponseClass<LocationType>(response, LocationType).response;
    }

    public async addNewLocationType(locationType: LocationType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/LocationTypes`,
            data: locationType,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateLocationType(locationType: LocationType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/LocationTypes`,
            data: locationType,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async deleteLocationType(locationType: LocationType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/LocationTypes`,
            data: locationType,
        });

        return new DataAccessResponseClass<number>(response).response;
    }
}
