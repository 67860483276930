import { createApp } from 'vue';
import {
    faBarcode,
    faTrash,
    faBars,
    faBoxes,
    faBoxOpen,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCog,
    faDatabase,
    faDolly,
    faDollyFlatbed,
    faDraftingCompass,
    faExchangeAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faHome,
    faImage,
    faLayerGroup,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faMinus,
    faNewspaper,
    faPlus,
    faSearch,
    faShippingFast,
    faSignOutAlt,
    faSyncAlt,
    faUnlock,
    faUserSecret,
    faTimes,
    faUserLock,
    faUserCircle,
    faBell,
    faUsers,
    faToolbox,
    faMapPin,
    faBox,
    faTruck,
    faTable,
    faColumns,
    faArrowRight,
    faIdBadge,
    faArrowLeft,
    faLongArrowAltRight,
    faEllipsisH,
    faArrowDown,
    faArrowUp,
    faIdCard,
    faLock,
    faPallet,
    faClipboard,
    faInfo,
    faWindowMaximize,
    faWindowRestore,
    faChartBar,
    faExclamation,
    faFlag,
    faObjectGroup,
    faQuestionCircle,
    faProjectDiagram,
    faChartLine,
    faCamera,
    faWrench,
    faCalculator,
    faFileSignature,
    faFileInvoice,
    faCalendar,
    faClock,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './stylesheets/main.scss';
import '@/components/bootstrap-library/stylesheets/main.scss';
import './stylesheets/zoom.css';

import { APPLICATION_DISPLAY_NAME } from '@/constants/env';

import { AuthStore } from './store/AuthStore';
import CameraModal from '@/components/CameraModal.vue';
import SideBarNav from '@/components/sidebar/SideBarNav.vue';
import StartupService from '@/services/StartupService';
import OrbisCard from '@/components/OrbisCard.vue';
import PlacesAutocomplete from '@/components/PlacesAutocomplete.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import NumberInput from '@/components/inputs/NumberInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import Screen from '@/components/layout/Screen.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import oidcConfig from '@/config/okta/authConfig';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import BFormTextarea from '@/components/bootstrap-library/BFormTextarea.vue';
import BNavbar from '@/components/bootstrap-library/BNavbar.vue';
import BNavbarNav from '@/components/bootstrap-library/BNavbarNav.vue';
import BNavbarBrand from '@/components/bootstrap-library/BNavbarBrand.vue';
import BBadge from '@/components/bootstrap-library/BBadge.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import BTable from '@/components/bootstrap-library/table/BTable/BTable.vue';
import BPagination from '@/components/bootstrap-library/table/BTable/BPagination.vue';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import { ClickOutsideDirective } from '@/components/bootstrap-library/directives/clickoutside';
import { TestDirective } from '@/components/bootstrap-library/directives/test';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BFormDatepicker from '@/components/bootstrap-library/BFormDatepicker.vue';
import DateInput from '@/components/inputs/DateInput.vue';
import { PopoverDirective } from '@/components/bootstrap-library/directives/popover';
import BBanner from '@/components/bootstrap-library/BBanner.vue';
import BButtonGroup from '@/components/bootstrap-library/BButtonGroup.vue';
import ClientIdConfig from '@/config/okta/ClientIdConfig';
import { i18n } from '@/translation/i18n';
import { normalizeTitle } from './functions/title';

library.add(
    faUserSecret,
    faDolly,
    faShippingFast,
    faBoxes,
    faExchangeAlt,
    faPlus,
    faMinus,
    faNewspaper,
    faBoxOpen,
    faImage,
    faLayerGroup,
    faDollyFlatbed,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faBars,
    faSearch,
    faHome,
    faSignOutAlt,
    faExclamationTriangle,
    faCheck,
    faExclamationCircle,
    faSyncAlt,
    faBarcode,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faDraftingCompass,
    faUnlock,
    faTimes,
    faUserLock,
    faUserCircle,
    faBell,
    faUsers,
    faToolbox,
    faMapPin,
    faBox,
    faTruck,
    faTable,
    faColumns,
    faArrowRight,
    faArrowLeft,
    faLongArrowAltRight,
    faEllipsisH,
    faIdBadge,
    faArrowDown,
    faArrowUp,
    faIdCard,
    faLock,
    faPallet,
    faTrash,
    faClipboard,
    faInfo,
    faWindowMaximize,
    faWindowRestore,
    faChartBar,
    faExclamation,
    faDatabase,
    faFlag,
    faObjectGroup,
    faQuestionCircle,
    faProjectDiagram,
    faChartLine,
    faCog,
    faCamera,
    faWrench,
    faCalculator,
    faFileSignature,
    faFileInvoice,
    faCalendar,
    faClock
);

oidcConfig().then(async (config: OktaAuthOptions) => {
    // TODO: figure out better error handling, probably route them to a 404 page
    if (!config.clientId) {
        alert('Unable to find client id for subdomain');
        throw new Error('Unable to find client id for subdomain');
    }

    const oktaAuth = new OktaAuth(config);

    const subdomain = ClientIdConfig.getSubdomain();
    router.beforeEach((to, from, next) => {
        document.title = subdomain + '-' + normalizeTitle(to.name as string);
        next();
    });

    const startupService = new StartupService();
    await startupService.startApp();
    const authStore = AuthStore.getInstance();

    const app = createApp(App);

    app.component('faicon', FontAwesomeIcon);
    app.component('side-bar', SideBarNav);
    app.component('camera-modal', CameraModal);
    app.component('orbis-card', OrbisCard);
    app.component('places-autocomplete', PlacesAutocomplete);
    app.component('text-input', TextInput);
    app.component('date-input', DateInput);
    app.component('number-input', NumberInput);
    app.component('checkbox-input', CheckboxInput);
    app.component('select-input', SelectInput);
    app.component('screen', Screen);

    // CONVERTED BOOTSTRAP app. COMPONENTS

    app.component('BRow', BRow);
    app.component('BCol', BCol);
    app.component('BButton', BButton);
    app.component('BButtonGroup', BButtonGroup);
    app.component('BFormInput', BFormInput);
    app.component('BForm', BForm);
    app.component('BFormCheckbox', BFormCheckbox);
    app.component('BFormTextarea', BFormTextarea);
    app.component('BFormSelect', BFormSelect);
    app.component('BFormDatepicker', BFormDatepicker);

    app.component('BNavbar', BNavbar);
    app.component('BNavbarNav', BNavbarNav);
    app.component('BNavbarBrand', BNavbarBrand);

    app.component('BDropdown', BDropdown);
    app.component('BDropdownItem', BDropdownItem);

    app.component('BBadge', BBadge);
    app.component('BSpinner', BSpinner);
    app.component('BBanner', BBanner);

    app.component('BTable', BTable);
    app.component('BAdvancedTable', BAdvancedTable);
    app.component('BPagination', BPagination);

    app.component('BModal', BModal);

    app.directive('click-outside', ClickOutsideDirective);
    app.directive('test', TestDirective);
    app.directive('popover', PopoverDirective);

    if (process.env.VUE_APP_MODE !== 'development') {
        Sentry.init({
            app,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracePropagationTargets: ['localhost', 'smarttrak360.com', /^\//],
                }),
            ],
            environment: process.env.VUE_APP_MODE,
            tracesSampleRate: 0.01,
        });
    }

    app.use(i18n);
    app.use(router);
    app.use(OktaVue, { oktaAuth });
    app.mount('#vue-app');
    await authStore.initAuth(oktaAuth);
});
