
import {
    computed, defineComponent, onBeforeMount, PropType, reactive, watch,
} from 'vue';
import isEqual from 'lodash.isequal';
import { Option, TableDefinition } from '@/types';
import useStringFormatter from '@/composable/useStringFormatter';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import BTable, { SidePaneOptions } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import SubHeader from '@/components/SubHeader.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    tableKey: string;
    tableName: string;
    search: string;
    searchNumFrom: number | null;
    searchNumTo: number | null;
    searchKey: string;
    dynamicColumns: Array<string>;
};

type SearchType = 'string' | 'number' | 'array';

export default defineComponent({
    name: 'b-advanced-table',
    components: {
        SubHeader,
        BFormSelect,
        BTable,
        BFormCheckbox,
    },
    props: {
        tableArray: {
            type: Array as () => Array<TableDefinition<any>>,
            required: true,
        },
        stickyHeader: String,
        loading: Boolean,
        dynamicColumns: { type: [Boolean], default: () => false },
        sidePaneOptions: Object as PropType<SidePaneOptions>,
        showPagination: { type: Boolean, default: false },
        verticalTextAlignment: {
            type: String as PropType<'top' | 'middle' | 'bottom'>,
            default: () => 'middle',
        },
        allowOverflow: Boolean,
        hideHeader: { type: Boolean, default: false },
        rowHeight: {
            type: String,
            default: () => '40px',
        },
        enableMultiselect: {
            type: Boolean,
            default: () => false,
        },
        selectedItems: {
            type: Array as PropType<Array<unknown>>,
            default: () => [],
        },
        boldTitle: { type: Boolean, default: false },
    },
    emits: ['columnChange', 'onTableChange'],
    setup(props, context) {
        const hasSidePane = computed((): boolean => !!context.slots['side-pane'] || !!selectedTable.value.sidePane);

        const hasSidePaneHead = computed((): boolean => !!context.slots['side-pane-head']);

        const { getLabelFromFormDefinition } = useStringFormatter();

        // used to create the table dropdown select
        const tableOptions = computed(
            (): Array<Option> => {
                const options: Array<Option> = [];

                for (const table of props.tableArray) {
                    options.push({
                        text: table.name,
                        value: table.key,
                    });
                }
                return options;
            },
        );

        const hasActionSlotOrField = computed((): boolean => {
            const hasActionSlot = !!context.slots.action;
            const hasActionField = !!selectedTable.value.columnDefinition.find((def) => def.key === 'action');
            return hasActionSlot || hasActionField;
        });

        const slots = computed(() => context.slots);

        // show top row if slots are present
        const showTopRow = computed(() => !!context.slots.toprow);

        const computedStickyHeader = computed((): string | null => {
            if (props.stickyHeader) {
                let baseOffset = 2;
                if (showTopRow.value) baseOffset += 40;
                return `calc(${props.stickyHeader} - ${baseOffset}px)`;
            }
            return null;
        });

        // if using sticky header, calculate the max height of the column selector dropdown so it'll allow scrolling
        const computedDropdownHeight = computed((): string | null => {
            if (props.stickyHeader) {
                let baseOffset = 62;
                if (showTopRow.value) baseOffset += 40;
                return `calc(${props.stickyHeader} - ${baseOffset}px)`;
            }
            return null;
        });

        const activeTable = sessionStorage.getItem(`${props.tableArray[0].key}-active`) || (tableOptions.value[0].value as string);
        const state = reactive<State>({
            tableKey: activeTable,
            search: '',
            searchNumFrom: null,
            searchNumTo: null,
            searchKey: '', // what key on the object we search
            tableName: props.tableArray.find((table) => table.key === activeTable)?.name || (tableOptions.value[0].text as string),
            dynamicColumns: [],
        });

        const selectedTable = computed(
            (): TableDefinition<any> => {
                const tableFound = props.tableArray.find((table) => table.key === state.tableKey);
                if (tableFound) return tableFound;
                throw new Error('cannot find table');
            },
        );

        const searchOptions = computed(
            (): Array<Option> => {
                const options: Array<Option> = [
                    {
                        value: '',
                        text: getTitleCaseTranslation('core.selectOptions.selectSearchOption'),
                    },
                ];
                for (const item of selectedTable.value.columnDefinition) {
                    if (item.searchable) {
                        options.push({
                            text: item.label ? item.label : formattedLabel(item.key as string),
                            value: item.key as string,
                        });
                    }
                }
                return options;
            },
        );

        const searchType = computed((): SearchType | null => {
            if (selectedTable.value && selectedTable.value.items && selectedTable.value.items.length > 0) {
                let itemTypeForSearch;

                if (Array.isArray(state.searchKey)) {
                    itemTypeForSearch = state.searchKey.reduce((prevVal, curVal) => (prevVal === null ? prevVal : prevVal[curVal]), selectedTable.value.items[0]);

                    itemTypeForSearch = typeof itemTypeForSearch;
                } else {
                    // find a populated value so we can get the type
                    const value = selectedTable.value.items.find((x) => x[state.searchKey] !== null);
                    itemTypeForSearch = typeof value[state.searchKey];
                }

                if (itemTypeForSearch === 'string' || itemTypeForSearch === 'boolean') return 'string';
                if (itemTypeForSearch === 'number') return 'number';
            }

            return null;
        });

        const filteredItems = computed(() => {
            // if user filtered out all of their columns, dont show any rows
            // if (userColumnKeys.value.length === 0) return [];

            let items = [...selectedTable.value.items];

            if (!`${state.searchKey}`.length) {
                return items;
            }

            if (state.searchKey.length > 0 && state.search.length > 0) {
                items = items.filter((item: unknown) => {
                    let itemValue;

                    if (Array.isArray(state.searchKey)) {
                        itemValue = state.searchKey.reduce((prevVal, curVal) => (prevVal === null ? prevVal : prevVal[curVal]), item);
                    } else {
                        // @ts-ignore
                        itemValue = item[state.searchKey];
                    }

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (itemValue === undefined || itemValue === null) {
                        return false;
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (
                        itemValue.toString()
                        && itemValue
                            .toString()
                            .toLowerCase()
                            .includes(state.search.toLowerCase())
                    ) {
                        return true;
                    }
                });
            }

            if (searchType.value === 'number' && state.searchNumFrom) {
                items = items.filter((item: unknown) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (item[state.searchKey] >= state.searchNumFrom) {
                        return true;
                    }
                });
            }

            if (searchType.value === 'number' && state.searchNumTo) {
                items = items.filter((item: unknown) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (item[state.searchKey] <= state.searchNumTo) {
                        return true;
                    }
                });
            }

            return items;
        });

        function resetSearch() {
            state.search = '';
            state.searchNumTo = null;
            state.searchNumFrom = null;
        }

        function onTableChange(tableKey: string) {
            hydrateState(tableKey);
            context.emit('onTableChange', tableKey);
        }

        function formattedLabel(label: string): string {
            const result = label.replace(/([A-Z])/g, ' $1');
            return result.charAt(0).toUpperCase() + result.slice(1);
        }

        // dynamic columns
        const allFields = computed(() => {
            const arr = [
                {
                    key: 'action',
                    label: '',
                    ignoreSort: true,
                    width: '60px',
                },
                ...selectedTable.value.columnDefinition,
            ];
            return arr;
        });

        function toggleColumn(value: boolean, key: string) {
            if (value && !state.dynamicColumns.find((x) => isEqual(key, x))) {
                state.dynamicColumns.push(key);
            }
            if (!value) {
                const index = state.dynamicColumns.findIndex((k) => isEqual(k, key));
                if (index > -1) state.dynamicColumns.splice(index, 1);
            }
            context.emit('columnChange', state.dynamicColumns);
        }

        function hydrateState(tableKey?: string): boolean {
            const key = tableKey ?? state?.tableKey;
            if (key) {
                if (props.tableArray.length > 1) {
                    sessionStorage.setItem(`${props.tableArray[0].key}-active`, key);
                }

                const data = sessionStorage.getItem(key);
                if (data) {
                    const res = JSON.parse(data);
                    state.search = res.search;
                    state.searchNumFrom = res.searchNumFrom;
                    state.searchNumTo = res.searchNumTo;
                    state.searchKey = res.searchKey;
                    state.dynamicColumns = res.dynamicColumns;
                    return true;
                }
            }
            return false;
        }

        function initColumns() {
            if (!hydrateState()) {
                state.dynamicColumns = selectedTable.value.columnDefinition.filter((columnDef) => !columnDef.hidden).map((columnDef) => columnDef.key as string);
            }
        }

        onBeforeMount(() => {
            initColumns();
        });

        watch(
            () => ({ ...state }),
            () => {
                if (state?.tableKey) {
                    sessionStorage.setItem(state.tableKey, JSON.stringify(state));
                }
            },
            { deep: true },
        );

        const filteredFields = computed(() => {
            if (state.dynamicColumns) {
                return allFields.value.filter((field) => state.dynamicColumns?.findIndex((x) => isEqual(x, field.key)) > -1 || field.key === 'action');
            }
            return allFields.value;
        });

        function onChangeSearch(val: string) {
            state.search = val;
        }

        function onChangeSearchNumFrom(val: number) {
            state.searchNumFrom = val;
        }

        function onChangeSearchNumTo(val: number) {
            state.searchNumTo = val;
        }

        return {
            state,
            tableOptions,
            selectedTable,
            filteredItems,
            searchOptions,
            resetSearch,
            onTableChange,
            showTopRow,
            searchType,
            filteredFields,
            toggleColumn,
            getLabelFromFormDefinition,
            hasActionSlotOrField,
            computedStickyHeader,
            computedDropdownHeight,
            slots,
            isEqual,
            hasSidePane,
            hasSidePaneHead,
            onChangeSearch,
            onChangeSearchNumFrom,
            onChangeSearchNumTo,
            props,
            getTitleCaseTranslation,
        };
    },
});
