import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75ffe158"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "orbis-card" }
const _hoisted_2 = { class: "orbis-card-header" }
const _hoisted_3 = { class: "header-center" }
const _hoisted_4 = { class: "header-right" }
const _hoisted_5 = { class: "orbis-card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        (_ctx.showBack)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "button button-go-back",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
            }, [
              _createVNode(_component_faicon, { icon: "arrow-left" })
            ]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header-center", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "header-right", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}