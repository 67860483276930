/* eslint-disable max-classes-per-file */
import * as Sentry from '@sentry/browser';
import { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { getTranslation } from '@/services/TranslationService';

export class DataListAccessResponseClass<T> {
    // capitalize prop since its used as a constructor
    private readonly TypeConstructor?: { new (obj: T): T };

    private axiosResponse!: AxiosResponse<DataAccessResponse<Array<T>>>;

    constructor(response: AxiosResponse<DataAccessResponse<Array<T>>>, typeConstructor?: { new (obj: T): T }) {
        this.axiosResponse = response;
        this.TypeConstructor = typeConstructor;
    }

    get response(): DataAccessResponse<Array<T>> {
        if (!this.axiosResponse || !this.axiosResponse.data) return { success: false, message: 'Server Error', data: [] };
        if (!this.axiosResponse.data.success) return { success: false, message: this.axiosResponse.data.message, data: [] };

        if (this.TypeConstructor) {
            try {
                const data: Array<T> = this.axiosResponse.data.data.map((x) => new this.TypeConstructor!(x));
                return { success: true, data, message: '' };
            } catch (err) {
                console.log(err);
                Sentry.captureException(err);
                return { success: false, data: [], message: 'Malformed Data' };
            }
        }

        return this.axiosResponse.data;
    }
}

export class DataAccessResponseClass<T> {
    // capitalize prop since its used as a constructor
    private readonly TypeConstructor?: { new (obj: T): T };

    private axiosResponse!: AxiosResponse<DataAccessResponse<T>>;

    private errorList?: {};

    constructor(response: AxiosResponse<DataAccessResponse<T>>, typeConstructor?: { new (obj: T): T }) {
        this.axiosResponse = response;
        this.errorList = response?.data?.errorList;
        this.TypeConstructor = typeConstructor;
    }

    get response(): DataAccessResponse<T> {
        if (!this.axiosResponse) {
            return {
                success: false,
                message: getTranslation('core.common.noDataInResponse'),
                data: {} as T,
                errorList: this.errorList,
            };
        }
        if (!this.axiosResponse.data) {
            return {
                success: false,
                // @ts-ignore - When there is an error, we are returing an ApiResponse problem instead of an axios response.
                message: this.axiosResponse.message,
                data: {} as T,
                errorList: this.errorList,
            };
        }
        if (!this.axiosResponse.data.success) {
            return {
                success: false,
                message: this.axiosResponse.data.message,
                data: {} as T,
                errorList: this.errorList,
            };
        }

        if (this.TypeConstructor && this.axiosResponse.data.data) {
            try {
                const data = new this.TypeConstructor(this.axiosResponse.data.data);
                return { success: true, data, message: '' };
            } catch (err) {
                console.log(err);
                Sentry.captureException(err);
                return { success: false, data: {} as T, message: 'Malformed Data' };
            }
        }

        return this.axiosResponse.data;
    }
}
