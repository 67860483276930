import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b9a7284"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calculator" }
const _hoisted_2 = { class: "buttons" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.display) = $event)),
      type: "text",
      class: "display",
      disabled: ""
    }, null, 512), [
      [_vModelText, $setup.display]
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.buttons, (btn) => {
        return (_openBlock(), _createElementBlock("button", {
          key: btn.value,
          class: _normalizeClass(`button button_${btn.type || 'default'}`),
          onClick: ($event: any) => ($setup.updateDisplay(btn.value))
        }, _toDisplayString(btn.value), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}