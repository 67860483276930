import { AxiosError } from 'axios';
import ApiErrorResponseHandlerFactory from '../ResponseStrategy/ApiErrorResponseHandlerFactory';
import ApiErrorWrapper from './ApiErrorWrapper';

const handleErrors = (error: AxiosError) => {
    const factory = new ApiErrorResponseHandlerFactory(new ApiErrorWrapper(error));
    return factory.GetErrorResponder().handleError();
};

export default handleErrors;
