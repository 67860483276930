
import {
    defineComponent, watch, ref, PropType,
} from 'vue';
import Datepicker from 'vue3-datepicker';

export default defineComponent({
    name: 'date-input',
    components: { Datepicker },
    props: {
        modelValue: Date,
        cols: { type: String, default: '' },
        lg: { type: String, default: '' },
        md: { type: String, default: '' },
        sm: { type: String, default: '' },
        xs: { type: String, default: '' },
        label: { type: String, default: '' },
        disabled: Boolean,
        moreInfo: { type: String, default: '' },
        upperLimit: Date,
        lowerLimit: Date,
        clearable: Boolean,
        disabledDates: { type: Array as PropType<Array<Date>>, default: () => [] },
        inputFormat: { type: String, default: 'MM-dd-yyyy' },
        error: { type: String, default: '' },
        defaultTime: {
            type: String,
            validator: (value: string): boolean => {
                if (value) {
                    const timeRegex = /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/;
                    return timeRegex.test(value);
                }
                return true;
            },
            default: '',
        },
        startingView: {
            type: String as PropType<'time' | 'day' | 'month' | 'year'>,
            default: 'day',
        },
        minimumView: {
            type: String as PropType<'time' | 'day' | 'month' | 'year'>,
            default: 'day',
        },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const internalDate = ref(props.modelValue ? props.modelValue : null);

        watch(
            () => props.modelValue,
            (newValue) => {
                internalDate.value = newValue || null;
            },
        );

        function addDefaultTimeToDate(dateValue: Date) {
            if (props.defaultTime) {
                const timePieces = props.defaultTime.split(':');
                if (timePieces.length === 3) {
                    dateValue.setHours(Number(timePieces[0]), Number(timePieces[1]), Number(timePieces[2]));
                }
            }
        }

        function handleChange() {
            if (internalDate.value) {
                addDefaultTimeToDate(internalDate.value);
            }

            context.emit('update:modelValue', internalDate.value);
        }

        function clearDate() {
            internalDate.value = null;
            context.emit('update:modelValue', null);
        }

        return {
            internalDate,
            handleChange,
            clearDate,
        };
    },
});
