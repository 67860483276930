import InventoryCategory from '@/domain/InventoryCategory';
import ItemTypeInventoryCategory from '@/domain/ItemTypeInventoryCategory';
import Repair from '@/domain/Repair/Repair';
import Location from './Location';

export default class ItemType {
    constructor(init?: Partial<ItemType>) {
        Object.assign(this, init);
        this.repairs = init?.repairs?.map((repair) => new Repair(repair)) ?? [];
        this.sortInventoryCategories = init?.itemTypeInventoryCategories?.map((i) => new InventoryCategory(i.inventoryCategory)) || [];
    }

    public id!: number;

    public description!: string;

    public sortInventoryCategories: Array<InventoryCategory> = [];

    public itemTypeInventoryCategories: Array<ItemTypeInventoryCategory> = [];

    public overridePutAwayLocationId?: number;

    public overridePutAwayLocation?: Location;

    public repairable: boolean = false;

    public repairs: Repair[] = [];

    public deleted!: boolean;
}
