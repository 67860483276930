import { reactive } from 'vue';
import { store } from '@/decorators/store';
import ItemType from '@/domain/ItemType';
import LocationType from '@/domain/LocationType';
import LocationTypeApiService from '../../services/api/LocationTypeApiService';
import FreightCode from '@/domain/FreightCode';
import EquipmentType from '@/domain/EquipmentType';

export type State = {
    itemType: Array<ItemType>;
    locationType: Array<LocationType>;
    freightCode: Array<FreightCode>;
    equipmentType: Array<EquipmentType>;
};

@store
export default class ConfigStore {
    protected locationTypeApiService: LocationTypeApiService;

    constructor() {
        this.locationTypeApiService = new LocationTypeApiService();
    }

    // state
    private state = reactive<State>({
        itemType: [],
        locationType: [],
        freightCode: [],
        equipmentType: [],
    });

    public initItemTypes(value: Array<ItemType>) {
        this.state.itemType = value;
    }

    get itemTypes(): Array<ItemType> {
        return this.state.itemType;
    }

    public addItemType(value: ItemType) {
        this.state.itemType.unshift(value);
    }

    public deleteItemType(value: ItemType) {
        const index = this.state.itemType.findIndex((data) => data.id === value.id);
        this.state.itemType.splice(index, 1);
    }

    public updateItemType(value: ItemType) {
        this.state.itemType = this.state.itemType.map((data) => {
            if (data.id === value.id) {
                return value;
            }
            return data;
        });
    }

    public initLocationTypes(value: Array<LocationType>) {
        this.state.locationType = value;
    }

    get locationTypes(): Array<LocationType> {
        return this.state.locationType;
    }

    public addLocationType(value: LocationType) {
        this.state.locationType.unshift(value);
    }

    public deleteLocationType(value: LocationType) {
        const index = this.state.locationType.findIndex((data) => data.id === value.id);
        this.state.locationType.splice(index, 1);
    }

    public updateLocationType(value: LocationType) {
        this.state.locationType = this.state.locationType.map((data) => {
            if (data.id === value.id) {
                return value;
            }
            return data;
        });
    }

    public initFreightCodes(value: Array<FreightCode>) {
        this.state.freightCode = value;
    }

    get freightCodes(): Array<FreightCode> {
        return this.state.freightCode;
    }

    public addFreightCode(value: FreightCode) {
        this.state.freightCode.unshift(value);
    }

    public deleteFreightCode(value: FreightCode) {
        const index = this.state.freightCode.findIndex((data) => data.id === value.id);
        this.state.freightCode.splice(index, 1);
    }

    public updateFreightCode(value: FreightCode) {
        this.state.freightCode = this.state.freightCode.map((data) => (data.id === value.id ? value : data));
    }

    public initEquipmentTypes(value: Array<EquipmentType>) {
        this.state.equipmentType = value;
    }

    get equipmentTypes(): Array<EquipmentType> {
        return this.state.equipmentType;
    }

    public addEquipmentType(value: EquipmentType) {
        this.state.equipmentType.unshift(value);
    }

    public deleteEquipmentType(value: EquipmentType) {
        const index = this.state.equipmentType.findIndex((data) => data.id === value.id);
        this.state.equipmentType.splice(index, 1);
    }

    public updateEquipmentType(value: EquipmentType) {
        this.state.equipmentType = this.state.equipmentType.map((data) => (data.id === value.id ? value : data));
    }
}
