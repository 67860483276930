import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import Carrier from '@/domain/Carrier';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class CarrierApiService {
    public async getAllCarriers(): Promise<DataAccessResponse<Array<Carrier>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Carrier>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/carrier`,
        });
        return new DataListAccessResponseClass<Carrier>(response, Carrier).response;
    }

    public async addNewCarrier(carrier: Carrier): Promise<DataAccessResponse<Number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/carrier`,
            data: carrier,
        });

        return new DataAccessResponseClass<Number>(response, Number).response;
    }

    public async updateCarrier(carrier: Carrier): Promise<DataAccessResponse<Carrier>> {
        const response: AxiosResponse<DataAccessResponse<Carrier>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/carrier`,
            data: carrier,
        });

        return new DataAccessResponseClass<Carrier>(response, Carrier).response;
    }

    public async deleteCarrier(id: number): Promise<DataAccessResponse<Number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/carrier/${id}`,
        });

        return new DataAccessResponseClass<Number>(response, Number).response;
    }
}
