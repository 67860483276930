import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e24683ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "company-name" }
const _hoisted_5 = {
  key: 0,
  style: {"margin-right":"30px"}
}
const _hoisted_6 = { class: "user-info-display" }
const _hoisted_7 = { class: "display-username" }
const _hoisted_8 = { class: "nowrap-with-ellipse" }
const _hoisted_9 = {
  key: 0,
  class: "display-location"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = {
  href: "/terms-of-service",
  target: "_blank"
}
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = {
  href: "/privacy-policy",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_navbar_brand = _resolveComponent("b-navbar-brand")!
  const _component_b_navbar_nav = _resolveComponent("b-navbar-nav")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_divider = _resolveComponent("b-divider")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_navbar = _resolveComponent("b-navbar")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_Calculator = _resolveComponent("Calculator")!

  return (_ctx.authStore.isAuthenticated && _ctx.authStore.ready)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.state.routesHideNav.includes(_ctx.currentRoute))
          ? (_openBlock(), _createBlock(_component_b_navbar, {
              key: 0,
              class: "orbis-nav",
              toggleable: "lg"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_navbar_nav, { style: {"display":"flex","flex-direction":"row"} }, {
                  default: _withCtx(() => [
                    (_ctx.state.showSideBarButton)
                      ? (_openBlock(), _createBlock(_component_faicon, {
                          key: 0,
                          icon: "bars",
                          class: "btn-toggle-nav",
                          style: {"margin":"auto 25px auto 0"},
                          onClick: _ctx.toggleNav
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.profileStore.companyImg)
                      ? (_openBlock(), _createBlock(_component_b_navbar_brand, {
                          key: 1,
                          onClick: _ctx.openCore
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createElementVNode("img", {
                                src: _ctx.smartTrakLogo,
                                class: "application-logo"
                              }, null, 8, _hoisted_3),
                              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.metaDataStore.companyDisplayName), 1)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_navbar_nav, {
                  class: "ml-auto",
                  style: {"display":"flex","flex-direction":"row"}
                }, {
                  default: _withCtx(() => [
                    (_ctx.profileStore.isUserLocationSet)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          (_ctx.Permissions.ADMINISTRATION.canAccessModule())
                            ? (_openBlock(), _createBlock(_component_b_button, {
                                key: 0,
                                link: "white",
                                class: _normalizeClass({ 'active-route': _ctx.isMasterData }),
                                onClick: _ctx.openMasterData
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.nav.adminAndPlanning')), 1)
                                ]),
                                _: 1
                              }, 8, ["class", "onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.Permissions.PARTNER_ENGAGEMENT.canAccessModule())
                            ? (_openBlock(), _createBlock(_component_b_button, {
                                key: 1,
                                link: "white",
                                class: _normalizeClass({ 'active-route': _ctx.isPEM }),
                                onClick: _ctx.openPartnerEngagementModule
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.nav.pem')), 1)
                                ]),
                                _: 1
                              }, 8, ["class", "onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.Permissions.FLOORTRAK.canAccessModule())
                            ? (_openBlock(), _createBlock(_component_b_button, {
                                key: 2,
                                link: "white",
                                class: _normalizeClass({ 'active-route': _ctx.isFloorTrak }),
                                onClick: _ctx.openFloorTrak
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getTranslation('core.nav.floortrak')), 1)
                                ]),
                                _: 1
                              }, 8, ["class", "onClick"]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.isMobile)
                      ? (_openBlock(), _createBlock(_component_faicon, {
                          key: 1,
                          class: "navbar-icon-btn",
                          icon: _ctx.state.screenIcon,
                          onClick: _ctx.toggleFullscreen
                        }, null, 8, ["icon", "onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_b_button, {
                      link: "white",
                      title: _ctx.getTitleCaseTranslation('core.common.calculator'),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCalculator(true)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_faicon, {
                          icon: "calculator",
                          style: {"font-size":"24px","margin":"auto 0px 0px 0px"}
                        })
                      ]),
                      _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_b_button, {
                      link: "white",
                      onClick: _ctx.help
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_faicon, {
                          icon: "question-circle",
                          style: {"font-size":"24px","margin":"auto 12px 0px 0px"}
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_b_dropdown, {
                      class: "home-nav-dropdown",
                      left: "",
                      "close-on-click": ""
                    }, {
                      "button-content": _withCtx(() => [
                        _createVNode(_component_faicon, {
                          icon: "user-circle",
                          style: {"font-size":"26px"}
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("h6", _hoisted_7, _toDisplayString(_ctx.profileStore.fullName), 1),
                          _createElementVNode("div", _hoisted_8, [
                            (_ctx.profileStore.isUserLocationSet)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.profileStore.userLocation.name), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createVNode(_component_b_divider)
                        ]),
                        (_ctx.numberOfLocations > 1)
                          ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                              key: 0,
                              variant: "link",
                              onClick: _ctx.openChooseLocation
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.changeLocation')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_b_dropdown_item, {
                          variant: "link",
                          onClick: _ctx.openChooseLanguage
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.changeLanguage')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_b_dropdown_item, {
                          variant: "outline-danger",
                          onClick: _ctx.oktaLogout
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.logOff')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_b_divider),
                        _createVNode(_component_b_dropdown_item, { onClick: _ctx.showAbout }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.about')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_b_modal, {
          modelValue: _ctx.state.showAbout,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.showAbout) = $event)),
          centered: "",
          size: "md",
          title: `${_ctx.getTitleCaseTranslation('core.common.about')} ${_ctx.APPLICATION_DISPLAY_NAME}`,
          "hide-footer": ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.version')) + ": " + _toDisplayString(_ctx.state.appVersion), 1),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.termsOfService')), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("a", {
                  href: _ctx.releaseNotesLink,
                  target: "_blank"
                }, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.releaseNotes')), 9, _hoisted_15)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("a", _hoisted_17, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.privacyPolicy')), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "title"]),
        _createVNode(_component_b_modal, {
          modelValue: _ctx.state.showChooseLanguage,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.showChooseLanguage) = $event)),
          centered: "",
          size: "sm",
          title: _ctx.getTitleCaseTranslation('core.common.chooseLanguage'),
          "hide-footer": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_select_input, {
              model: _ctx.profileStore.userLanguage?.id,
              options: _ctx.languageStore.languages,
              text: "name",
              value: "id",
              class: "form-col",
              onChange: _ctx.setLanguage
            }, null, 8, ["model", "options", "onChange"])
          ]),
          _: 1
        }, 8, ["modelValue", "title"]),
        _createVNode(_component_b_modal, {
          modelValue: _ctx.state.showCalculator,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.showCalculator) = $event)),
          centered: "",
          size: "md",
          "hide-footer": "",
          onHidden: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCalculator(false)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Calculator)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}