import { reactive } from 'vue';
import { CreateStore } from '@/components/sidebar/store/store-decorators';

type SidebarState = {
    sideBarVisible: boolean;
};

export class SidebarStore {
    private state = reactive<SidebarState>({
        sideBarVisible: false,
    });

    private props = {
        sideBarWidth: 245,
    };

    get sideBarVisible(): boolean {
        return this.state.sideBarVisible;
    }

    set sideBarVisible(value: boolean) {
        this.state.sideBarVisible = value;
    }

    get sideBarWidth(): number {
        return this.props.sideBarWidth;
    }

    public toggleSidebar(): void {
        this.state.sideBarVisible = !this.state.sideBarVisible;
    }
}

const sidebarStore = new CreateStore(new SidebarStore());
export default sidebarStore;
