export default class SortStationActivity {
    public id: number;
    public sortStationId: number;
    public userId: number;
    public userFullName: string;
    public startedOn: Date;
    public stoppedOn?: Date;

    constructor(init: SortStationActivity) {
        this.id = init.id;
        this.sortStationId = init.sortStationId;
        this.userId = init.userId;
        this.userFullName = init.userFullName;
        this.startedOn = init.startedOn;
        this.stoppedOn = init.stoppedOn;
    }
}
