import Theme from '@/domain/Theme';
import ThemeApiService from '@/services/api/ThemeApiService';

export default class ThemeService {
    private themeApiService: ThemeApiService;

    constructor() {
        this.themeApiService = new ThemeApiService();
    }

    public async getTheme(): Promise<Theme> {
        return (await this.themeApiService.getTheme()).data;
    }
}
