import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-864e4842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.menuClass)
  }, [
    _createElementVNode("div", {
      ref: "element",
      class: _normalizeClass(['dropdown-toggle', { 'hide-caret': _ctx.noCaret }, { 'btn-disabled': _ctx.disabled }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
      onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseOver && _ctx.handleMouseOver(...args)))
    }, [
      _renderSlot(_ctx.$slots, "button-content", {}, () => [
        (_ctx.isButton)
          ? (_openBlock(), _createBlock(_component_b_button, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                _createVNode(_component_faicon, {
                  icon: _ctx.dropUp ? 'chevron-up' : 'chevron-down'
                }, null, 8, ["icon"])
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title), 1))
      ], true)
    ], 34),
    (_ctx.state.show)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: "dropdown-menu",
          style: _normalizeStyle(_ctx.menuStyle),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClickDropdownItem && _ctx.handleClickDropdownItem(...args)))
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}