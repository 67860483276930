import axios, { AxiosResponse } from 'axios';
import ItemType from '@/domain/ItemType';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class ItemTypeApiService {
    public async getItemTypes(): Promise<DataAccessResponse<Array<ItemType>>> {
        const response: AxiosResponse<DataAccessResponse<Array<ItemType>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/ItemTypes`,
        });

        return new DataListAccessResponseClass<ItemType>(response, ItemType).response;
    }

    public async addNewItemType(itemType: ItemType): Promise<DataAccessResponse<ItemType>> {
        const response: AxiosResponse<DataAccessResponse<ItemType>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/ItemTypes`,
            data: itemType,
        });

        return new DataAccessResponseClass<ItemType>(response, ItemType).response;
    }

    public async updateItemType(itemType: ItemType): Promise<DataAccessResponse<ItemType>> {
        const response: AxiosResponse<DataAccessResponse<ItemType>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/ItemTypes`,
            data: itemType,
        });

        return new DataAccessResponseClass<ItemType>(response, ItemType).response;
    }

    public async deleteItemType(itemType: ItemType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/ItemTypes`,
            data: itemType,
        });

        return new DataAccessResponseClass<number>(response).response;
    }
}
