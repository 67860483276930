import axios, { AxiosResponse } from 'axios';
import ApplicationUser from '@/domain/ApplicationUser';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class UserApiService {
    public async getUserProfileByUsername(username: string): Promise<DataAccessResponse<ApplicationUser>> {
        const response: AxiosResponse<DataAccessResponse<ApplicationUser>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/users/${username}`,
        });
        return new DataAccessResponseClass<ApplicationUser>(response, ApplicationUser).response;
    }

    public async getAllUsers(): Promise<ApplicationUser[]> {
        const response: AxiosResponse<DataAccessResponse<ApplicationUser[]>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/users`,
        });
        return response.data.data;
    }

    public async createNewSSOUser(user: ApplicationUser): Promise<DataAccessResponse<ApplicationUser>> {
        const response: AxiosResponse<DataAccessResponse<ApplicationUser>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/users/federated`,
            data: user,
        });
        return new DataAccessResponseClass<ApplicationUser>(response, ApplicationUser).response;
    }

    public async createNewActiveDirectoryUser(user: ApplicationUser): Promise<DataAccessResponse<ApplicationUser>> {
        const response: AxiosResponse<DataAccessResponse<ApplicationUser>> = await axios({
            data: user,
            method: 'POST',
            url: `${API_SERVICE_URL}/users/active-directory`,
        });
        return new DataAccessResponseClass<ApplicationUser>(response, ApplicationUser).response;
    }

    public async createNewLocalUser(user: ApplicationUser): Promise<DataAccessResponse<ApplicationUser>> {
        const response: AxiosResponse<DataAccessResponse<ApplicationUser>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/users/local`,
            data: user,
        });

        return new DataAccessResponseClass<ApplicationUser>(response, ApplicationUser).response;
    }

    public async linkAppUserToAuthServer(user: ApplicationUser): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/users/link-to-auth-server`,
            data: user,
        });
        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async updateUser(user: ApplicationUser): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/users`,
            data: user,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async deleteUser(userId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/users/delete/${userId}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async getTableauToken(): Promise<DataAccessResponse<string>> {
        const response: AxiosResponse<DataAccessResponse<string>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/users/tableau-token`,
        });

        return new DataAccessResponseClass<string>(response).response;
    }

    public async resetPassword(userName: string): Promise<DataAccessResponse<string>> {
        const response: AxiosResponse<DataAccessResponse<string>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/users/reset-password/${userName}`,
        });

        return new DataAccessResponseClass<string>(response).response;
    }
}
