export default class ShippingLoadType {
    constructor(init?: Partial<ShippingLoadType>) {
        Object.assign(this, init);
    }

    public id!: number;

    public description: string = '';

    public allowMaxLTLDayRange!: boolean;

    public toString() {
        return this.description;
    }
}
