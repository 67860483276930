import Permissions from '@/services/permissions/Permissions';
import MasterDataStartupService from '../services/MasterDataStartupService';
import masterDataStore from '../store/MasterDataStore';

export default async function onMasterDataEnter() {
    if (Permissions.ADMINISTRATION.canAccessModule()) {
        if (!masterDataStore.isInitialized()) {
            const masterDataStartupService = new MasterDataStartupService();
            await masterDataStartupService.start();
        }
        return true;
    }
    return false;
}
