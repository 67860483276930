

import { defineComponent, ref } from 'vue';

export type Option = {
    text: string;
    value: string | number | null; // casing is manipulated so mismatched casings match
    disabled?: boolean;
}

export default defineComponent({
    name: 'select-input',
    components: {},
    emits: ['change', 'update:model'],
    props: {
        options: {
            type: Array as () => Array<any>,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        text: {
            type: [String, Array as () => Array<string>],
            required: true,
        },
        model: {
            type: [String, Number],
        },
        cols: String,
        lg: String,
        md: String,
        sm: String,
        xs: String,
        label: String,
        noPadding: Boolean,
        loading: Boolean,
        moreInfo: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        noOptionsText: {
            type: String,
            required: false,
        },
        readonly: Boolean,
        error: String, // concepting this out, but this will replace the label with red text
        allowEmptyOption: Boolean,
    },
    setup(props, context) {
        const componentModel = ref<string | number | null>(null);
        if (props.model !== undefined && props.model !== null) {
            componentModel.value = props.model;
            if (typeof props.model === 'string') {
                componentModel.value = (componentModel.value as string).trim();
            }
        }

        const componentOptions = initComponentOptions();

        function initComponentOptions(): Array<Option> {
            const array: Array<Option> = [];
            if (props.allowEmptyOption) {
                array.push({
                    text: '',
                    value: null,
                    disabled: false,
                });
            }
            for (const propOption of props.options) {
                array.push({
                    text: createDisplayText(propOption),
                    value: propOption[props.value],
                    disabled: propOption.disabled,
                });
            }
            return array;
        }

        function createDisplayText(propOption: Array<Option>): string {
            if (Array.isArray(props.text)) {
                let displayText = '';
                const { length } = props.text;
                for (let i = 0; i < length; i++) {
                    if (i === length - 1) {
                        // @ts-ignore
                        displayText = displayText.concat(propOption[props.text[i]]);
                    } else {
                        // @ts-ignore
                        displayText = displayText.concat(`${propOption[props.text[i]]} | `);
                    }
                }
                return displayText;
            }
            // @ts-ignore
            return propOption[props.text];
        }

        function emitChange(value: any) {
            context.emit('update:model', value); // updates value
            context.emit('change', getOptionFromValue(value)); // emits entire option to @change
        }

        function getOptionFromValue(value: any): any {
            // if object
            for (const option of props.options) {
                if (option[props.value] === value) {
                    return option;
                }
            }
            // if string
            return value;
        }

        function isEmpty(): boolean {
            return props.options.length === 0;
        }

        return {
            componentOptions,
            componentModel,
            emitChange,
            isEmpty,
        };
    },
});

