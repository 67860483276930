import Location from './Location';
import Role from '@/domain/Role'; 
import Language from './Language';
import { normalizeDate } from '@/functions/date';

export default class ApplicationUser {
    public id = 0;

    public firstName?: string;

    public lastName?: string;

    public authServerId?: string;

    public username = '';

    public fullName?: string;

    public email?: string;

    public status?: string;

    public userRoles: Array<Role> = new Array<Role>();

    public locations: Array<Location> = new Array<Location>();

    public language?: Language;

    public password?: string;

    public loginType?: 'local' | 'sso' | 'active-directory';

    public phoneNumber?: string;

    public samAccountName?: string;

    public forkliftCertified: boolean = false;

    public certExpirationDate?: Date;

    constructor(init?: ApplicationUser) {
        if (init) {
            this.id = init.id;
            this.firstName = init.firstName;
            this.lastName = init.lastName;
            this.authServerId = init.authServerId;
            this.username = init.username;
            this.fullName = init.fullName;
            this.email = init.email;
            this.status = init.status;
            this.locations = init.locations.map((loc) => new Location(loc));
            this.userRoles = init.userRoles;
            this.password = init.password;
            this.loginType = init.loginType;
            this.language = init.language;
            this.phoneNumber = init.phoneNumber;
            this.samAccountName = init.samAccountName;
            this.forkliftCertified = init.forkliftCertified;
            this.certExpirationDate = normalizeDate(init.certExpirationDate);
        }
    }

    get displayName(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        return this.username;
    }
}
