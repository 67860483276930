import ApiErrorResponseStrategy from './ApiErrorResponseStrategy';

export default class BadRequestApiResponseStrategy extends ApiErrorResponseStrategy {
    // in some cases, invalid inputs will be caught by .net's model binder and won't get past the controller.
    // i.e. you pass in a value larger than int max value for an int query param - this results in a 400 resposne code.
    // in that case, the Axios error response data will have an object containing lists of failed inputs called 'errors'
    override handleError(): void {
        let msg = '';

        if (this.error.responseBody.data?.errors) {
            msg = this.error.responseBody.data?.errors;
        } else {
            msg = this.error.responseBody.data?.message || this.error.responseMessage;
        }

        this.notification().showError(msg);
    }
}
