import CarrierApiService from '@/modules/master-data/services/api/CarrierApiService';
import Carrier from '@/domain/Carrier';
import { useNotification } from '@/composable/useNotifications';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class CarrierService {
    private carrierApiService: CarrierApiService;

    private notification = useNotification();

    constructor() {
        this.carrierApiService = new CarrierApiService();
    }

    public async getAllCarriers(): Promise<{ carriers: Array<Carrier>; success: boolean }> {
        const response = await this.carrierApiService.getAllCarriers();
        if (!response.success) {
            this.notification.showError(response.message);
        }
        return { carriers: response.data, success: response.success };
    }

    public async updateCarrier(carrier: Carrier): Promise<boolean> {
        const response = await this.carrierApiService.updateCarrier(carrier);
        if (response.success) {
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.updated')} ${carrier.name}`);
        }
        return response.success;
    }

    public async addNewCarrier(carrier: Carrier): Promise<Carrier | null> {
        const response = await this.carrierApiService.addNewCarrier(carrier);
        if (!response.success) {
            return null;
        }
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.added')} ${carrier.name}`);
        return new Carrier({
            ...carrier,
            id: response.data as number,
        });
    }

    public async deleteCarrier(carrier: Carrier): Promise<boolean> {
        const response = await this.carrierApiService.deleteCarrier(carrier.id);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.deleted')} ${carrier.name}`);
        }
        return response.success;
    }
}
