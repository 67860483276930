import axios, { AxiosResponse } from 'axios';
import TrailerType from '@/domain/TrailerType';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class TrailerTypeApiService {
    public async getTrailerTypes(): Promise<DataAccessResponse<Array<TrailerType>>> {
        const response: AxiosResponse<DataAccessResponse<Array<TrailerType>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/TrailerTypes`,
        });

        return new DataListAccessResponseClass<TrailerType>(response, TrailerType).response;
    }

    public async getPreferredTrailerTypeForLocation(locationId: number): Promise<DataAccessResponse<TrailerType>> {
        const response: AxiosResponse<DataAccessResponse<TrailerType>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/TrailerTypes/preferred/${locationId}`,
        });

        return new DataAccessResponseClass<TrailerType>(response, TrailerType).response;
    }

    public async getPreferredTrailerTypeForRoute(fromLocationId: number, toLocationId: number): Promise<DataAccessResponse<TrailerType>> {
        const response: AxiosResponse<DataAccessResponse<TrailerType>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/TrailerTypes/preferred/from/${fromLocationId}/to/${toLocationId}`,
        });

        return new DataAccessResponseClass<TrailerType>(response, TrailerType).response;
    }

    public async addNewTrailerType(locationType: TrailerType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/TrailerTypes`,
            data: locationType,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateTrailerType(locationType: TrailerType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/TrailerTypes`,
            data: locationType,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async deleteTrailerType(locationType: TrailerType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/TrailerTypes`,
            data: locationType,
        });

        return new DataAccessResponseClass<number>(response).response;
    }
}
