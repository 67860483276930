import LocationService from '@/services/LocationService';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { useLoading } from '@/composable/useLoading';
import LocationTypeApiService from '@/modules/master-data/services/api/LocationTypeApiService';
import ItemTypeApiService from '@/services/api/ItemTypeApiService';
import FreightCodeApiService from '@/modules/master-data/services/api/FreightCodeApiService';
import EquipmentTypeApiService from '@/services/api/EquipmentTypeApiService';

export default class MasterDataStartupService {
    private store = masterDataStore.getInstance();

    private loading = useLoading();

    private locationService: LocationService;

    private locationTypeApiService: LocationTypeApiService;

    private itemTypeApiService: ItemTypeApiService;

    private freightCodeApiService: FreightCodeApiService;

    private equipmentTypeApiService: EquipmentTypeApiService;

    constructor() {
        this.locationService = new LocationService();
        this.locationTypeApiService = new LocationTypeApiService();
        this.itemTypeApiService = new ItemTypeApiService();
        this.freightCodeApiService = new FreightCodeApiService();
        this.equipmentTypeApiService = new EquipmentTypeApiService();
    }

    public async start() {
        this.loading.show();

        try {
            const [locationListResult, locationTypeResult, itemTypeResult, freightCodeResult, equipmentTypeResult] = await Promise.all([
                this.locationService.getAllLocations(),
                this.locationTypeApiService.getLocationTypes(),
                this.itemTypeApiService.getItemTypes(),
                this.freightCodeApiService.getFreightCodes(),
                this.equipmentTypeApiService.getEquipmentTypes(),
            ]);

            if (locationListResult.success) {
                this.store.locationStore.initLocations(locationListResult.locations);
            }

            if (locationTypeResult.success) {
                this.store.configStore.initLocationTypes(locationTypeResult.data);
            }

            if (itemTypeResult.success) {
                this.store.configStore.initItemTypes(itemTypeResult.data);
            }

            if (freightCodeResult.success) {
                this.store.configStore.initFreightCodes(freightCodeResult.data);
            }

            if (equipmentTypeResult.success) {
                this.store.configStore.initEquipmentTypes(equipmentTypeResult.data);
            }
        } finally {
            this.loading.hide();
        }
    }
}
