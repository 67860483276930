import axios, { AxiosResponse } from 'axios';
import EquipmentType from '@/domain/EquipmentType';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import FreightCode from '@/domain/FreightCode';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';

export default class EquipmentTypeApiService {
    public async getEquipmentTypes(): Promise<DataAccessResponse<Array<EquipmentType>>> {
        const response: AxiosResponse<DataAccessResponse<Array<FreightCode>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/EquipmentTypes`,
        });
        return new DataListAccessResponseClass<EquipmentType>(response, EquipmentType).response;
    }

    public async addNewEquipmentType(equipmentType: EquipmentType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/EquipmentTypes`,
            data: equipmentType,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateEquipmentType(equipmentType: EquipmentType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/EquipmentTypes`,
            data: equipmentType,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async deleteEquipmentType(equipmentType: EquipmentType): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/EquipmentTypes/${equipmentType.id}`,
        });
        return new DataAccessResponseClass<number>(response).response;
    }
}
