import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19faac40"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = {
  key: 0,
  class: "modal-header"
}
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = {
  key: 0,
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  "aria-label": "Close"
}
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "modal-footer"
}
const _hoisted_9 = {
  ref: "focusHere",
  type: "text",
  class: "hidden-focus"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_spinner = _resolveComponent("b-spinner")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      id: _ctx.componentId,
      ref: "element",
      class: _normalizeClass(["modal", _ctx.classes]),
      style: _normalizeStyle(_ctx.styles),
      "data-bs-backdrop": "static",
      tabindex: "-1"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-dialog", _ctx.modalDialogClasses]),
        style: _normalizeStyle({ ..._ctx.modalDialogStyle })
      }, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.hideHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h5", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "title", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ], true)
                ]),
                (!_ctx.hideHeaderClose)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_5))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.modelValue)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]))
              : _createCommentVNode("", true)
          ]),
          (!_ctx.hideFooter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "footer", {}, () => [
                  _renderSlot(_ctx.$slots, "btn-cancel", {}, () => [
                    _createVNode(_component_b_button, {
                      variant: "light",
                      "data-bs-dismiss": "modal",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel'))),
                      onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('cancel')), ["enter"]))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.cancelButton), 1)
                      ]),
                      _: 1
                    })
                  ], true),
                  _renderSlot(_ctx.$slots, "btn-ok", {}, () => [
                    _createVNode(_component_b_button, {
                      variant: "primary",
                      "data-bs-dismiss": "modal",
                      disabled: _ctx.state.clickingOk,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => {
                                        _ctx.state.clickingOk = true;
                                        _ctx.$emit('ok');
                                    }),
                      onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => {
                                        _ctx.state.clickingOk = true;
                                        _ctx.$emit('ok');
                                    }, ["enter"]))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.okButton) + " ", 1),
                        (_ctx.state.clickingOk)
                          ? (_openBlock(), _createBlock(_component_b_spinner, {
                              key: 0,
                              small: ""
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ], true)
                ], true)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 6),
      _createElementVNode("input", _hoisted_9, null, 512)
    ], 14, _hoisted_1)
  ]))
}