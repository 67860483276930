import { reactive } from 'vue';
import { store } from '@/decorators/store';
import Location from '@/domain/Location';

export type State = {
    locations: Array<Location>;
    loaded: boolean;
};

@store
export default class LocationStore {
    // state
    private state = reactive<State>({
        locations: [],
        loaded: false,
    });

    public initLocations(value: Array<Location>) {
        this.state.locations = value;
        this.state.loaded = true;
    }

    get loaded(): boolean {
        return this.state.loaded;
    }

    get locations(): Array<Location> {
        return this.state.locations;
    }

    public addLocation(location: Location) {
        this.validateLocationId(location);
        this.state.locations.unshift(location);
    }

    public updateLocation(location: Location) {
        this.validateLocationId(location);
        const index = this.locations.findIndex((loc) => loc.id === location.id);
        this.locations[index] = location;
    }

    public deleteLocation(id: number) {
        const index = this.state.locations.findIndex((data) => data.id === id);
        this.state.locations.splice(index, 1);
    }

    private validateLocationId(location: Location) {
        if (!location.id || location.id === 0) {
            throw new Error('New location does not have valid id');
        }
    }
}
