import Permissions from '@/services/permissions/Permissions';

function onPartnerEngagementEnter() {
    if (Permissions.PARTNER_ENGAGEMENT.canAccessModule()) {
        // load up any startup data here
        return true;
    }
    return false;
}

export default onPartnerEngagementEnter;
