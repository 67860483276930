
import { computed, defineComponent } from 'vue';

export default defineComponent({
    name: 'b-pagination',
    props: {
        modelValue: { type: Number, default: () => 1 },
        totalRows: { type: Number, required: true },
        perPage: { type: Number, default: () => 25 },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        function next() {
            if (props.modelValue < numberOfPages.value) {
                context.emit('update:modelValue', props.modelValue + 1);
            }
        }

        function previous() {
            if (props.modelValue > 1) {
                context.emit('update:modelValue', props.modelValue - 1);
            }
        }

        function first() {
            context.emit('update:modelValue', 1);
        }

        function last() {
            context.emit('update:modelValue', numberOfPages.value);
        }

        function goToNum(num: number) {
            context.emit('update:modelValue', num);
        }

        const numberOfPages = computed((): number => {
            const value = Math.ceil(props.totalRows / props.perPage);
            if (value) return value;
            return 1;
        });

        return {
            numberOfPages,
            goToNum,
            next,
            previous,
            last,
            first,
        };
    },
});
