
import { ref } from 'vue';

export default {
    setup() {
        const display = ref('');
        const buttons = [
            { value: 'AC', type: 'clear' },
            { value: 'DEL', type: 'delete' },
            { value: '.', type: 'operator' },
            { value: '/', type: 'operator' },

            { value: '7' },
            { value: '8' },
            { value: '9' },
            { value: '*', type: 'operator' },

            { value: '4' },
            { value: '5' },
            { value: '6' },
            { value: '-', type: 'operator' },

            { value: '1' },
            { value: '2' },
            { value: '3' },
            { value: '+', type: 'operator' },

            { value: '000' },
            { value: '00' },
            { value: '0' },
            { value: '=', type: 'equals' },
        ];

        function updateDisplay(value: string) {
            if (value === 'AC') {
                display.value = '';
                return display.value;
            }
            if (value === 'DEL') {
                display.value = display.value.slice(0, -1);
                return display.value;
            }
            if (value === '=') {
                display.value = eval(display.value);
                return display.value;
            }
            display.value += value;
            return display.value;
        }

        return {
            display,
            buttons,
            updateDisplay,
        };
    },
};
