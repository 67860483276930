import { reactive } from 'vue';
import { store } from '@/decorators/store';
import ShippingLoadType from '@/domain/ShippingLoadType';
import { CoreStore } from '../CoreStore';

export type State = {
    shippingLoadTypes: Array<ShippingLoadType>
};

@store
export default class ShippingLoadTypeStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        shippingLoadTypes: [],
    });

    public initShippingLoadTypes(value: Array<ShippingLoadType>) {
        this.state.shippingLoadTypes = value;
    }

    get shippingLoadTypes(): Array<ShippingLoadType> {
        return this.state.shippingLoadTypes;
    }
}
