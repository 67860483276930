import { createI18n } from 'vue-i18n';
import { navEn, navEs, NavTranslation } from '@/translation/nav';
import { buttonEn, buttonEs, ButtonTranslation } from '@/translation/button';
import { commonEn, commonEs, CommonTranslation } from '@/translation/common';
import { transactionStatusEn, transactionStatusEs, TransactionStatusTranslation } from '@/translation/transactionStatus';
import { selectOptionsEn, selectOptionsEs, SelectOptionsTranslation } from '@/translation/selectOptions';
import { domainEn, domainEs, DomainTranslation } from '@/translation/domain';
import { validationEn, validationEs, ValidationTranslation } from '@/translation/validation';
import { uomEn, uomEs, UOMTranslation } from '@/translation/uom';

type TranslationMessage = {
    en: TranslationSchema;
    es: TranslationSchema;
};

type TranslationSchema = {
    core: {
        nav: NavTranslation;
        button: ButtonTranslation;
        selectOptions: SelectOptionsTranslation;
        domain: DomainTranslation;
        common: CommonTranslation;
        transactionStatus: TransactionStatusTranslation;
        uom: UOMTranslation;
        validation: ValidationTranslation;
    };
    pem: {};
};

const messages: TranslationMessage = {
    en: {
        core: {
            nav: navEn,
            button: buttonEn,
            selectOptions: selectOptionsEn,
            domain: domainEn,
            common: commonEn,
            transactionStatus: transactionStatusEn,
            uom: uomEn,
            validation: validationEn,
        },
        pem: {},
    },
    es: {
        core: {
            nav: navEs,
            button: buttonEs,
            selectOptions: selectOptionsEs,
            domain: domainEs,
            common: commonEs,
            transactionStatus: transactionStatusEs,
            uom: uomEs,
            validation: validationEs,
        },
        pem: {},
    },
};

const i18n = createI18n<TranslationMessage, 'en' | 'es'>({
    locale: 'en',
    messages,
    legacy: false,
    globalInjection: true,
});

export { messages, i18n };
