import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import Location from '@/domain/Location';
import UpdateLocationDTO from '@/modules/master-data/dtos/UpdateLocationDTO';
import CreateNewLocationDTO from '@/dtos/CreateNewLocationDTO';

export default class LocationApiService {
    public async getLocationById(id: number): Promise<DataAccessResponse<Location>> {
        const response: AxiosResponse<DataAccessResponse<Location>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/location/${id}`,
        });

        return new DataAccessResponseClass<Location>(response, Location).response;
    }

    public async getCanShipToLocations(locationId: number): Promise<DataAccessResponse<Array<Location>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Location>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/location/ship-to/${locationId}`,
        });
        return new DataListAccessResponseClass<Location>(response, Location).response;
    }

    public async getCanReceiveFromLocations(locationId: number): Promise<DataAccessResponse<Array<Location>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Location>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/location/receive-from/${locationId}`,
        });
        return new DataListAccessResponseClass<Location>(response, Location).response;
    }

    public async getAllLocations(): Promise<DataAccessResponse<Array<Location>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Location>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/location`,
        });
        return new DataListAccessResponseClass<Location>(response, Location).response;
    }

    public async addNewLocation(dto: CreateNewLocationDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/location`,
            data: dto,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateLocation(dto: UpdateLocationDTO): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/location`,
            data: dto,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async deleteLocation(id: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/location/${id}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
