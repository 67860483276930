import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataListAccessResponseClass, DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import Language from '@/domain/Language';

export default class LanguageApiService {
    public async getAllLanguages(): Promise<DataAccessResponse<Array<Language>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Language>>> = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/settings/languages`,
        });

        return new DataListAccessResponseClass<Language>(response, Language).response;
    }
    
    public async setUserLanguage(language: Language): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'post',
            url: `${API_SERVICE_URL}/users/language/${language.id}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
