const CoreRouteTypes = {
    BASE: 'core',
    HOME: 'core-home',
    CHOOSELOCATION: 'choose-location',
    LOGINCALLBACK: 'login-callback',
    NOTFOUND: 'not-found',
    UNAUTHORIZED: 'unauthorized',
    INTERNALSERVERERROR: 'internal-server-error',
};

export default CoreRouteTypes;
