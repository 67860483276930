import { LoginCallback } from '@okta/okta-vue';
import { RouteMeta, RouteRecordRaw } from 'vue-router';
import { onAppEnter, onSelectLocationEnter, onHomeEnter } from './guards';
import CoreRouteTypes from '@/router/types';
import pemRoutes from '@/modules/partner-engagement/routes/routes';
import floorTrakRoutes from '@/modules/floortrak/router/routes';
import masterDataRoutes from '@/modules/master-data/routes/routes';
import { APPLICATION_DISPLAY_NAME } from '@/constants/env';
import { API_SERVICE_URL } from '@/config/env';

const Core = () => import('@/views/Core.vue');
const HomeScreen = () => import('@/views/home/HomeScreen.vue');
const LocationSelectorScreen = () => import('@/components/LocationSelectorScreen.vue');
const NotFound = () => import('@/views/errors/NotFound.vue');
const Unauthorized = () => import('@/views/errors/Unauthorized.vue');
const InternalServerError = () => import('@/views/errors/InternalServerError.vue');
const Loading = () => import('@/components/Loading.vue');

const moduleRoutes: ModuleRouteRecordRaw[] = [...pemRoutes, ...floorTrakRoutes, ...masterDataRoutes];

export type ModuleTypes = 'master-data' | 'partner-engagement' | 'floortrak';

export type MainRouteRecordRaw = RouteRecordRaw & {
    meta?: RouteMeta & { requiresAuth?: boolean }
    children?: ModuleRouteRecordRaw[];
};

export type ModuleRouteRecordRaw = RouteRecordRaw & {
    meta?: RouteMeta & { module: ModuleTypes }
    children?: ModuleChildRouteRecordRaw[];
};

export type ModuleChildRouteRecordRaw = RouteRecordRaw & {
    meta?: RouteMeta & {}
    children?: ModuleGrandChildRouteRecordRaw[]
};

export type ModuleGrandChildRouteRecordRaw = RouteRecordRaw & {
    meta?: RouteMeta & { parent?: string }
};

const routes: MainRouteRecordRaw[] = [
    {
        path: '/terms-of-service',
        beforeEnter: () => {
            window.location.href = 'https://docs.orbiscorporation.com/termsofuseSMARTTRAK360';
        },
        component: Loading,
    },
    {
        path: '/privacy-policy',
        beforeEnter: () => {
            window.location.href = 'https://docs.orbiscorporation.com/privacypolicySMARTTRAK360';
        },
        component: Loading,
    },
    { path: '/login/callback', name: CoreRouteTypes.LOGINCALLBACK, component: LoginCallback },
    {
        path: '/locations/choose',
        name: CoreRouteTypes.CHOOSELOCATION,
        component: LocationSelectorScreen,
        beforeEnter: onSelectLocationEnter,
        meta: { title: 'Choose Location', requiresAuth: true },
    },
    { path: '/not-found', name: CoreRouteTypes.NOTFOUND, component: NotFound },
    { path: '/unauthorized', name: CoreRouteTypes.UNAUTHORIZED, component: Unauthorized },
    { path: '/internal-server-error', name: CoreRouteTypes.INTERNALSERVERERROR, component: InternalServerError },
    {
        path: '/',
        redirect: '/home',
        name: CoreRouteTypes.BASE,
        beforeEnter: onAppEnter,
        component: Core,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                name: CoreRouteTypes.HOME,
                path: 'home',
                component: HomeScreen,
                beforeEnter: onHomeEnter,
                props: {
                    title: APPLICATION_DISPLAY_NAME,
                    showSideBar: false,
                },
            },
            ...moduleRoutes,
        ],
    },
    { path: '/:catchAll(.*)*', name: CoreRouteTypes.NOTFOUND, component: NotFound },
];

export default routes;
