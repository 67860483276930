import InventoryCategoryApiService from '@/services/api/InventoryCategoryApiService';
import InventoryCategory from '@/domain/InventoryCategory';

export default class InventoryCategoryService {
    private inventoryCategoryApiService: InventoryCategoryApiService;

    constructor() {
        this.inventoryCategoryApiService = new InventoryCategoryApiService();
    }

    public async getCategoryDetails(): Promise<{ categories: Array<InventoryCategory>, success: boolean }> {
        const response = await this.inventoryCategoryApiService.getCategoryDetails();

        return {
            categories: response.data,
            success: response.success,
        };
    }
}
