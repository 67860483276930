import { useNotification } from '@/composable/useNotifications';
import LocationApiService from '@/services/api/LocationApiService';
import Location from '@/domain/Location';
import MasterDataStore from '@/modules/master-data/store/MasterDataStore';
import { getTranslation } from '@/services/TranslationService';

export default class LocationService {
    private locationApiService: LocationApiService;
    private notification = useNotification();

    constructor() {
        this.locationApiService = new LocationApiService();
    }

    public async getLocationById(id: number): Promise<Location | null> {
        const response = await this.locationApiService.getLocationById(id);
        if (response.success) {
            return response.data;
        }
        return null;
    }

    public async getCanShipToLocations(locationId: number): Promise<{ locations: Array<Location>; success: boolean }> {
        const response = await this.locationApiService.getCanShipToLocations(locationId);

        if (!response.success) {
            this.notification.showError(response.message);
        }

        return {
            locations: response.data,
            success: response.success,
        };
    }

    public async getCanReceiveFromLocations(locationId: number): Promise<Array<Location>> {
        const response = await this.locationApiService.getCanReceiveFromLocations(locationId);

        if (!response.success) {
            this.notification.showError(response.message);
        }

        return response.data;
    }

    public async getAllLocations(): Promise<{ locations: Array<Location>; success: boolean }> {
        const response = await this.locationApiService.getAllLocations();

        return { locations: response.data, success: response.success };
    }

    public async deleteLocation(id: number): Promise<boolean> {
        const response = await this.locationApiService.deleteLocation(id);
        if (response.success) {
            MasterDataStore.getInstance().locationStore.deleteLocation(id);
            this.notification.showSuccess();
        } else {
            this.notification.showError(response.message);
        }
        return response.success;
    }

    public async updateLocation(location: Location): Promise<boolean> {
        const dto = location.toUpdateDTO();
        const response = await this.locationApiService.updateLocation(dto);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.locationUpdated'));
            MasterDataStore.getInstance().locationStore.updateLocation(location);
        }
        return response.success;
    }

    public async addNewLocation(location: Location): Promise<{ success: boolean; newLocId: number }> {
        const dto = location.toCreateNewDTO();
        const response = await this.locationApiService.addNewLocation(dto);
        if (response.success) {
            this.notification.showSuccess();
            const newLocation = new Location({
                ...location,
                id: response.data,
            });
            MasterDataStore.getInstance().locationStore.addLocation(newLocation);
        }
        return { success: response.success, newLocId: response.data };
    }
}
