

import { defineComponent, reactive, WritableComputedRef } from 'vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import useModelWrapper from '@/composable/useModelWrapper';

type State = {
    error: string;
    componentKey: number;
}

export default defineComponent({
    name: 'number-input',
    components: {},
    extends: BFormInput,
    props: {
        modelValue: {
            type: Number,
        },
        min: {
            type: Number,
            default: 0,
        },
        cols: String,
        lg: String,
        md: String,
        sm: String,
        xs: String,
        disabled: Boolean,
        moreInfo: String,
        step: Number,
        required: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['change', 'update:modelValue', 'input'],
    setup(props, context) {
        const state = reactive<State>({
            error: '',
            componentKey: 0,
        });

        const inputValue: WritableComputedRef<number | string | undefined> = useModelWrapper(props, context, 'modelValue');

        function handleChange(e: string | number | undefined) {
            context.emit('change', e);
            context.emit('update:modelValue', e);
        }

        function handleInput(e: string | number | undefined) {
            context.emit('input', e);
            context.emit('update:modelValue', e);
        }

        return {
            state,
            handleChange,
            handleInput,
            inputValue,
        };
    },
});

