
import {
    computed, defineComponent, reactive, ref, onMounted, watch,
} from 'vue';
import Camera from './Camera.vue';

type State = {
    capturedImageData: string;
    cameraError: boolean;
    width: number; // We will scale the photo width to this
    height: number; // This will be computed based on the input stream
}

export default defineComponent({
    name: 'camera-modal',
    components: {
        Camera,
    },
    props: {},
    emits: ['cancel', 'selectedImage'],
    setup(props, context) {
        const cameraCanvas = ref<HTMLCanvasElement | null>(null); // : Ref<HTMLCanvasElement>; // = ref<HTMLCanvasElement>(new HTMLCanvasElement());
        const showPictureResultModal = ref(false);
        const showTakePictureModal = ref(true);
        const state = reactive<State>({
            capturedImageData: '',
            cameraError: false,
            width: 320, // We will scale the photo width to this
            height: 0, // This will be computed based on the input stream
        });

        // used to go BACK to the camera modal from the result modal
        function openTakePictureModal() {
            // TODO
            // context.root.$bvModal.hide('picture-result-modal');
            // context.root.$bvModal.show('take-picture-modal');
        }

        // used to show the result from the camera modal
        function showPictureModal(imageData: string) {
            state.capturedImageData = imageData;
            // TODO
            // context.root.$bvModal.hide('take-picture-modal');
            // context.root.$bvModal.show('picture-result-modal');
        }

        // used when cancelling in camera modal
        function closeCamera() {
            context.emit('cancel');
        }

        function selectPicture() {
            // TODO
            // context.root.$bvModal.hide('picture-result-modal');
            context.emit('selectedImage', state.capturedImageData);
        }

        return {
            cameraCanvas,
            state,
            selectPicture,
            showPictureResultModal,
            showTakePictureModal,
            showPictureModal,
            closeCamera,
            openTakePictureModal,
        };
    },
});
