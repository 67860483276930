import NonWorkingTimeSet from '@/domain/Dates/NonWorkingTimeSet';
import NonWorkingDaysTimeSetApiService from './api/NonWorkingDaysTimeSetApiService';
import { useNotification } from '@/composable/useNotifications';

export default class NonWorkingDaysTimeSetService {
    private timeSetApiService: NonWorkingDaysTimeSetApiService;

    private notification = useNotification();

    constructor() {
        this.timeSetApiService = new NonWorkingDaysTimeSetApiService();
    }

    public async getAllNonWorkingTimeSets(): Promise<{ success: boolean, nonWorkingTimeSets: Array<NonWorkingTimeSet> }> {
        const response = await this.timeSetApiService.getAllNonWorkingTimeSets();

        if (!response.success) {
            this.notification.showError(response.message);
        }

        return {
            success: response.success,
            nonWorkingTimeSets: response.data,
        };
    }
}
