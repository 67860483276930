
import {
    defineComponent, reactive, onMounted, nextTick, computed, watch, onBeforeUnmount,
} from 'vue';
import sidebarStore from '@/components/sidebar/store/SidebarStore';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import CoreRouteTypes from '@/router/types';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import { routesHideSideBar } from '@/components/sidebar/settings';
import PartnerEngagementRouteTypes from '@/modules/partner-engagement/routes/types';
import coreStore from '@/store/CoreStore';
import router from '@/router';
import AuthStore from '@/store/AuthStore';
import BNavbar from '@/components/bootstrap-library/BNavbar.vue';
import Permissions from '@/services/permissions/Permissions';
import BDivider from '@/components/bootstrap-library/BDivider.vue';
import SystemApiService from '@/services/api/SystemApiService';
import useModuleUtil from '@/composable/useModuleUtil';
import useScreenSize from '@/composable/useScreenSize';
import BButton from '@/components/bootstrap-library/BButton.vue';
import useHelp from '@/composable/useHelp';
import { APPLICATION_DISPLAY_NAME } from '@/constants/env';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import Language from '@/domain/Language';
import LanguageService from '@/services/LanguageService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import Calculator from '@/components/inputs/Calculator.vue';
import SortStationService from '@/services/SortStationService';

type State = {
    routesHideNav: Array<string>;
    showSideBarButton: boolean;
    showAbout: boolean;
    appVersion: string;
    screenIcon: string;
    showChooseLanguage: boolean;
    showCalculator: boolean;
};

export default defineComponent({
    components: {
        BModal,
        BButton,
        BDivider,
        BNavbar,
        Calculator,
    },
    setup() {
        const smartTrakLogo = require('@/assets/smarttrak360-white.png');

        const { profileStore, metaDataStore, languageStore } = coreStore.getInstance();
        const authStore = AuthStore.getInstance();
        const systemApiService = new SystemApiService();
        const languageService = new LanguageService();
        const { activeModule } = useModuleUtil();
        const { isMobile } = useScreenSize();
        const { help } = useHelp();
        const isFloorTrak = computed(() => activeModule.value === 'floortrak');
        const isMasterData = computed(() => activeModule.value === 'master-data');
        const isPEM = computed(() => activeModule.value === 'partner-engagement');

        const state = reactive<State>({
            routesHideNav: ['LoginScreen', 'LocationSelectionScreen'],
            showSideBarButton: false,
            showAbout: false,
            appVersion: '',
            screenIcon: 'window-maximize',
            showChooseLanguage: false,
            showCalculator: false,
        });

        function checkForSideBar() {
            // after each route change, if sidebar is in dom and routesHideSideBar doesnt include route, show open button
            nextTick(() => {
                const sideBar = document.getElementById('orbis-side-bar');
                state.showSideBarButton = !!(sideBar && router.currentRoute.value.name && !routesHideSideBar.includes(router.currentRoute.value.name as string));
            });
        }

        router.afterEach(() => {
            checkForSideBar();
        });

        function handleFullscreenChange() {
            state.screenIcon = document.fullscreenElement ? 'window-restore' : 'window-maximize';
        }

        function openFullscreen() {
            document.body.requestFullscreen();
        }

        function closeFullscreen() {
            document.exitFullscreen();
        }

        function toggleFullscreen() {
            if (!document.fullscreenElement) {
                openFullscreen();
            } else {
                closeFullscreen();
            }
        }

        watch(
            () => [activeModule.value],
            () => {
                if (isMobile.value && activeModule.value === 'floortrak') {
                    openFullscreen();
                }
            },
            { immediate: true },
        );

        onMounted(async () => {
            document.addEventListener('fullscreenchange', handleFullscreenChange);

            checkForSideBar();

            const versionResult = await systemApiService.getVersion();
            if (versionResult) {
                state.appVersion = versionResult;
            }
        });

        onBeforeUnmount(() => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        });

        function toggleNav() {
            sidebarStore.getInstance().toggleSidebar();
        }

        function openMasterData() {
            if (Permissions.ADMINISTRATION.canAccessDashboard() && authStore.samAccountName) {
                router.push({ name: MasterDataRouteTypes.DASHBOARD });
            } else {
                router.push({ name: MasterDataRouteTypes.TRANSACTION.LIST });
            }
        }

        function openFloorTrak() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function openCore() {
            router.push({ name: CoreRouteTypes.HOME });
        }

        function openPartnerEngagementModule() {
            if (Permissions.PARTNER_ENGAGEMENT.canAccessDashboard() && authStore.samAccountName) {
                router.push({ name: PartnerEngagementRouteTypes.DASHBOARD });
            } else if (Permissions.PARTNER_ENGAGEMENT.canAddShipment()
                || Permissions.PARTNER_ENGAGEMENT.canOrderPackaging()
                || Permissions.PARTNER_ENGAGEMENT.canConfirmDisputeInboundReceipts()) {
                router.push({ name: PartnerEngagementRouteTypes.TRANSACTION.LISTALL });
            } else {
                router.push({ name: PartnerEngagementRouteTypes.HOME });
            }
        }

        function openChooseLocation() {
            profileStore.clearUserLocation();
            router.push({ name: CoreRouteTypes.CHOOSELOCATION });
        }

        function openChooseLanguage() {
            state.showChooseLanguage = true;
        }

        async function setLanguage(value: Language) {
            if (value) {
                await languageService.changeUserLanguage(value);
            }
            state.showChooseLanguage = false;
        }

        const currentRoute = computed((): string | null | undefined => router.currentRoute.value.name as string | null | undefined);
        const numberOfLocations = computed((): number => profileStore.locations.length);
        const wikiBaseLink = process.env.VUE_APP_WIKI_LINK;
        const releaseNotesLink = computed((): string => `${wikiBaseLink}/${profileStore.userLanguage?.culture ?? 'en'}/release-notes`);

        function showAbout() {
            state.showAbout = true;
        }

        function showCalculator(show: boolean) {
            state.showCalculator = show;
        }

        return {
            state,
            toggleNav,
            openMasterData,
            openChooseLocation,
            openChooseLanguage,
            setLanguage,
            openFloorTrak,
            openPartnerEngagementModule,
            openCore,
            profileStore,
            authStore,
            languageStore,
            currentRoute,
            MasterDataRouteTypes,
            numberOfLocations,
            Permissions,
            showAbout,
            isMobile,
            isFloorTrak,
            isMasterData,
            isPEM,
            toggleFullscreen,
            help,
            APPLICATION_DISPLAY_NAME,
            smartTrakLogo,
            metaDataStore,
            getTitleCaseTranslation,
            getTranslation,
            releaseNotesLink,
            showCalculator,
        };
    },
    methods: {
        async oktaLogout() {
            const sortStationService = new SortStationService();
            await sortStationService.disconnectAllUsersFromUsersSortStation(this.profileStore.id);
            await this.$auth.signOut();
        },
    },
});
