import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_notification_center = _resolveComponent("notification-center")!
  const _component_confirm_dialog_modal = _resolveComponent("confirm-dialog-modal")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    key: _ctx.$i18n.locale
  }, [
    _createElementVNode("div", null, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_notification_center),
      _createVNode(_component_confirm_dialog_modal, { ref: "confirmModal" }, null, 512),
      _createVNode(_component_navigation),
      _createVNode(_component_router_view, { style: {"padding-top":"56px"} })
    ])
  ]))
}