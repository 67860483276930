import { ref } from 'vue';

const loading = ref(true);

export function useLoading() {
    function show() {
        loading.value = true;
    }

    function hide() {
        loading.value = false;
    }

    return {
        show,
        hide,
        loading,
    };
}
