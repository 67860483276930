export type SelectOptionsTranslation = {
    selectSearchOption: string;
    allTransactions: string;
    availableToSelect: string;
    inboundTransactions: string;
    locationsAvailableToSelect: string;
    noAvailableItems: string;
    noItemsAdded: string;
    noItemsInSearch: string;
    outboundTransactions: string;
    rolesAvailableToSelect: string;
    selectedInRoute: string;
    selectedLocations: string;
    selectedRoles: string;
    currentTransaction: string;
};

const selectOptionsEn: SelectOptionsTranslation = {
    selectSearchOption: 'select search option',
    allTransactions: 'all transactions',
    availableToSelect: 'available to select',
    inboundTransactions: 'inbound transactions',
    locationsAvailableToSelect: 'locations available to select',
    noAvailableItems: 'no available items',
    noItemsAdded: 'no items added',
    noItemsInSearch: 'no items in search',
    outboundTransactions: 'outbound transactions',
    rolesAvailableToSelect: 'roles available to select',
    selectedInRoute: 'selected in route',
    selectedLocations: 'selected locations',
    selectedRoles: 'selected roles',
    currentTransaction: 'current transaction',
};

const selectOptionsEs: SelectOptionsTranslation = {
    selectSearchOption: 'seleccione la opción de búsqueda',
    allTransactions: 'todas las transacciones',
    availableToSelect: 'disponible para seleccionar',
    inboundTransactions: 'transacciones de entradas',
    locationsAvailableToSelect: 'ubicaciones disponibles para seleccionar',
    noAvailableItems: 'no hay artículos disponibles',
    noItemsAdded: 'no se agregaron elementos',
    noItemsInSearch: 'no hay artículos en la búsqueda',
    outboundTransactions: 'transacciones de salida',
    rolesAvailableToSelect: 'roles disponibles para seleccionar',
    selectedInRoute: 'seleccionado en la ruta',
    selectedLocations: 'ubicaciones seleccionadas',
    selectedRoles: 'roles seleccionados',
    currentTransaction: 'transacción actual',
};

export { selectOptionsEn, selectOptionsEs };
