

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'loading',
    components: {},
    props: {},
    setup() {
        return {};
    },
});

