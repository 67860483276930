import Location from '@/domain/Location';

export default class CreateNewLocationDTO {
    public name!: string;

    public shortName!: string;

    public typeId!: number;

    public nonWorkingTimeSetId?: number;

    public freightCodeId?: number;

    public isOpen24Hours: boolean = false;

    public openTime?: Date;

    public closeTime?: Date;

    public taxId?: string;

    public locationNumber?: string;

    public languageId?: number;

    public targetDaysOnHand: number | null;

    public maxLoadPerTimeslot?: number;

    public processedPutAwayPullFromLocationId?: number;

    public timeslotDuration?: number;

    constructor(location: Location) {
        this.name = location.name;
        this.shortName = location.shortName;
        this.typeId = location.type?.id ?? location.typeId;
        this.nonWorkingTimeSetId = location.nonWorkingTimeSet?.id ?? location.nonWorkingTimeSetId;
        this.freightCodeId = location.freightCode?.id ?? location.freightCodeId;
        this.isOpen24Hours = location.isOpen24Hours;
        this.openTime = location.openTime;
        this.closeTime = location.closeTime;
        this.taxId = location.taxId;
        this.locationNumber = location.locationNumber;
        this.languageId = location.language?.id;
        this.maxLoadPerTimeslot = location.maxLoadPerTimeslot;
        this.processedPutAwayPullFromLocationId = location.processedPutAwayPullFromLocationId;
        this.targetDaysOnHand = location.targetDaysOnHand || null;
        this.timeslotDuration = location.timeslotDuration;
    }
}
