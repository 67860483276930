import {
    Ref, ref, nextTick, ComputedRef,
} from 'vue';
import { ColumnData } from '@/components/bootstrap-library/table/BTable/ColumnData';

export type DragData = {
    x: number;
    y: number;
    columnKey: string;
};

type UseTableWidth = {
    initTableWidth: () => void;
    getColumnWidthByKey: (key: string) => string;
    setColumnWidthByKey: (key: string, width: string) => void;
    startDrag: (columnKey: string, e: PointerEvent | MouseEvent) => void;
    stopDrag: (e: PointerEvent | MouseEvent) => void;
    isDragging: Ref<boolean>;
    show: Ref<boolean>;
    switchTable: (newTableKey: string) => void;
};

type Props = {
    tableKey?: string;
    columnData: ComputedRef<Array<ColumnData>>;
};

export default function useTableWidth(props: Props): UseTableWidth {
    const minColumnWidth = 100;
    const tableKey = ref(props.tableKey);
    const columnWidths: Map<string, string> = new Map();
    const dragData: DragData = { x: NaN, y: NaN, columnKey: '' };
    const isDragging = ref(false);
    const show = ref(true);

    function getColumnWidthByKey(key: string) {
        if (columnWidths.has(key)) {
            return columnWidths.get(key) as string;
        }
        return 'auto';
    }

    function setColumnWidthByKey(key: string, width: string) {
        columnWidths.set(key, width);
        if (tableKey.value) {
            const data = Object.fromEntries(columnWidths);
            sessionStorage.setItem(`${tableKey.value}-style`, JSON.stringify(data));
        }
    }

    function initTableWidth() {
        let colMap: Map<string, string>;
        if (tableKey.value) {
            const data = sessionStorage.getItem(`${tableKey.value}-style`);
            if (data) {
                colMap = new Map(Object.entries(JSON.parse(data)));
            }
        }

        props.columnData.value.forEach((data: ColumnData) => {
            if (colMap?.get(data.key)) {
                setColumnWidthByKey(data.key, `${colMap.get(data.key)}`);
                return;
            }
            if (data.width) {
                setColumnWidthByKey(data.key, data.width);
            }
        });
        show.value = false;
        nextTick(() => {
            show.value = true;
        });
    }

    function startDrag(columnKey: string, e: PointerEvent | MouseEvent) {
        dragData.columnKey = columnKey;
        dragData.x = e.clientX;
        dragData.y = e.clientY;
        isDragging.value = true;
    }

    function stopDrag(e: PointerEvent | MouseEvent) {
        if (isDragging.value) {
            let curWidth = columnWidths
                .get(dragData.columnKey)
                ?.replace('px', '')
                .trim();
            if (!curWidth) {
                // try to find the width of the TH set by the browser and use that as the default. fall back to the min column width
                curWidth = `${(e.target as Element)?.closest('th')?.getBoundingClientRect().width ?? minColumnWidth}`;
            }

            const width = Math.max(parseFloat(curWidth) + (e.x - dragData.x), minColumnWidth);
            setColumnWidthByKey(dragData.columnKey, `${width}px`);

            dragData.columnKey = '';
            dragData.x = NaN;
            dragData.y = NaN;
            isDragging.value = false;
        }
    }

    function switchTable(newTableKey: string) {
        tableKey.value = newTableKey;
        initTableWidth();
    }

    return {
        initTableWidth,
        getColumnWidthByKey,
        setColumnWidthByKey,
        startDrag,
        stopDrag,
        isDragging,
        show,
        switchTable,
    };
}
