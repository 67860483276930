import { AxiosResponse } from 'axios';
import ApiErrorResponseStrategy from './ApiErrorResponseStrategy';
import { FluentValidationError } from '@/domain/FluentValidation';

export default class UnprocessableEntityResponseStrategy extends ApiErrorResponseStrategy {
    override handleError() {
        const errorList = this.error.responseBody.data as FluentValidationError[];
        const structuredErrors = {};

        if (Array.isArray(errorList)) {
            errorList.forEach((err) => {
                const key = err.propertyName.charAt(0).toLowerCase() + err.propertyName.slice(1);
                // strip out single quotes around property name to match format used client side
                const msg = err.formattedMessagePlaceholderValues?.PropertyName
                    ? err.errorMessage.replace(`'${err.formattedMessagePlaceholderValues?.PropertyName}'`, err.formattedMessagePlaceholderValues?.PropertyName)
                    : err.errorMessage;

                // @ts-ignore
                structuredErrors[key] = msg;
            });
        } else {
            // this shouldn't occur but is a fallback in case the error from the server isn't structured correctly
            this.notification().showErrorList(errorList);
        }

        // useValidator listens for this event so it can display errors inline
        window.dispatchEvent(new CustomEvent('validationError', { detail: structuredErrors }));

        // returns errorList to the calling service so it can handle errors as needed
        return Promise.resolve({ data: { errorList: structuredErrors } } as AxiosResponse);
    }
}
