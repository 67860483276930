import ApplicationUser from '@/domain/ApplicationUser';
import SortStation from '@/domain/SortStation';
import SortStationApiService from '@/services/api/SortStationApiService';

export default class SortStationService {
    private sortStationApiService = new SortStationApiService();

    public async createSortStation(sortStation: SortStation): Promise<SortStation | null> {
        const response = await this.sortStationApiService.createSortStation(sortStation);
        if (response.success) {
            return new SortStation({
                ...sortStation,
                id: response.data,
            });
        }
        return null;
    }

    public async updateSortStation(sortStation: SortStation): Promise<SortStation | null> {
        const response = await this.sortStationApiService.updateSortStation(sortStation);
        if (response.success) {
            return sortStation;
        }
        return null;
    }

    public async deleteSortStation(sortStation: SortStation): Promise<boolean> {
        const response = await this.sortStationApiService.deleteSortStation(sortStation);
        return response.success;
    }

    public async getUsers(locationId: number): Promise<{ users: Array<ApplicationUser>; success: boolean }> {
        const response = await this.sortStationApiService.getUsers(locationId);
        return {
            users: response.data,
            success: response.success,
        };
    }

    public async getSortStationsByLocationIdWithActivity(locationId: number): Promise<{ sortStations: Array<SortStation>; success: boolean }> {
        const response = await this.sortStationApiService.getSortStationsByLocationIdWithActivity(locationId);
        return {
            sortStations: response.data,
            success: response.success,
        };
    }

    public async connectToSortStation(sortStationId: number, userId: number): Promise<boolean> {
        const response = await this.sortStationApiService.connectToSortStation(sortStationId, userId);
        return response.success;
    }

    public async disconnectFromSortStation(sortStationId: number, userId: number): Promise<boolean> {
        const response = await this.sortStationApiService.disconnectFromSortStation(sortStationId, userId);
        return response.success;
    }

    public async disconnectAllUsersFromUsersSortStation(userId: number): Promise<boolean> {
        const response = await this.sortStationApiService.disconnectAllUsersFromUsersSortStation(userId);
        return response.success;
    }
}
