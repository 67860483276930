import { reactive, Ref, ref } from 'vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export type ConfirmDialogProps = {
    title: string;
    message: string;
    okButton?: string;
    cancelButton?: string;
    vHtml?: boolean;
};

export interface ConfirmDialogData extends ConfirmDialogProps {
    okButton: string;
    cancelButton: string;
    vHtml: boolean;
}

export type UseDialogBox = {
    confirmModal: Ref<null | HTMLElement>;
    confirm: (props: ConfirmDialogProps) => Promise<boolean>;
};

// These are methods from the Vue component that we want exposed and are only used in this composable
interface ConfirmDialogBox extends HTMLElement {
    show: (props: ConfirmDialogProps) => Promise<boolean>;
}

const confirmModal = ref<null | ConfirmDialogBox>(null);

export default function useDialogBox(): UseDialogBox {
    const initialState: ConfirmDialogData = {
        title: '',
        message: '',
        okButton: getTitleCaseTranslation('core.button.ok'),
        cancelButton: getTitleCaseTranslation('core.button.cancel'),
        vHtml: false,
    };

    const state = reactive<ConfirmDialogData>({ ...initialState });

    async function confirm(props: ConfirmDialogProps): Promise<boolean> {
        // reset the current state back to the initial state first
        Object.assign(state, initialState);

        state.message = props.message;
        state.title = props.title;
        if (props.okButton) state.okButton = props.okButton;
        if (props.cancelButton) state.cancelButton = props.cancelButton;
        if (props.vHtml) state.vHtml = props.vHtml;

        return confirmModal.value?.show(state) ?? Promise.resolve(false);
    }

    return {
        confirmModal,
        confirm,
    };
}
