import NonWorkDays from '@/domain/Dates/NonWorkDays';
import NonWorkDates from '@/domain/Dates/NonWorkDates';

export default class NonWorkingTimeSet {
    constructor(init?: Partial<NonWorkingTimeSet>) {
        Object.assign(this, init);
        this.nonWorkDates = init?.nonWorkDates?.map((d) => new NonWorkDates(d)) || [];
    }

    public id!: number;

    public name!: string;

    public isDefaultTimeSet!: boolean;

    public nonWorkDays!: Array<NonWorkDays>;

    public nonWorkDates!: Array<NonWorkDates>;
}
