import { reactive } from 'vue';
import { store } from '@/decorators/store';
import Carrier from '@/domain/Carrier';
import { CoreStore } from '../CoreStore';

type State = {
    carriers: Array<Carrier>,
    loaded: boolean;
};

@store
export default class CarrierStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        carriers: [],
        loaded: false,
    });

    public initCarriers(value: Array<Carrier>) {
        this.state.carriers = value;
        this.state.loaded = true;
    }

    get loaded(): boolean {
        return this.state.loaded;
    }

    get carriers(): Array<Carrier> {
        return this.state.carriers;
    }
}
