import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71b72d14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "error"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, {
    class: "number-input",
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs
  }, {
    default: _withCtx(() => [
      (_ctx.label || _ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.error), 1))
              : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.label? 'input-error-label' : 'input-error-no-label')
      }, _toDisplayString(_ctx.state.error), 3),
      _createVNode(_component_b_form_input, {
        ref: "numberInput",
        key: _ctx.state.componentKey,
        modelValue: _ctx.inputValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        min: _ctx.min,
        max: _ctx.max,
        type: "number",
        step: _ctx.step,
        disabled: _ctx.disabled,
        number: "",
        "number-options": _ctx.numberOptions,
        "hide-stepper": _ctx.hideStepper,
        autofocus: _ctx.autofocus,
        required: _ctx.required,
        onInput: _ctx.handleInput,
        onChange: _ctx.handleChange
      }, null, 8, ["modelValue", "min", "max", "step", "disabled", "number-options", "hide-stepper", "autofocus", "required", "onInput", "onChange"])
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs"]))
}