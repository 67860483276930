
import {
    computed, defineComponent, ref, watch,
} from 'vue';
import { useNotification } from '@/composable/useNotifications';

type IconStyle = {
    icon: 'check' | 'exclamation-triangle' | 'exclamation-circle';
    color: string;
}

export default defineComponent({
    name: 'notification-center',
    components: {},
    props: {},
    setup() {
        const notificationContainer = ref<HTMLElement | null>(null);
        const { notifications } = useNotification();

        function scrollToBottom() {
            if (notificationContainer.value) {
                notificationContainer.value.scrollTop = notificationContainer.value.scrollHeight;
            }
        }

        const activeNotifications = computed(() => notifications.value.filter((notif) => notif.visible));

        watch(() => notifications.value, () => scrollToBottom());

        function getIcon(type?: 'success' | 'warning' | 'danger'): IconStyle | undefined {
            switch (type) {
            case 'success':
                return {
                    icon: 'check',
                    color: 'green',
                };
            case 'warning':
                return {
                    icon: 'exclamation-triangle',
                    color: 'yellow',
                };
            case 'danger':
                return {
                    icon: 'exclamation-circle',
                    color: 'red',
                };
            default:
                return undefined;
            }
        }

        return {
            notifications,
            getIcon,
            notificationContainer,
            activeNotifications,
        };
    },
});
