import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

export enum LocationAddressType {
    ShippingAndReceiving = 1,
    Receiving = 2,
    Shipping = 3,
}

export function mapLocationAddressTypeToName(type: LocationAddressType | null): string {
    switch (type) {
    case LocationAddressType.ShippingAndReceiving:
        return getTranslation('core.common.shippingAndReceiving');
    case LocationAddressType.Shipping:
        return getTitleCaseTranslation('core.common.shipping');
    case LocationAddressType.Receiving:
        return getTitleCaseTranslation('core.common.receiving');
    default:
        return getTitleCaseTranslation('core.validation.invalid');
    }
}

export type AddressTypeSelectItem = {
    value: LocationAddressType | null,
    text: String,
    disabled?: boolean
};

export const allAddressTypeOptions: Array<AddressTypeSelectItem> = [
    {
        value: null,
        text: getTranslation('core.common.selectAddressType').toUpperCase(),
        disabled: true,
    },
    {
        value: LocationAddressType.ShippingAndReceiving,
        text: getTranslation('core.common.shippingAndReceiving'),
    },
    {
        value: LocationAddressType.Shipping,
        text: getTitleCaseTranslation('core.common.shipping'),
    },
    {
        value: LocationAddressType.Receiving,
        text: getTitleCaseTranslation('core.common.receiving'),
    },
];
