import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f89dcb1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "notificationContainer",
  class: "custom-notification-container"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "custom-notification-head" }
const _hoisted_4 = {
  key: 0,
  class: "custom-notification-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeNotifications, (notification, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "custom-notification"
      }, [
        _createElementVNode("div", {
          class: "close-notification",
          onClick: ($event: any) => (notification.visible=false)
        }, [
          _createVNode(_component_faicon, { icon: "times" })
        ], 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          (notification.type)
            ? (_openBlock(), _createBlock(_component_faicon, {
                key: 0,
                icon: _ctx.getIcon(notification.type).icon,
                style: _normalizeStyle({color: _ctx.getIcon(notification.type).color})
              }, null, 8, ["icon", "style"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(notification.head ? notification.head.toUpperCase() : notification.message), 1)
        ]),
        (notification.head)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notification.message, (message, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(message), 1))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 512))
}