export default class Language {
    id!: number;

    name!: string;

    culture!: string;

    constructor(init?: Partial<Language>) {
        Object.assign(this, init);
    }
}
