
import {
    computed, defineComponent, PropType, Ref,
} from 'vue';
import BBadge from '@/components/bootstrap-library/BBadge.vue';

export default defineComponent({
    name: 'side-bar-nav-badge',
    components: {
        BBadge,
    },
    props: {
        pushNotificationSubscriptionKey: {
            type: String,
            required: true,
        },
        pushNotificationSubscriptions: {
            type: Object as PropType<Map<string, Ref<number>>>,
            required: true,
        },
    },
    setup(props) {
        const pushNotificationCount = computed(() => props.pushNotificationSubscriptions.get(props.pushNotificationSubscriptionKey)?.value);

        return {
            pushNotificationCount,
        };
    },
});
