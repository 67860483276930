import { LocationAddressType, mapLocationAddressTypeToName } from '@/domain/enums/LocationAddressType';

export default class LocationAddress {
    id: number = 0;

    displayName: string = '';

    type?: LocationAddressType = LocationAddressType.ShippingAndReceiving;

    locationId!: number;

    streetAddress?: string;

    city?: string;

    state?: string;

    zip?: string;

    country?: string;

    phoneNumber?: string;

    contactName?: string;

    emailAddress?: string;

    timeZone?: string;

    constructor(init?: Partial<LocationAddress>) {
        Object.assign(this, init);
    }

    public get typeDescription() {
        return mapLocationAddressTypeToName(this.type ?? null);
    }
}
