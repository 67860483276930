import PushNotificationSubscription from '@/store/utility/PushNotificationSubscription';

export default class SideBarMenuChild {
    public static TYPE = {
        PARENT: 'parent',
        CHILD: 'child',
    };

    public title: string = '';

    public routeName?: string = ''; // route that you are navigated to on click

    public activeRouteNameArray?: string[] = []; // array of routes that makes the child nav "active"

    public show?: boolean;

    public key?: string;

    public type?: string = '';

    public icon?: string = '';

    public children?: SideBarMenuChild[] = [];

    public pushNotificationSubscription?: PushNotificationSubscription;

    public id: number = 0;

    constructor(init?: Partial<SideBarMenuChild>) {
        Object.assign(this, init);
    }
}
