
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'b-form-textarea',
    props: {
        modelValue: String,
        label: String,
        formatter: { type: Function },
        lazyFormatter: { type: Boolean, default: false },
        placeholder: String,
        disabled: { type: Boolean, default: false },
        rows: {
            type: String,
            default: '3',
        },
        maxLength: { type: Number, default: undefined },
    },
    emits: ['update:modelValue', 'input', 'change'],
    setup(props, context) {
        function formatValue(value: unknown, evt: any, force = false) {
            const { formatter, lazyFormatter } = props;
            value = String(value);
            if (typeof formatter === 'function' && (!lazyFormatter || force)) {
                // @ts-ignore
                value = formatter(value, evt);
            }
            return value;
        }

        function onInput(evt: any) {
            const { value } = evt.target;
            const formattedValue = formatValue(value, evt);
            if (formattedValue === false || evt.defaultPrevented) {
                evt.preventDefault();
                return;
            }
            context.emit('update:modelValue', value);
            context.emit('input', value);
        }
        function onChange(evt: any) {
            const { value } = evt.target;
            const formattedValue = formatValue(value, evt);
            if (formattedValue === false || evt.defaultPrevented) {
                evt.preventDefault();
                return;
            }
            context.emit('change', formattedValue);
        }

        return {
            onInput,
            onChange,
        };
    },
});
