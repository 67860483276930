/* eslint-disable import/no-mutable-exports */

// TODO: at some point we should move all of this into .env files
let API_SERVICE_URL = 'http://localhost:5000/api';
let ISSUER = 'https://orbiscorporation.oktapreview.com/oauth2/default';
let AUTH_BASE_URL = 'https://orbiscorporation.oktapreview.com';

if (process.env.VUE_APP_MODE === 'stage') {
    API_SERVICE_URL = '/api';
}

if (process.env.VUE_APP_MODE === 'user-acceptance') {
    API_SERVICE_URL = '/api';
}

if (process.env.VUE_APP_MODE === 'production') {
    API_SERVICE_URL = '/api';
    ISSUER = 'https://orbiscorporation.okta.com/oauth2/default';
    AUTH_BASE_URL = 'https://orbiscorporation.okta.com';
}

export {
    API_SERVICE_URL, AUTH_BASE_URL, ISSUER,
};
