import axios from 'axios';
import handleErrors from '@/config/api/interceptors/errors';
import { handleSuccess } from '@/config/api/interceptors/success';

export default class ApiConfig {
    private static instance: ApiConfig;

    private constructor() {
        this.initInterceptor();
    }

    public static getInstance(): ApiConfig {
        if (!ApiConfig.instance) {
            ApiConfig.instance = new ApiConfig();
        }

        return ApiConfig.instance;
    }

    public static setToken(token: string) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    private initInterceptor() {
        axios.interceptors.response.use(handleSuccess, handleErrors);
    }
}
