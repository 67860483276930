export default class TrailerType {
    constructor(init?: Partial<TrailerType>) {
        if (init) {
            this.id = init.id || 0;
            this.description = init.description || '';
            this.deleted = init.deleted || false;
            this.name = init.name || '';
            this.shortName = init.shortName || '';
            this.length = init.length || 0;
            this.width = init.width || 0;
            this.height = init.height || 0;
            this.loadType = init.loadType || '';
            this.isPreferred = init.isPreferred || false;
            this.maxLoadCapacity = init.maxLoadCapacity || 0;
        }
    }

    public id!: number;

    public description!: string;

    public deleted!: boolean;

    public name!: string;

    public shortName!: string;

    public length!: number;

    public width!: number;

    public height!: number;

    public loadType!: string;

    public isPreferred!: boolean;

    public maxLoadCapacity!: number;

    get cubicVolume(): number {
        return this.length * this.width * this.height;
    }
}
