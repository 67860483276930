import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22e74410"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check" }
const _hoisted_2 = ["checked", "value", "disabled"]
const _hoisted_3 = { class: "form-check-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "form-check-input",
      type: "checkbox",
      checked: _ctx.modelValue,
      value: _ctx.modelValue,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.label), 1)
      ], true)
    ])
  ]))
}