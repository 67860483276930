
import { defineComponent, PropType } from 'vue';
import SubHeader from '@/components/SubHeader.vue';
import useScreenSize from '@/composable/useScreenSize';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';

export default defineComponent({
    name: 'screen',
    components: { BSpinner, SubHeader },
    props: {
        full: Boolean,
        padded: Boolean,
        title: { type: String, default: undefined },
        heading: { type: String, default: undefined },
        goBack: { type: Function as PropType<() => void>, default: undefined },
        heightOffset: { type: Number, default: 56 }, // nav height
        loading: { type: Boolean, default: false },
    },
    setup() {
        const { screenHeight } = useScreenSize();

        // TODO: move footer styling into this component to enforce consistency
        // most importantly spacing and top border
        // the problem is that context.slot isn't reactive, so if we're dynamically showing the footer and use a computed, it wont work

        return {
            screenHeight,
        };
    },
});
