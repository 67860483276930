import { useLoading } from '@/composable/useLoading';
import ApiConfig from '@/config/api/api-config';

export default class StartupService {
    private loading = useLoading();

    public async startApp() {
        this.loading.hide();

        // init the ApiConfig singleton so axios is configured correctly
        ApiConfig.getInstance();
    }
}
