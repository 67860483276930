import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    centered: "",
    title: _ctx.title,
    "ok-button": _ctx.okButton,
    "cancel-button": _ctx.cancelButton,
    "disable-escape-key": "",
    "hide-header-close": "",
    "z-index": 1056,
    onOk: _ctx._onOk,
    onCancel: _ctx._onCancel,
    onEnter: _ctx._onEnter
  }, {
    default: _withCtx(() => [
      (_ctx.vHtml)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            innerHTML: _ctx.message
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.message), 1))
    ]),
    _: 1
  }, 8, ["modelValue", "title", "ok-button", "cancel-button", "onOk", "onCancel", "onEnter"]))
}