import { i18n } from '@/translation/i18n';
import useStringFormatter from '@/composable/useStringFormatter';

const { titleCase } = useStringFormatter();

export function getTranslation(value: string, ...formatParams: unknown[]): string {
    return i18n.global.t(value, formatParams);
}

export function getTitleCaseTranslation(value: string, ...formatParams: unknown[]): string {
    return titleCase(i18n.global.t(value, formatParams));
}
