import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a40179e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-state-screen" }
const _hoisted_2 = { class: "empty-state-content" }
const _hoisted_3 = {
  key: 0,
  class: "icon-container"
}
const _hoisted_4 = { class: "empty-state-title" }
const _hoisted_5 = { class: "empty-state-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_faicon, { icon: _ctx.icon }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.message), 1),
      (_ctx.button)
        ? (_openBlock(), _createBlock(_component_b_button, {
            key: 1,
            variant: _ctx.button.variant,
            disabled: _ctx.button.disabled,
            onClick: _ctx.button.onClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.button.text), 1)
            ]),
            _: 1
          }, 8, ["variant", "disabled", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}