import PartnerEngagementRouteTypes from '@/modules/partner-engagement/routes/types';
import onPartnerEngagementEnter from '@/modules/partner-engagement/routes/guards';
import { ModuleRouteRecordRaw } from '@/router/routes';
import Permissions from '@/services/permissions/Permissions';
import SiteModule from '@/domain/enums/SiteModule';

const pemRoutes: ModuleRouteRecordRaw[] = [
    {
        path: '/partner-engagement',
        name: PartnerEngagementRouteTypes.BASE,
        component: () => import('../view/PartnerEngagement.vue'),
        beforeEnter: onPartnerEngagementEnter,
        meta: {
            module: 'partner-engagement',
        },
        children: [
            {
                path: '',
                name: PartnerEngagementRouteTypes.HOME,
                component: () => import('@/views/home/HomeScreen.vue'),
                props: {
                    title: 'RPM',
                },
            },
            {
                path: 'dashboard',
                name: PartnerEngagementRouteTypes.DASHBOARD,
                component: () => import('@/views/home/DashboardScreen.vue'),
                props: {
                    siteModule: SiteModule.PartnerEngagement,
                },
            },
            {
                path: 'transaction',
                name: PartnerEngagementRouteTypes.TRANSACTION.BASE,
                component: () => import('../view/transactions/TransactionScreen.vue'),
                children: [
                    {
                        name: PartnerEngagementRouteTypes.TRANSACTION.LISTALL,
                        path: 'list/all',
                        meta: { parent: 'transactions' },
                        props: {
                            showLocationTransactionsOnly: true,
                        },
                        component: () => import('../view/transactions/screens/TransactionList.vue'),
                    },
                    {
                        name: PartnerEngagementRouteTypes.TRANSACTION.ADD_SUPPLIER_ORDER,
                        path: 'add/:transactionType',
                        props: true,
                        meta: { parent: 'transactions' },
                        component: () => import('../view/TransactionEditScreenDispatcher.vue'),
                        beforeEnter: Permissions.PARTNER_ENGAGEMENT.canOrderPackaging,
                    },
                    {
                        name: PartnerEngagementRouteTypes.TRANSACTION.ADD_SUPPLIER_SHIPPING,
                        path: 'add-supplier-shipping',
                        meta: { parent: 'transactions' },
                        component: () => import('../view/supplier-shipping/screens/SupplierShippingTransactionScreen.vue'),
                        beforeEnter: Permissions.PARTNER_ENGAGEMENT.canAddShipment,
                    },
                    {
                        name: PartnerEngagementRouteTypes.TRANSACTION.EDIT,
                        path: 'edit/:transactionId',
                        props: true,
                        meta: { parent: 'transactions' },
                        component: () => import('../view/TransactionEditScreenDispatcher.vue'),
                    },
                    {
                        name: PartnerEngagementRouteTypes.TRANSACTION.SUPPLIER_INBOUND,
                        path: 'edit/inbound/:transactionId',
                        props: true,
                        meta: { parent: 'transactions' },
                        component: () => import('../view/supplier-shipping/screens/ConfirmDisputeTransactionScreen.vue'),
                    },
                ],
            },
            {
                path: 'inventory',
                name: PartnerEngagementRouteTypes.INVENTORY.BASE,
                component: () => import('../view/inventory/InventoryScreen.vue'),
                children: [
                    {
                        name: PartnerEngagementRouteTypes.INVENTORY.LIST,
                        path: 'list',
                        props: true,
                        meta: { parent: 'inventory' },
                        component: () => import('../view/inventory/screens/InventoryList.vue'),
                        beforeEnter: Permissions.PARTNER_ENGAGEMENT.canViewInventory,
                    },
                ],
            },
            {
                path: 'cycle-count-report/report',
                name: PartnerEngagementRouteTypes.CYCLE_COUNT_REPORT.REPORT,
                component: () => import('../../../views/cyclecount/CycleCountReport.vue'),
                beforeEnter: Permissions.PARTNER_ENGAGEMENT.canRecordCount,
                props: () => ({
                    canApproveCount: Permissions.PARTNER_ENGAGEMENT.canAdjustCount(),
                }),
            },
            {
                path: 'production-part-list',
                name: PartnerEngagementRouteTypes.PRODUCTION_PART.LIST,
                component: () => import('../view/production-part/ProductionPartList.vue'),
                beforeEnter: Permissions.PARTNER_ENGAGEMENT.canEditProductionPartData,
            },
        ],
    },
];

export default pemRoutes;
