import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import MetaDataDTO from '@/dtos/MetaDataDTO';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import InventorySettingsDTO from '@/dtos/InventorySettingsDTO';
import AdminSettingsDTO from '@/dtos/AdminSettingsDTO';
import PEMSettingsDTO from '@/dtos/PEMSettingsDTO';

export default class SystemApiService {
    public async getVersion(): Promise<string> {
        const response: AxiosResponse<string> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/system/version`,
        });
        return response.data;
    }

    public async getMetaData(): Promise<DataAccessResponse<MetaDataDTO>> {
        const response: AxiosResponse<DataAccessResponse<MetaDataDTO>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/settings/meta-data`,
        });
        return new DataAccessResponseClass<MetaDataDTO>(response).response;
    }

    public async getInventorySettings(): Promise<DataAccessResponse<InventorySettingsDTO>> {
        const response: AxiosResponse<DataAccessResponse<InventorySettingsDTO>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/settings/inventory`,
        });

        return new DataAccessResponseClass<InventorySettingsDTO>(response).response;
    }

    public async getAdminSettings(): Promise<DataAccessResponse<AdminSettingsDTO>> {
        const response: AxiosResponse<DataAccessResponse<AdminSettingsDTO>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/settings/admin-settings`,
        });

        return new DataAccessResponseClass<AdminSettingsDTO>(response).response;
    }

    public async getPEMSettings(): Promise<DataAccessResponse<PEMSettingsDTO>> {
        const response: AxiosResponse<DataAccessResponse<PEMSettingsDTO>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/settings/pem-settings`,
        });

        return new DataAccessResponseClass<PEMSettingsDTO>(response).response;
    }
}
