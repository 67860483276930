import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "visually-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(_ctx.classes),
    role: _ctx.label || _ctx.$slots.label ? _ctx.role : null,
    "aria-hidden": _ctx.label || _ctx.$slots.label ? null : true
  }, {
    default: _withCtx(() => [
      (_ctx.label || _ctx.$slots.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "role", "aria-hidden"]))
}