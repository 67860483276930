import { RouteLocationNormalized } from 'vue-router';
import coreStore from '@/store/CoreStore';
import authStore from '@/store/AuthStore';
import CoreRouteTypes from '@/router/types';
import Permissions from '@/services/permissions/Permissions';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import PartnerEngagementRouteTypes from '@/modules/partner-engagement/routes/types';

// @ts-ignore
function handleRedirect({ from, redirectUrl }) {
    if (from.name === CoreRouteTypes.CHOOSELOCATION && redirectUrl) {
        from.redirectedFrom = undefined;
        // remove redirectUrl reference to prevent an endless loop
        return { path: redirectUrl.path };
    }
    return true;
}

// @ts-ignore
function didWeJustChooseLocation({ from, redirectUrl }) {
    return from.name === 'choose-location' && redirectUrl;
}

function selectPemRoute() {
    if (Permissions.PARTNER_ENGAGEMENT.canAccessDashboard() && authStore.getInstance().samAccountName) {
        return PartnerEngagementRouteTypes.DASHBOARD;
    }

    if (Permissions.PARTNER_ENGAGEMENT.canAddShipment()
        || Permissions.PARTNER_ENGAGEMENT.canOrderPackaging()
        || Permissions.PARTNER_ENGAGEMENT.canConfirmDisputeInboundReceipts()) {
        return PartnerEngagementRouteTypes.TRANSACTION.LISTALL;
    }

    return PartnerEngagementRouteTypes.HOME;
}

export async function onAppEnter(to: RouteLocationNormalized, from: RouteLocationNormalized): Promise<boolean | object> {
    const { profileStore } = coreStore.getInstance();
    if (!profileStore.isProfileLoaded) {
        await authStore.getInstance().initUserProfile();
        // load startup data now that we have a user
        await coreStore.getInstance().start();
    }

    const redirectUrl = from.redirectedFrom;
    const redirectOptions = { from, redirectUrl };
    if (didWeJustChooseLocation(redirectOptions)) {
        return handleRedirect(redirectOptions);
    }

    return profileStore.isUserLocationSet ? true : { name: CoreRouteTypes.CHOOSELOCATION };
}

/**
 * forward to the default page based on user permissions
 * TODO: this might be a good place to check configured default module in the future
 */
export async function onHomeEnter(): Promise<boolean | object> {
    if (Permissions.ADMINISTRATION.canAccessModule()) {
        return { name: MasterDataRouteTypes.TRANSACTION.LIST };
    }
    if (Permissions.FLOORTRAK.canAccessModule()) {
        return { name: FloorTrakRouteTypes.HOME };
    }
    if (Permissions.PARTNER_ENGAGEMENT.canAccessModule()) {
        return {
            name: selectPemRoute(),
        };
    }
    return true;
}

export async function onSelectLocationEnter(): Promise<boolean> {
    const { profileStore } = coreStore.getInstance();
    if (!profileStore.isProfileLoaded) {
        await authStore.getInstance().initUserProfile();
        // load startup data now that we have a user
        await coreStore.getInstance().start();
    }
    return true;
}
