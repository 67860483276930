import Axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import Theme from '@/domain/Theme';

export default class ThemeApiService {
    public async getTheme(): Promise<DataAccessResponse<Theme>> {
        const response: AxiosResponse<DataAccessResponse<Theme>> = await Axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/Settings/theme`,
            headers: Axios.defaults.headers.json,
        });

        return new DataAccessResponseClass<Theme>(response, Theme).response;
    }
}
