export default class Theme {
    constructor(init?: Partial<Theme>) {
        Object.assign(this, init);
    }

    public logoUrl!: string;

    public primary!: string;

    public primaryInverse!: string;

    public secondary!: string;

    public secondaryInverse!: string;

    public tertiary!: string;

    public tertiaryInverse!: string;
}
